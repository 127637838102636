/**
 * Created by osirvent on 15/03/2016.
 */
angular.module('annexaApp')
    .directive('annexaModal',['$compile', function($compile) {
        var getTemplate = function(modalObject) {
            var template = '';

            template += '<div class="modal-dialog '+ modalObject.size + '">';
            template += '   <div class="modal-content">';
            template += '       <div class="modal-header">';


            template += '                   <h5 class="modal-title" tabindex="0"><span translate="' + modalObject.title + '">' + modalObject.title + '</span></h5>';


            template += '       </div>';
            template += '       <div class="modal-body p-lg">';
            template += '       	<div uib-alert ng-repeat="alert in modal.alerts" class="alert-danger" close="closeAlert($index)">';
            template += '       		<span>{{alert.msg | translate}}</span> <span ng-if="alert.msgAux" ng-click="alert.show(alert)"><strong>{{\'global.literals.see\' | translate}}</strong></span>';
            template += '    	        <div ng-if="alert.msgAux && alert.showAux" class="row b-b m-b-xs">';
            template += '    	             <div class="col-xs-12 label-strong">{{alert.msgAux | translate}}</div>';
            template += '    	        </div>';
            template += '       	</div>';
            template += modalObject.content.join('');
            template += '       </div>';
            if(modalObject.noFooter == null || modalObject.noFooter == undefined || modalObject.noFooter == true) {
                template += '       <div class="modal-footer">';
                if(modalObject.extraSubmitFunction2 && modalObject.extraSubmitLabel2 ){
                    var extraSubmitClass2 = modalObject.extraSubmitClass2 ? modalObject.extraSubmitClass2 : 'primary';
                    template += '           <button type="button" class="btn btn-sm ' + extraSubmitClass2 + '" ng-click="modal.extraSubmitFunction2();"><span translate="'+modalObject.extraSubmitLabel2+'">Save</span></button>';
                }
                if(modalObject.extraSubmitFunction3 && modalObject.extraSubmitLabel3 ){
                    var extraSubmitClass3 = modalObject.extraSubmitClass3 ? modalObject.extraSubmitClass3 : 'primary';
                    template += '           <button type="button" class="btn btn-sm ' + extraSubmitClass3 + '" ng-click="modal.extraSubmitFunction3();"><span translate="'+modalObject.extraSubmitLabel3+'">Save</span></button>';
                }
                if(modalObject.submitFunction && modalObject.submitLabel){
                    var submitClass = modalObject.submitClass ? modalObject.submitClass : 'primary';
                    template += '           <button type="button" class="btn btn-sm ' + submitClass + '" ng-click="modal.submitFunction();"><span translate="'+modalObject.submitLabel+'">Save</span></button>';
                }
                if(modalObject.extraSubmitFunction && modalObject.extraSubmitLabel ){
                    var extraSubmitClass = modalObject.extraSubmitClass ? modalObject.extraSubmitClass : 'primary';
                    template += '           <button type="button" class="btn btn-sm ' + extraSubmitClass + '" ng-click="modal.extraSubmitFunction();"><span translate="'+modalObject.extraSubmitLabel+'">Save</span></button>';
                }
                template += '           <button type="button" class="btn btn-sm grey-500  text-white" ng-click="modal.close();"><span translate="global.literals.close">Close</span></button>';
                template += '       </div>';
            }
            template += '   </div>';
            template += '</div>';

            return template;
        }

        return {
            restrict: 'E',
            scope: {
                modal: '=modalObject'
            },
            link: function(scope, element, attr) {
                element.html(getTemplate(scope.modal)).show();

                scope.closeAlert = function(index) {
                    scope.modal.alerts.splice(index,1);
                }

                $compile(element.contents())(scope);
            }
        }
    }])
    .controller('AnnexaModalController',['$scope','close',function($scope,close) {
        $scope.close = function() {
            var validated = true;

            if(this.closeValidation) {
                validated = this.closeValidation();
            }

            if(validated) {
                angular.element('body').attr("data-temp","obert");
                angular.element('body').removeClass('modal-open');
                close({}, 200);
            }
        };
    }]);