/**
 * Created by osirvent on 15/03/2016.
 */
angular.module('annexaApp')
    .directive('iconsearchcolumnrender',[function () {
        var getTemplate = function(scope) {
            var a = 0;
        };

        return {
            restrict: 'E',
            replace: true,
            scope: {
                definition: '='
            },
            controller: function ($scope) {
                $scope.showIcon = function (option) {
                    if($scope.filtermodel[$scope.fieldname] == option.value) {
                        return true;
                    } else {
                        return false;
                    }
                };

                $scope.setFilter = function(option) {
                    $scope.filtermodel[$scope.fieldname] = option.value;
                }
            },
            link: function(scope, element, attr) {
                if(attr['definition'] && attr['filtermodel'] && attr['fieldname']) {
                    element.html(getTemplate(scope)).show();
                }

                $compile(element.contents())(scope);
            }
        }
    }])
    .directive('iconsearchcolumn',['$compile', function ($compile) {
        var getTemplate = function(scope) {
            var containerDiv = new annexaDiv('btn-group dropdown');
            var ddButton = new annexaDropdownButton('bt-sm white dropdown-toggle p-l-0');
            var spanStack = new annexaSpan(undefined,'fa-stack');

            angular.forEach(scope.definition.options, function (value, key) {
                var icon = new annexaIcon(value.buton_icon,undefined,undefined,'showIcon(definition.options[' + key + '])');
                spanStack.htmlObj.addChildren(icon);
            });

            ddButton.htmlObj.addChildren(spanStack);

            containerDiv.htmlObj.addChildren(ddButton);

            var ddDiv = new annexaDiv('dropdown-menu');

            angular.forEach(scope.definition.options, function (value, key) {
                var ahref = new annexaClickHref("dropdown-item","setFilter(definition.options[" + key + "],definition.dataTable)"," {{'" + value.label + "' | translate}}",false);
                var spanhref = new annexaSpan();
                var iconhref = new annexaIcon(value.dropdown_icon);
                spanhref.htmlObj.addChildren(iconhref);
                ahref.htmlObj.addChildren(spanhref);
                ddDiv.htmlObj.addChildren(ahref);
            });

            containerDiv.htmlObj.addChildren(ddDiv);
            
            return containerDiv.htmlObj.getHtml();
        };
        return {
            restrict: 'E',
            replace: true,
            scope: {
                definition: '=',
                filtermodel: '=',
                fieldname: '@'
            },
            controller: function ($scope, $state, RouteStateFactory) {
                $scope.showIcon = function (option) {
                    if($scope.filtermodel[$scope.fieldname] == option.value) {
                        return true;
                    } else {
                        return false;
                    }
                };
                
                $scope.setFilter = function(option, dataTable) {
                    $scope.filtermodel[$scope.fieldname] = option.value;
                    RouteStateFactory.addRouteState($state.current, $scope.filtermodel, 'ColumnsFilter');
                    if(dataTable){
                        if($scope[dataTable]){
                            $scope[dataTable].reloadInternalData(true);
                        }else if($scope.$parent[dataTable]){
                            $scope.$parent[dataTable].reloadInternalData(true);
                        }
                    }
                }
            },
            link: function(scope, element, attr) {
                if(attr['definition'] && attr['filtermodel'] && attr['fieldname']) {
                    element.html(getTemplate(scope)).show();
                }

                $compile(element.contents())(scope);
            }
        }
    }])
    .directive('tabledata',['$compile', '$rootScope', 'DTOptionsBuilder', 'RestService', 'DTColumnBuilder', '$filter', function($compile, $rootScope, DTOptionsBuilder, RestService, DTColumnBuilder, $filter) {

        var getTemplate = function(id, classes) {
            var template = '<table id="' + id + '" datatable=""';

            template += ' dt-options="' + id + '.dtOptions"';

            template += ' dt-columns="' + id + '.dtColumns"';

            template += ' dt-instance="' + id + '.table"';

            if(classes) {
                template += ' class="' + classes + '"';
            }

            template += '/>';

            return template;
        };


        var buildOptions = function(id,tabledef, scope, buttons) {
           var retOptions = DTOptionsBuilder.newOptions()
                .withOption('ajax',
                    function (data, callback, settings) {
						settings.maxLengthAnnexaValues = (($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.data_table_max_length && $rootScope.app.configuration.data_table_max_length.value)?$rootScope.app.configuration.data_table_max_length.value:undefined);
                        if(tabledef.skipFirstSearch){
                            data.draw = settings.iDraw;
                            data.recordsTotal = 0;
                            data.recordsFiltered = 0;
                            data.data = [];
                            callback(data);
                        	tabledef.skipFirstSearch = false;
                        } else if(tabledef.origin && tabledef.objectType) {
                            var page = settings._iDisplayStart;
                            var sizePage = settings._iDisplayLength;
                            if(sizePage == -1){
                                sizePage = settings._iRecordsTotal
                            }
                            if(sizePage > 250){
                        		sizePage = 10;
                        	}
                            var sort = "";
                            if (page > 0) {
                                page = page / sizePage;
                            }
                            if (data.order == null || data.order == undefined || data.order.length == 0 || (!data.columns[data.order[0].column].orderable)) {
                                if (tabledef.sortName) {
                                    sort = tabledef.sortName + ",desc";
                                }
                            } else {
                                if (data.columns[data.order[0].column].orderable) {
                                    sort = data.columns[data.order[0].column].data + "," + data.order[0].dir;
                                }
                            }
                            RestService.filterData(tabledef.origin, tabledef.objectType, scope[id].filterCall, scope[id].filterCallAux, page, sizePage, sort, tabledef.jsonDepth)
                                .then(function (dataSearch) {
                                    if (dataSearch.data && dataSearch.data.content) {
                                        var content = [];
                                        for (var i = 0; i < dataSearch.data.content.length; i++) {
                                            content.push(angular.fromJson(dataSearch.data.content[i]));
                                        }
                                        data.draw = settings.iDraw;
                                        data.recordsTotal = dataSearch.data.totalElements;
                                        data.recordsFiltered = dataSearch.data.totalElements;
                                        data.data = content;
                                        scope[id].list = content;
                                        callback(data);
                                    }else{
                                        data.draw = settings.iDraw;
                                        data.recordsTotal = 0;
                                        data.recordsFiltered = 0;
                                        data.data = [];
                                        scope[id].list = [];
                                        callback(data);
                                    }
                                    if(scope[id].functionToReload){
                                        scope[id].isSelectAll = false;
                                        scope[id].selected = {};
                                        scope[id].functionToReload();
                                    }
                                }).catch(function (error) {
                                data.draw = settings.iDraw;
                                data.recordsTotal = 0;
                                data.recordsFiltered = 0;
                                data.data = [];
                                scope[id].list = [];
                                callback(data);
                                if(scope[id].functionToReload){
                                    scope[id].isSelectAll = false;
                                    scope[id].selected = {};
                                    scope[id].functionToReload();
                                }
                            });
                        }else{
                            if(tabledef.url){
                                data.draw = settings.iDraw;
                                data.recordsTotal = scope[tabledef.url].objects.length;
                                data.recordsFiltered = scope[tabledef.url].objects.length;
                                data.data = scope[tabledef.url].objects;
                                callback(data);
                            }else {
                                data.draw = settings.iDraw;
                                data.recordsTotal = 0;
                                data.recordsFiltered = 0;
                                data.data = [];
                                callback(data);
                            }
                        }
                    }
                )
                .withDataProp(function(json) {
                    return json.data;
                })
                .withLanguageSource('./i18n/'+$rootScope.app.language+'/datatable.json')
                .withOption('fnRowCallback', function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)(scope);
                 })
                .withOption('headerCallback', function(header) {
                    if (!scope[id].headerCompiled) {
                        scope[id].headerCompiled = true;
                        $compile(angular.element(header).contents())(scope);
                    }
                })
                .withOption('processing', tabledef.processing)
                .withOption('serverSide', tabledef.serverSide)
                .withOption('bLengthChange', tabledef.lengthChange)
                .withOption('bFilter', tabledef.filter)
                .withOption('aaSorting', tabledef.sort)
                .withOption('lengthMenu',[[5,10,25,50,100], [5,10,25,50,100]])
                .withOption('pageLength',10)
                .withOption('responsive',tabledef.responsive)
                .withOption('stateSave', true)
                .withButtons(buttons);

            if(tabledef.dom) {
                var buttonsDom = '<"row p-t-sm p-r-sm"<"text-right"B>>';
                retOptions.withDOM(buttonsDom+tabledef.dom);
            }

            return retOptions;
        }

        return {
            restrict: 'E',
            replace: true,
            link: function(scope, element, attr) {
                var buttons = [
                    {
                        extend: 'excelHtml5',
                        className: 'grey'
                    },
                    {
                        extend: 'colvis',
                        className: 'grey buttons-columns',
                        text: $filter('translate')('global.literals.columns'),
                        columnText: function ( dt, idx, title ) {
                        	if(title && title == 'NotShow'){
                        		title = '';
                        	}else{
		                        switch(dt.column(idx).dataSrc()) {
	                                case 'createdDate':
	                                    title = $filter('translate')('global.literals.creation_date');
	                                    break;
	                                case 'docStatus':
	                                    title = $filter('translate')('global.literals.state');
	                                    break;
	                                case 'favorite':
	                                    title = $filter('translate')('global.literals.favorite');
	                                    break;
	                            }

	                            if(!title) {
	                                title = '--';
	                            }else if(title.indexOf('global.literals.showOptions') > 0){
	                            	title = $filter('translate')('global.literals.showOptions');
	                            }
                        	}

                            return title;
                        }
                    },
                    {
                        text: $filter('translate')('global.literals.reload'),
                        className: 'grey buttons-reload',
                        action: function (e, dt, node, config) {
                            dt.ajax.reload();
                        }
                    }
                ];
                if(attr['id'] && attr['tabledataDefinition']) {
                    if(!scope[attr['id']] && scope.modal){
                        scope[attr['id']] = scope.modal[attr['id']];
                        scope[attr['tabledataDefinition']] = scope.modal[attr['tabledataDefinition']];
                    }
                    if(scope[attr['id']].buttonsAddTable && scope[attr['id']].buttonsAddTable.length > 0){
                        angular.forEach(scope[attr['id']].buttonsAddTable, function(value, key){
                            buttons.push(value);
                        });
                        scope[attr['id']].isSelectAll = false;
                        scope[attr['id']].selected = {};
                        scope[attr['id']].buttonsTable = buttons;
                        var titleHtml = '<div class="checkbox checkbox-success" ><input type="checkbox"  ng-model="'+attr['id']+'.isSelectAll" ng-click="selectAll()" aria-label="{{ \'global.selectAll\' | translate }}"></div>';
                        scope[attr['id']].dtColumns.unshift(
                            DTColumnBuilder.newColumn("id").withTitle(titleHtml).notSortable().renderWith(function (data, type, full, meta) {
                            return '<input ng-if="showCheck('+data+')" type="checkbox" ng-model="'+attr['id']+'.selected['+data+']" ng-click="selectOne()">';
                        }));
                    }
                    scope[attr['id']].table = {};
                    scope[attr['id']].headerCompiled = false;
                    scope[attr['id']].dtOptions = buildOptions(attr['id'],scope[attr['tabledataDefinition']], scope, buttons);
                    scope.selectAll = function(){
                       if(scope[attr['id']].list){
                            angular.forEach(scope[attr['id']].list,function(value, key){
                                if(scope.showCheck(value.id)) {
                                    scope[attr['id']].selected[value.id] = scope[attr['id']].isSelectAll;
                                }
                            });
                        }
                        if(scope[attr['id']].functionToReload){
                            scope[attr['id']].functionToReload();
                        }
                    }

                    scope.selectOne = function(){
                        scope[attr['id']].isSelectAll = false;
                        if(scope[attr['id']].functionToReload){
                            scope[attr['id']].functionToReload();
                        }
                    }

                    scope.showCheck = function(id){
                        if(scope[attr['id']].functionToShowCheck){
                            return scope[attr['id']].functionToShowCheck(id);
                        }else{
                            return true;
                        }
                    }

                    element.html(getTemplate(attr['id'], attr['class'])).show();
                }

                $compile(element.contents())(scope);
            }
        }
    }]);