/**
 * Created by dpardell on 01/12/2015.
 */
angular
    .module('annexaApp')
    .factory('HeaderService',['$rootScope', 'UIButtonFactory', '$stateParams', '$location', function ($rootScope, UIButtonFactory, $stateParams, $location) {
        var CHANGE_STATE = "changeState";
        return {
            changeState: function (state, hideTabs) {
                $rootScope.headButtons = [];
                $rootScope.subHeadButtons = [];
                $rootScope.subHeadTabs = [];
                $rootScope.subHeadText = undefined;
                $rootScope.hideTabs = hideTabs;
                $rootScope.$broadcast(CHANGE_STATE, {state: state});
            },
            onChangeState: function ($scope, handler) {
                $scope.$on(CHANGE_STATE, function (event, message) {
                    handler(message);

                    UIButtonFactory.updateHeaderButtons(message.state, $scope, $stateParams, $location);
                });
            }
        }
    }]);







