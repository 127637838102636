/**
 * Created by osirvent on 09/02/2016.
 */

var DatatablesDefinition = function(showProcessing, showLengthChange, showFilter, isServerSide, language, compileRow, compileHeader, dom, filter, defaultSort, url, joins, scope) {
    this.showProcessing = showProcessing;
    this.showLengthChange = showLengthChange;
    this.showFilter = showFilter;
    this.isServerSide = isServerSide;
    this.language = language;
    this.compileRow = compileRow;
    this.scope = scope;
    this.compileHeader = compileHeader;
    this.scope.headerCompiled = false;
    this.dom = dom;
    this.filter = filter;
    this.url = url;
    this.joins = joins;
    this.defaultSort = defaultSort;
    this.getDatatablesRequestFilterArray = function() {
        var retFilter = [];
        this.filter.forEach(function(item) {
            var fil = item.getDatatablesRequestFilter(scope);
            if(fil) {
                retFilter.push(fil);
            }
        });
        return retFilter;
    }
};

var DatatablesDefinitionFilter = function(column,scope_name) {
    this.column=column;
    this.scope_name=scope_name;
    this.getDatatablesRequestFilter = function(scope) {
        var snames = this.scope_name.split('.');
        var val = undefined;
        snames.forEach(function(item) {
            if(val == undefined) {
                val = scope[item];
            } else {
                val = val[item];
            }
        })
        if(val) {
            return new DatatablesRequestFilter(column,val);
        }
    }
}

var DatatablesRequestSort = function(column, dir) {
    this.column = column;
    this.dir = dir;
};

var DatatablesRequestFilter = function(column, value) {
    this.column = column;
    this.value = value;
}

var DatatablesRequest = function(tdef, settings, applyFilter) {
    this.page = settings._iDisplayStart == 0 ? 'page=0' :  'page=' + (settings._iDisplayStart / settings._iDisplayLength);
    this.size = 'size=' + settings._iDisplayLength;
    this.sort = '';
    if(tdef.order !=  null) {
        for(var i = 0; i < tdef.order.length; i++) {
            var item = tdef.order[i];
            var ooldef = tdef.columns[item.column];

            if(ooldef.orderable) {
                if (this.sort != '') {
                    this.sort = this.sort + '&';
                }
                this.sort = this.sort + 'sort=' + ooldef.data + ',' + item.dir;
            }
        }
    }
    this.filter = '';
    for(var i = 0; i < applyFilter.length; i++) {
        var item = applyFilter[i];
        if(this.filter != '') {
            this.filter = this.filter + '&';
        }
        this.filter = this.filter + item.column + '=' + item.value;
    }
    this.getQueryString = function() {
        var retQueryString = '?';

        if(this.page != '') {
            retQueryString = retQueryString + this.page;
        }

        if(this.size != '') {
            if(retQueryString!= '?') {
                retQueryString = retQueryString + '&';
            }
            retQueryString = retQueryString + this.size;
        }

        if(this.sort != '') {
            if(retQueryString!= '?') {
                retQueryString = retQueryString + '&';
            }
            retQueryString = retQueryString + this.sort;
        }

        if(this.filter != '') {
            if(retQueryString!= '?') {
                retQueryString = retQueryString + '&';
            }
            retQueryString = retQueryString + this.filter;
        }

        if(retQueryString == '?') {
            return ';'
        } else {
            return retQueryString;
        }
    };
};

var getDatatableGetColumnOrderProperties = function(datatableSettings, datatableId, columns){
    var columnProperties = [];
    if(datatableSettings && datatableId && datatableSettings[datatableId] && datatableSettings[datatableId].length > 0){
        var column = $linq(datatableSettings[datatableId]).where("x => x.defaultOrder").toArray();
        if(column && column.length > 0){
            columnProperties = column[0];
            var index = $linq(columns).indexOf("x => x.id == '"+columnProperties.name+"'");
            if(index > -1) {
                if (columnProperties.defaultOrderDirection) {
                    return {name: columnProperties.name, direction: columnProperties.defaultOrderDirection, index: index};
                } else {
                    return {name: columnProperties.name, direction: 'desc', index: index};
                }
            }
        }
    }
    return false;
}

var getDatatableColumnsSettings = function(datatableSettings, datatableId, columns){
    if(columns && datatableSettings && datatableId && datatableSettings[datatableId] && datatableSettings[datatableId].length > 0){
        _.forEach(columns, function(column){
            var columnAux = $linq(datatableSettings[datatableId]).where("x => x.name == '"+column.id+"'").toArray();
            if(columnAux && columnAux.length > 0){
                if(columnAux[0].visible != null && columnAux[0].visible != undefined){
                    if(columnAux[0].visible) {
                        delete column.visible;
                    }else{
                        column.visible = columnAux[0].visible;
                    }
                }
                if(columnAux[0].sortable != null && columnAux[0].sortable != undefined){
                    if(columnAux[0].sortable) {
                        delete column.sortable;
                    }else{
                        column.sortable = columnAux[0].sortable;
                    }
                }
            }
        });
    }
    return columns;
}
