(function() {
  'use strict';
  angular
    .module('annexaApp')
    .directive('uiFullscreen', uiFullscreen);

    uiFullscreen.$inject = ['$document'];
    function uiFullscreen($document) {
      var directive = {
          restrict: 'AC',
          link: link
      };
      return directive;
      
      function link(scope, el, attr) {
          el.addClass('hide');
          if (screenfull.enabled) {
            el.removeClass('hide');
          } else{
            return;
          }
          el.bind('click', function(){
        	  if(screenfull.isFullscreen){
        		  screenfull.exit();
        	  }else{
        		  screenfull.request();
        	  }
          });
          screenfull.on('change', function(){
      		console.log('Am I fullscreen?', screenfull.isFullscreen ? 'Yes' : 'No');
      	  });
      }
    }
})();
