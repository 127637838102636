/**
 * Created by osirvent on 25/04/2017.
 */
angular
    .module('annexaApp')
    .component('annexaPluginsCreate', {
        templateUrl: './scripts/components/annexa-plugins/annexa-plugins.html',
        controller: ['$q', '$filter', '$http', 'Language', 'RestService', '$scope', function ($q, $filter, $http, Language, RestService, $scope) {
            var vm = this;

            vm.languageColumn = Language.getActiveColumn();

            vm.model = vm.data.model;
            vm.pluginsToCall = vm.data.plugins;

            var isPluginModelValid = function(target) {

            }
            this.$onInit = function () {
                vm.submitFunction = function () {
                    var self = this;
                    var params = {};
                    _.forEach(vm.pluginsToCall, function (plugin) {
                        params[plugin.target] = [];
                        _.forEach(plugin.parameters, function (value) {
                            var parameter = {id:value.id, value:vm.model[plugin.target][value.id]};
                            if(parameter.value.id){
                                parameter.value = parameter.value.id;
                            }
                            params[plugin.target].push(parameter);
                        });
                    });
                    if(!vm.data.args.params){
                        vm.data.args.params = {modal: self}
                    }else {
                        vm.data.args.params.modal = self;
                    }
                    vm.data.args.params.pluginParams = angular.toJson(params);
                    vm.data.args.submitFunction(vm.data.args.object, vm.data.args.params);
                }
            }

            $scope.$on('annexaCustomFieldComponentModelChanged', function (event, args) {
                if(args.plugin){
                    if(!vm.model[args.plugin]){
                        vm.model[args.plugin] = {};
                    }
                    vm.model[args.plugin][args.key] = args.model;
                }

            });
        }],
        bindings: {
            data: '=',
            submitFunction: '='
        }
    })