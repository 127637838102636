/**
 * Created by osirvent on 08/03/2016.
 */
angular
    .module('annexa.localData',['annexa.helper'])
    .factory('LocalDataFactory',['$q', '$http', 'HelperService', '$localStorage', function($q, $http, HelperService, $localStorage) {
        var factory = {};

        factory.getAllData = function(local_data) {
            var deferred = $q.defer();

            var promises = [];

            function getData(store) {
                var dataDefer = $q.defer();

                $http({
                    url: './api/localdata',
                    method: 'GET',
                    headers: { 'x-auth-token': $localStorage.LoggedUserToken },
                    params: {store: store }
                }).then(function(data) {
                    dataDefer.resolve( { name: store, data: JSOG.decode(data.data) });
                })

                return dataDefer.promise;
            }

            angular.forEach(local_data, function (value) {
                promises.push(getData(value));
            })

            $q.all(promises).then(function(data) {
                deferred.resolve(data);
            }).catch(function(error) {
                deferred.reject(error);
            })
            return deferred.promise;
        }

        return factory;
    }]);