/**
 * Created by osirvent on 20/01/2016.
 */
angular
    .module('annexaApp')
    .factory('AuditFactory',['$q', '$http','AnnexaFormlyFactory','$rootScope', 'TableFilter', 'DTColumnBuilder', '$filter', 'RegModals', 'RestService', 'HelperService', 'Language', 'ErrorFactory', 'DccumentsFactory', '$sce', 'AnnexaModalFactory', 'AnnexaPermissionsFactory', 'SignLocalFactory', 'CommonService', 'GlobalDataFactory', '$state', '$timeout', 'DialogsFactory', 'CacheFactory', function($q, $http, AnnexaFormlyFactory, $rootScope, TableFilter, DTColumnBuilder, $filter, RegModals, RestService, HelperService, Language, ErrorFactory, DccumentsFactory, $sce, AnnexaModalFactory, AnnexaPermissionsFactory, SignLocalFactory, CommonService, GlobalDataFactory, $state, $timeout, DialogsFactory, CacheFactory) {
        var factory = {};
        
        factory.getLastAudit = function(type, id, action) {
        	var deferred = $q.defer();
        	
        	$http({
                url: './api/audits/getLastAudit/' + type + '/' + id + '/' + action,
                method: 'GET'
            }).success(function (data) {                
            	deferred.resolve(JSOG.decode(data));
            }).catch(function(error) {
                deferred.reject(error);
            })
        	
        	return deferred.promise;
        }
        
        return factory;
        
    }]);