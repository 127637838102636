/**
 * Created by osirvent on 18/01/2017.
 */
angular.module('annexaApp')
    .factory('ABMComponentFactory', ['$filter', 'ABMInspectorFactory', 'ABMShapesFactory', 'ABMToolbarFactory', 'ABMModelFactory', 'ABMValidationFactory', 'dialogs', 'Language', 'CommonService',  function($filter, ABMInspectorFactory, ABMShapesFactory, ABMToolbarFactory, ABMModelFactory, ABMValidationFactory, dialogs, Language, CommonService) {
        var factory = {};

        factory.$inspectorHolder = undefined;
        factory.graph = undefined;
        factory.commandManager = undefined;
        factory.keyboard = undefined;
        factory.paper = undefined;
        factory.snaplines = undefined;
        factory.selection = undefined;
        factory.scope = undefined;
        factory.paperScroller = undefined;
        factory.stencil = undefined;
        factory.clipboard = undefined;
        factory.toolbar = undefined;

        var openTools = function (cellView) {
            var cell = cellView.model;
            var type = cell.get('type');

            ABMInspectorFactory.ElementInspector(cell, factory.scope, factory.$inspectorHolder);

            if(!cell.isLink() && !factory.selection.collection.contains(cell)) {
                factory.selection.collection.reset([]);

                factory.selection.collection.add(cell, { silent: true });

                new joint.ui.FreeTransform({
                    cellView: cellView,
                    allowOrthogonalResize: false,
                    allowRotation: false
                }).render();

                var halo = new joint.ui.Halo({
                    cellView: cellView,
                    theme: 'default',
                    boxContent: function (cellView) {
                        return cellView.model.get('type');
                    }
                });

                halo.render();
                halo.removeHandle('rotate');
                halo.removeHandle('resize');


                halo = ABMValidationFactory.setupHalo(cell, halo);
            }
        };

        factory.getEvent = function (type, position, fill) {
            if(!fill) {
                fill = '#ffffff';
            }

            return new joint.shapes.bpmn.Event({ eventType: type, position: position, attrs: { '.': { class: 'joint-theme-bpmn joint-cell joint-type-bpmn joint-type-bpmn-event joint-element' }, 'circle': { fill: fill } } });
        };

        factory.getActivity = function (type, position, content, fill, stroke) {
            if(!fill) {
                fill = '#ffffff';
            }

            if(!stroke) {
                stroke = '#000000';
            }

            return new joint.shapes.bpmn.Activity({ activityType: type, position: position, size: { width: 160, height: 40 }, content: content, attrs: { '.': { class: 'joint-theme-bpmn joint-cell joint-type-bpmn joint-type-bpmn-activity joint-element' }, '.body': { fill: fill, stroke: stroke } } });
        };

        factory.getLink = function (source, target) {
            return new joint.shapes.bpmn.Flow({ source: { id: source.id }, target: { id: target.id }, flowType: 'normal', attrs: { '.': {class: 'joint-theme-bpmn joint-cell joint-type-bpmn joint-type-bpmn-flow joint-link'} } });
        };

        factory.openModalSVG = function() {
            factory.paper.toDataURL(function(img) {
                var dlg = dialogs.notify(
                    'BPM',
                    '<img width="850" src="' + img + '"/>',
                    {
                        animation: true,
                        backdrop: 'static',
                        keyboard: true,
                        size: 'lg',
                        backdropClass: 'zMax',
                        windowClass: 'zMax2'
                    }
                );

                dlg.result.then(function(btn){}, function (btn) {});
            }, { type: 'image/jpeg'} );
        };

        factory.Initialization = [
            function Initialize (scope, element) {
                joint.setTheme('bpmn');

                factory.$inspectorHolder = $('.inspector-container', element);

                factory.scope = scope;

                ABMModelFactory.setScope(scope);

                //region Graph

                factory.graph = new joint.dia.Graph({ type: 'bpmn' }).on({
                    'add': function (cell, collection, opt) {
                        if(!opt.stencil) return;

                        ABMShapesFactory.unhighlightAllGateways(factory.graph.getCells());

                        ABMShapesFactory.processAdd(cell);
                    },
                    'remove': function (cell, collection, opt) {
                        if(cell.isLink()) {
                            ABMShapesFactory.processUnlink(cell);
                        } else {
                            delete factory.scope.model.transactions[cell.id];
                            
                            if (ABMShapesFactory.isOpenGateway(cell)) {
                            	//Si es una apertura tambien eliminamos su cierre
                            	var realCellsToRemove = [];                            	
                            	realCellsToRemove.push(ABMShapesFactory.getCloseGateway(cell));
                            	factory.graph.removeCells(realCellsToRemove);
                            } else if (ABMShapesFactory.isCloseGateway(cell)) {
                            	//Si es un cierre tambien eliminamos su apertura
                            	var realCellsToRemove = [];
                            	realCellsToRemove.push(ABMShapesFactory.getOpenGateway(cell));
                            	factory.graph.removeCells(realCellsToRemove);
                            }
                        }
                    }
                });

                //endregion

                //region CommandManager

                factory.commandManager = new joint.dia.CommandManager({ graph: factory.graph });

                //endregion

                //region Keyboard

                factory.keyboard = new joint.ui.Keyboard();

                factory.keyboard.on('delete backspace', function() {
                    var cellsToRemove = ABMShapesFactory.getShapesByNotType('TransactionStart', factory.selection.collection.toArray());
                    var realCellsToRemove = [];
                    _.forEach(cellsToRemove, function (cell) {
                        if(ABMShapesFactory.getShapeType(cell) != ABMShapesFactory.TI) {
                            realCellsToRemove.push(cell);
                        }
                    })
                    factory.graph.removeCells(realCellsToRemove);
                });

                //endregion

                //region Paper

                factory.paper = new joint.dia.Paper({
                    width: 2000,
                    height: 2000,
                    model: factory.graph,
                    gridSize: 10,
                    perpendicularLinks: true,
                    defaultLink: new joint.shapes.bpmn.Flow({
                        attrs: {
                            '.': {class: 'joint-theme-bpmn joint-cell joint-type-bpmn joint-type-bpmn-flow joint-link'}
                        }
                    }),
                    validateConnection: function(cellViewS, magnetS, cellViewT, magnetT, end) {
                        if(cellViewS && cellViewT) {
                            return ABMValidationFactory.validateConnection(cellViewS.model, cellViewT.model);
                        } else {
                            return false;
                        }
                    },
                    linkPinning: false,
                    markAvailable: true,
                    snapLinks: false
                }).on({
                    'blank:pointerdown': function (evt, x, y) {
                        if(factory.keyboard.isActive('shift', evt)) {
                            factory.selection.startSelecting(evt, x, y);
                        } else {
                            factory.selection.collection.reset([]);
                            ABMInspectorFactory.ModelerInspector(this.model, factory.scope, factory.$inspectorHolder);
                        }

                        ABMShapesFactory.unhighlightAllGateways(factory.graph.getCells());
                    },
                    'element:pointerdown': function(cellView, evt) {
                        if(factory.keyboard.isActive('ctrl meta', evt)) {
                            factory.selection.collection.add(cellView.model);
                        }

                        var cell = cellView.model;

                        ABMShapesFactory.unhighlightAllGateways(factory.graph.getCells());

                        if(_.contains([ABMShapesFactory.GSMO, ABMShapesFactory.GSMC, ABMShapesFactory.GSAO, ABMShapesFactory.GSAC],ABMShapesFactory.getShapeType(cell))) {
                            ABMShapesFactory.highlightSameIdGateways(cell, factory.graph.getCells())
                        }
                    },
                    'cell:pointerup': openTools,
                    'link:connect': function(evt, cellView, magnet, arrowhead) {
                        factory.selection.collection.reset([]);
                        openTools(this.findViewByModel(evt.model.get('source')));
                        ABMShapesFactory.processLink(evt.model);
                    },
                    'link:disconnect': function(evt, cellView, magnet, arrowhead) {
                        factory.selection.collection.reset([]);
                        openTools(this.findViewByModel(evt.model.get('source')));
                        ABMShapesFactory.processUnlink(evt.model);
                    }
                });

                factory.paperScroller = new joint.ui.PaperScroller({
                    autoResizePager: true,
                    padding: 50,
                    paper: factory.paper
                });

                $('.paper-container', element).append(factory.paperScroller.el);
                factory.paperScroller.center();

                //endregion

                //region Snaplines

                factory.snaplines = new joint.ui.Snaplines({ paper: factory.paper });
                factory.snaplines.startListening();

                //endregion

                //region Selection

                factory.selection = new joint.ui.Selection({
                    paper: factory.paper,
                    graph: factory.graph
                }).on({
                    'selection-box:pointerdown': function (cellView, evt) {
                        if(factory.keyboard.isActive('ctrl meta')) {
                            factory.selection.collection.remove(cellView.model);
                        }
                    }
                });

                //endregion

                //region Stencil

                factory.stencil = new joint.ui.Stencil({
                    graph: factory.graph,
                    paper: factory.paper,
                    width: 60,
                });

                $('.stencil-container', element).append(factory.stencil.render().el);

                factory.stencil.load(ABMShapesFactory.getShapes());

                joint.layout.GridLayout.layout(factory.stencil.getGraph(), {
                    columns: 1,
                    columnWidth: factory.stencil.options.width - 10,
                    rowHeight: 75,
                    dy: 5,
                    dx: 5,
                    resizeToFit: true
                });

                factory.stencil.getPaper().fitToContent(0,0,10)

                factory.stencil.getGraph().get('cells').each(function(cell) {
                    new joint.ui.Tooltip({
                        target: '.joint-stencil [model-id="' + cell.id + '"]',
                        content: $filter('translate')(ABMShapesFactory.getShapeTooltip(cell)),
                        direction: 'left',
                        padding: 0
                    });
                });

                //endregion

                //region Graph events

                factory.graph.on('add', function(cell, collection, opt) {
                    var showTools = true;

                    if (!opt.stencil) return;

                    if(ABMShapesFactory.getShapeType(cell) == ABMShapesFactory.GSA || ABMShapesFactory.getShapeType(cell) == ABMShapesFactory.GSM) {
                        cell.remove();
                        return;
                    }

                    var view = factory.paper.findViewByModel(cell);
                    if (view) {
                        if(cell.get('type') == 'bpmn.Activity') {
                            var originalSize = cell.get('size');
                            view.model.resize(originalSize.width * 4,originalSize.height);
                            scope.model.transactions[cell.id] = ABMModelFactory.InitializeTransaction(cell.get('activityType') == 'event-sub-process', cell, ABMShapesFactory.getShapeType(cell));
                        }

                        openTools(view);
                    };

                });

                factory.graph.on('remove', function(cell, collection, opt) {
                    if(cell.get('type') == 'bpmn.Flow' && cell.get('gatewayPath')) {
                        ABMShapesFactory.processUnlink(cell);
                    }

                });

                //endregion

                //region Clipboard

                factory.clipboard = new joint.ui.Clipboard;

                factory.keyboard.on('ctrl+c', function() {
                    factory.clipboard.copyElements(factory.selection, graph, {
                        translate: { dx: 20, dy: 20 },
                        useLocalStorage: true
                    });
                });

                factory.keyboard.on('ctrl+v', function () {
                    factory.selection.collection.reset([]);
                    factory.clipboard.pasteCells(graph, { link: { z: -1 }, useLocalStorage: true });
                    factory.clipboard.each(function(cell) {
                        if (cell.get('type') === 'link') return;

                        factory.selection.collection.add(graph.getCell(cell.id));
                    });

                });

                factory.keyboard.on('ctr+x', function() {
                    var originalCells = factory.clipboard.copyElements(factory.selection, graph, { useLocalStorage: true });
                    factory.commandManager.initBatchCommand();
                    _.invoke(originalCells, 'remove');
                    factory.commandManager.storeBatchCommand();
                    factory.selection.collection.reset([]);
                });

                //endregion

                //region Toolbar

                ABMToolbarFactory.setGraph(factory.graph);

                factory.toolbar = new joint.ui.Toolbar({
                    tools: ABMToolbarFactory.getToolbarTools(),
                    references: {
                        paperScroller: factory.paperScroller,
                        commandManager: factory.commandManager
                    }
                });

                factory.toolbar.on({
                    'print:pointerclick': _.bind(factory.paper.print, factory.paper),
                    'clear:pointerclick': ABMToolbarFactory.clear
                });

                $('.toolbar-container', element).append(factory.toolbar.render().el);

                factory.toolbar.$('[data-tooltip]').each(function() {
                    new joint.ui.Tooltip({
                        target: this,
                        content: $filter('translate')($(this).data('tooltip')),
                        top: '.joint-toolbar',
                        direction: 'top'
                    });
                });

                //endregion

                //region Load graph

                ABMInspectorFactory.InitializeInspectorEvents(factory.graph, scope);

                if(scope.procedure && scope.procedure.graphJSON) {
                    factory.graph.fromJSON(JSON.parse(scope.procedure.graphJSON))
                    _.forEach(factory.graph.getCells(), function (cell) {
                        if(factory.scope.model.transactions && factory.scope.model.transactions[cell.id]) {
                            var graphNodeType = factory.scope.model.transactions[cell.id].graphNodeType;

                            if (graphNodeType == ABMShapesFactory.TFF) {
                                ABMShapesFactory.setCellType(cell, ABMModelFactory.getAssignationType(cell), ABMModelFactory.TT_FINAL);
                            }
                        }
                    });
                } else if(scope.procedure && scope.procedure.cells) {
                    factory.graph.addCells(scope.procedure.cells);
                } else {
                    var startTransacton = ABMShapesFactory.getShape(ABMShapesFactory.TI, { x: 900, y: 750 }, $filter('translate')('global.bpm.event.start'));
                    factory.graph.addCell(startTransacton);
                    ABMModelFactory.setStartTransaction(startTransacton, ABMShapesFactory.TI);
                }

                ABMInspectorFactory.ModelerInspector(factory.paper.model, factory.scope, factory.$inspectorHolder);

                //endregion
            },
            function InitializeValidations (scope, element) {
                ABMValidationFactory.setABMComponentFactory(factory);
                ABMShapesFactory.setABMComponentFactory(factory);
            }
        ];

        factory.setCellContent = function (id, content) {
            var cell = factory.graph.getCell(id);

            if(cell) {
                cell.set('content', content);
            }
        };

        factory.getCell = function(id) {
            return factory.graph.getCell(id);
        }

        return factory;
    }])
    .factory('ABMToolbarFactory', ['dialogs', '$filter', 'ABMShapesFactory', 'ABMModelFactory', function(dialogs, $filter, ABMShapesFactory, ABMModelFactory) {
        var factory = {};

        factory.graph = undefined;

        factory.setGraph = function(graph) {
            factory.graph = graph;
        };

        factory.clear = function() {
            if(factory.graph) {
                var dlg = dialogs.confirm(
                    $filter('translate')('global.bpm.literals.confirmClearGraphTitle'),
                    $filter('translate')('global.bpm.literals.confirmClearGraphContent'),
                    {
                        animation: true,
                        backdrop: 'static',
                        keyboard: true,
                        size: '',
                        backdropClass: 'zMax',
                        windowClass: 'zMax3'
                    }
                );

                dlg.result.then(function (btn) {
                    factory.graph.clear();
                    ABMModelFactory.resetModel();

                    var startTransacton = ABMShapesFactory.getShape(ABMShapesFactory.TI, { x: 900, y: 750 }, $filter('translate')('global.bpm.event.start'));
                    factory.graph.addCell(startTransacton);
                    ABMModelFactory.setStartTransaction(startTransacton, ABMShapesFactory.TI);
                }, function (btn) {
                });
            }
        };

        factory.getToolbarTools = function () {
            return [
                {
                    type: 'button',
                    name: 'print',
                    attrs: {
                        button: {
                            'data-tooltip': 'Open a Print Dialog'
                        }
                    }
                },
                {
                    type: 'zoom-in',
                    name: 'zoom-in',
                    attrs: {
                        button: {
                            'data-tooltip': 'Zoom In'
                        }
                    }
                },
                {
                    type: 'zoom-out',
                    name: 'zoom-out',
                    attrs: {
                        button: {
                            'data-tooltip': 'Zoom Out'
                        }
                    }
                },
                {
                    type: 'button',
                    name: 'clear',
                    attrs: {
                        button: {
                            'data-tooltip': 'Clear Paper'
                        }
                    }
                }
            ];
        }

        return factory;
    }]);