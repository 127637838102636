/**
 * Created by osirvent on 20/01/2016.
 */
angular
    .module('annexaApp')
    .service('AlertsService',['$q', '$http', 'api', '$rootScope', function($q, $http, api, $rootScope) {
        return {
            getAlertsBySignInUser: function (searchType, modificat, page) {
                var deferrend = $q.defer();
                if($rootScope.LoggedUser) {
                    if (searchType == true) {
                        var url = api.getAlertsByFilter + "?userid=" + $rootScope.LoggedUser.id + "&modificat=" + modificat;
                        if (modificat == 3) {
                            url = api.getAlertsByFilterPaginated + "?userid=" + $rootScope.LoggedUser.id + "&modificat=" + modificat + "&page=" + page + "&size=10";
                        }
                        $http.get(url)
                            .success(function (data, status) {
                                deferrend.resolve(data);
                            })
                            .error(function (msg, code) {
                                deferrend.reject(msg);
                            });
                    } else {
                        var url = api.getAlertsByFilter + "?userid=" + $rootScope.LoggedUser.id + "&viewed=" + false + "&modificat=" + modificat;
                        if (modificat == 3) {
                            url = api.getAlertsByFilterPaginated + "?userid=" + $rootScope.LoggedUser.id + "&viewed=" + false + "&modificat=" + modificat + "&page=" + page + "&size=10";
                        }
                        $http.get(url)
                            .success(function (data, status) {
                                deferrend.resolve(data);
                            })
                            .error(function (msg, code) {
                                deferrend.reject(msg);
                            });

                    }
                }else{
                    deferrend.reject("No hi ha usuari");
                }
                return deferrend.promise;
            },
            updateAlertsBySignInUser: function (userId) {
                var deferrend = $q.defer();
                $http({
                    method: 'POST',
                    url: api.updateReadAlerts,
                    headers: {'Content-Type': 'application/json'},
                    params: {
                        userId: userId
                    }
                }).success(function (data, status) {
                    deferrend.resolve(data);
                }).error(function (msg, code) {
                   deferrend.reject(msg);
                });
                return deferrend.promise;
            }
        }
    }]);