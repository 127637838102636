/**
 * Created by osirvent on 27/09/2016.
 */
angular
    .module('annexaApp')
    .factory('ErrorFactory',[function () {
        var factory = {};

        factory.getErrorMessage = function(origin, type, error) {
            switch (origin) {
                case 'thirds':
                    switch(type) {
                        case 'new':
                            switch(error.code) {
                                case 'No Content Error':
                                    if(error.message == 'No addresses data received in update') {
                                        return 'global.thirds.errors.newNoAdresses';
                                    } else if (error.message == 'Document type invalid') {
                                    	return 'global.validation.documentTypeInvalid';
                                    } else if (error.message == 'Document type invalid for third type') {
                                    	return 'global.validation.documentTypeInvalidForThirdType';
                                    } else if (error.message ==  'Duplicate third identification document') {
                                    	return 'DIALOGS_EXIST_THIRD';
                                    } else if (error.message == 'The address recived cant be default (Need an address where the type can be marked as default)') {
                                    	return 'global.thirds.errors.newNoAdresses';
                                    }
                                    return 'global.errors.unknownNew';
                                default:
                                    return 'global.errors.unknownNew';
                            }
                        case 'delete':
                            switch (error.code) {
                                case 'Related Element Conflict':
                                    return 'global.thirds.errors.deleteConflict';
                                default:
                                    return 'global.errors.unknownDelete';
                            }
                        case 'update':
                            switch (error.message) {
                                case 'One contact required':
                                    return 'global.errors.oneContactRequired';
                                case 'Third address used in consent':
                                    return 'global.errors.thirdAddressUsedConsent';
                                case 'Third address used in register entry':
                                	return 'global.errors.thirdAddressUsedRegisterEntry';
                                case 'Third secretary notification address type data received incorrect':
                                    return 'global.errors.thirdAddressUsedSecNotification';
                                case 'Document type invalid':
                                    return 'global.validation.documentTypeInvalid';
                                case 'Document type invalid for third type':
                                	return 'global.validation.documentTypeInvalidForThirdType';
                                case 'Duplicate third identification document':
                                	return 'DIALOGS_EXIST_THIRD';
                                case 'Third consent all exist':
                                	return 'global.errors.existAllConsent';
                                case 'Third consent family procedure exist':
                                	return 'global.errors.existFamilyProcedureConsent';
                                case 'Third consent procedure exist':
                                	return 'global.errors.existProcedureConsent';
                                case 'Third consent dossier exist':
                                	return 'global.errors.existDossierConsent';
                                default:
                                    return 'global.errors.unknownUpdate';
                            }
                    }
                    break;
                case 'dossier':
                    switch (type) {
                        case 'template':
                            switch (error.code) {
                                case 'Template Exception':
                                    return 'global.tram.errors.processTemplate';
                                default:
                                    return 'global.tram.errors.unknownTemplateProcess';
                            }
                            break;
                        case 'complete':
                            switch (error.code) {
                                case 'No Content Error':
                                    if(error.message == 'No Required Custom Field Value data received in creation') {
                                        return 'global.tram.errors.requiredForEnd'
                                    }else if(error.message == 'No transaction data reciveid in creation'){
                                        return 'global.tram.errors.noTransaction'
                                    }else if(error.message == 'No dossierTransactions data reciveid in update'){
                                        return 'global.tram.errors.noTransactions'
                                    }else if(error.message == 'No translations data reciveid in update'){
                                        return 'global.tram.errors.noTranslations'
                                    }else if(error.message == 'Have pending sing or new documents'){
                                        return 'global.errors.pendingSingDocumentsOrNew';
                                    }
                                    else if (error.message == 'Error make definitive document'){
                                        return 'global.tram.errors.noDefinitiveDocument';
                                    }
                                    else{
                                        return 'global.tram.errors.unknownCompleteTransaction';
                                    }
                                    break;
                                case 'Not Found Error':
                                    if(error.message == 'error-SelectOne not found') {
                                        return 'global.tram.errors.selectOneError'
                                    }else if(error.message == 'error-MissingProfile not found') {
                                        return 'global.tram.errors.missingProfileError'
                                    }else if(error.message == 'error-MissingTransactionType not found') {
                                        return 'global.tram.errors.missingTransactionType'
                                    }else if(error.message == 'error-MissingResponsibleUser not found') {
                                        return 'global.tram.errors.missingResponsibleUser'
                                    }else if(error.message == 'error-SelectMultipleOpen not found') {
                                        return 'global.tram.errors.selectMultipleError'
                                    }else if(error.message == 'error-requestCompleteDossier-only-finally-transaction not found' || error.message == 'error-pendingTransactions not found'){
                                        return 'global.tram.errors.onlyFinallyTransaction';
                                    }else if(error.message == 'error-requestCompleteDossier-no-responsible not found'){
                                        return 'tram.errors.noResponsible';
                                    }else if(error.message == 'error-requestCompleteDossier-only-responsible not found'){
                                        return 'global.tram.errors.onlyResponsible';
                                    }else if(error.message == 'error-requestCompleteDossier-no-final-transaction not found'){
                                        return 'global.tram.errors.noFinallyTransaction';
                                    }else if(error.message == 'error-requestCompleteDossier-only-one-finally-transaction-type not found'){
                                        return 'global.tram.errors.onlyOneFinallyTransaction';
                                    }else if(error.message == 'error-requestCompleteDossier-no-responsible-profile not found'){
                                        return 'global.tram.errors.noProfileResponsibleFinallyTransaction';
                                    }else if(error.message == 'error-requestCompleteDossier-have-open-transactions not found'){
                                        return 'global.tram.errors.noCompleteFinallyTransactionHaveOpenTransactions';
                                    }else if(error.message == 'error-requestCompleteDossier-no-responsible-user-transaction'){
                                        return 'global.tram.errors.noResponsibleUserTransaction';
                                    }else if(error.message == 'error-requestCompleteDossier-profileNotFound not found' || error.message == 'error-requestCompleteDossier-list-profileNotFound not found') {
                                        return 'global.tram.errors.profileNotFound';
                                    }else if(error.message == 'error-requestCompleteDossier-is-in-restictive-transaction not found') {
                                        return 'global.tram.errors.inRestrictiveTransaction';
                                    }else{
                                        return 'global.tram.errors.unknownCompleteTransaction';
                                    }
                                    break;
                                default:
                                    if(error.message && error.message == 'error-requestCompleteDossier-is-in-restictive-transaction not found'){
                                    	return 'global.tram.errors.inRestrictiveTransaction';
									}else{
										return 'global.tram.errors.unknownCompleteTransaction';
									}
                            }
                        case 'foliate':
                            switch (error.message) {
                                case 'Not found dossier':
                                    return 'global.tram.errors.notFoundDossier';
                                case 'Not found documents':
                                        return 'global.tram.errors.notFoundDocuments';
                                case 'Not found foliate document template key not found':
                                        return 'global.tram.errors.notFoundFoliateDocumentTemplate';
                                case 'Not found entity certificate configuration key not found':
                                        return 'global.errors.notFoundSupportingDocumentCertificateKey';
                                case 'Not found entity certificate pin configuration key not found':
                                        return 'global.errors.notFoundSupportingDocumentCertificatePin';
                                case 'Not found dossier transaction not found':
                                        return 'global.tram.errors.notFoundDossierTransaction';
                                case 'Unknown Host not found':
                                    return 'global.errors.unknowHostError';
                                default:
                                    return 'global.errors.unknownNew';
                            }
                        case 'reopen':
                            switch (error.message){
                                case 'The application mode is eset not found':
                                    return 'global.tram.errors.esetMode';
                                case 'No dossier transactions data reciveid in update not found':
                                    return 'global.tram.errors.noTransactions';
                                case 'Dossier id not found':
                                    return 'global.tram.errors.dossierNotFound';
                                case 'Old dossier transaction not found':
                                    return 'global.tram.errors.oldDossierTransactionNotFound';
                                case 'Reopen only responsible not found':
                                    return 'tram.errors.reopenOnlyResponsible';
                                case 'Dossier is not finished not found':
                                    return 'global.tram.errors.dossierIsNotFinal';
                                case 'error-requestCompleteDossier-no-responsible-profile not found':
                                    return 'global.tram.errors.noProfileResponsibleFinallyTransaction';
                                case 'error-requestCompleteDossier-only-one-finally-transaction-type not found':
                                    return 'global.tram.errors.onlyFinallyTransaction';
                                case 'error-MissingTransactionType not found':
                                    return 'global.tram.errors.missingTransactionType';
                                case 'error-MissingResponsibleUser not found':
                                    return 'global.tram.errors.missingResponsibleUser';
                                case 'error-requestCompleteDossier-list-profileNotFound not found':
                                    return 'global.tram.errors.profileNotFound';
                                default:
                                    return 'global.tram.errors.unknownReopen';
                            }
                        case 'familyProcedures':
                            switch (error){
                                case 'Not family procedures with your user profile':
                                    return 'global.errors.errorPopupDiligenceNoProfile';
                            }
                            break;
                    }
                    break;
                case 'customFields':
                    switch (type) {
                        case 'new':
                            switch (error.message) {
                                case 'TemplateTag duplicated':
                                    return 'global.errors.customFieldTemplateTagDuplicate';
                                case 'TemplateTag has to start with a lowercase letter':
                                    return 'global.errors.customFieldTemplateTagNoStartWithLetter';
                                case 'TemplateTag can only contain A-Za-z0-9_':
                                    return 'global.errors.customFieldTemplateTagIncorrectCharacters';
                                default:
                                    return 'global.errors.unknownNew';
                            }
                            break;
                        case 'update':
                            switch (error.message) {
                                case 'TemplateTag duplicated':
                                    return 'global.errors.customFieldTemplateTagDuplicate';
                                case 'TemplateTag has to start with a lowercase letter':
                                    return 'global.errors.customFieldTemplateTagNoStartWithLetter';
                                case 'TemplateTag can only contain A-Za-z0-9_':
                                    return 'global.errors.customFieldTemplateTagIncorrectCharacters';
                                default:
                                    return 'global.errors.unknownUpdate';
                            }
                            break;
                    }
                    break;
                case 'queryParameters':
                    switch (type) {
                        case 'new':
                            switch (error.message) {
                                case 'Alias duplicated':
                                    return 'global.errors.queryParameterAliasDuplicate';
                                case 'Alias has to start with a lowercase letter':
                                    return 'global.errors.queryParameterAliasNoStartWithLetter';
                                case 'Alias can only contain A-Za-z0-9_':
                                    return 'global.errors.queryParameterAliasIncorrectCharacters';
                                default:
                                    return 'global.errors.unknownNew';
                            }
                            break;
                        case 'update':
                            switch (error.message) {
                                case 'Alias duplicated':
                                    return 'global.errors.queryParameterAliasDuplicate';
                                case 'Alias has to start with a lowercase letter':
                                    return 'global.errors.queryParameterAliasNoStartWithLetter';
                                case 'Alias can only contain A-Za-z0-9_':
                                    return 'global.errors.queryParameterAliasIncorrectCharacters';
                                default:
                                    return 'global.errors.unknownUpdate';
                            }
                            break;
                    }
                    break;
                case 'query':
                    switch (type) {
                        case 'new':
                            switch (error.message) {
                                case 'Alias duplicated':
                                    return 'global.errors.queryAliasDuplicate';
                                case 'Alias has to start with a lowercase letter':
                                    return 'global.errors.queryAliasNoStartWithLetter';
                                case 'Alias can only contain A-Za-z0-9_':
                                    return 'global.errors.queryAliasIncorrectCharacters';
                                default:
                                    return 'global.errors.unknownNew';
                            }
                            break;
                        case 'update':
                            switch (error.message) {
                                case 'Alias duplicated':
                                    return 'global.errors.queryAliasDuplicate';
                                case 'Alias has to start with a lowercase letter':
                                    return 'global.errors.queryAliasNoStartWithLetter';
                                case 'Alias can only contain A-Za-z0-9_':
                                    return 'global.errors.queryAliasIncorrectCharacters';
                                default:
                                    return 'global.errors.unknownUpdate';
                            }
                            break;
                    }
                    break;
               case 'registerDiligence':
                    switch (type) {
                        case 'updateState':
                            return 'global.errors.updateDiligenceState';
                        case 'sendTo':
                            return 'global.errors.sendToDiligence';
                        case 'attachToExp':
                            switch (error.message) {
                                case 'No register entry diligence found to attach':
                                    return 'global.errors.notFoundRegisterDiligencEntryFromAttach';
                                case 'No dossier transaction found to attach':
                                    return 'global.errors.notFoundDossierTransactionToAttach';
                                case 'No documents to attach':
                                    return 'global.errors.notFoundDocumentsToAttach';
                                case 'No receivers to attach':
                                    return 'global.errors.notFoundReceiversToAttach';
                                default:
                                    return 'global.errors.unknownAttachtoExp';
                            }
                            break;
                    }
                    break;
                case 'register':
                    switch (type) {
                        case 'new':
							if(error.message){
	                            switch (error.message) {
	                                case 'No register entry to insert':
	                                    return 'global.errors.registerEntryNoData';
	                                case 'No register entry type data received in insert':
	                                    return 'global.errors.registerEntryTypeNoData';
	                                case 'No register entry channel data received in insert':
	                                    return 'global.errors.registerEntryChannelNoData';
	                                case 'Unknown Host not found':
	                                    return 'global.errors.unknowHostError';
	                                case 'Sign error not found':
	                                    return 'global.errors.addQrError';
	                                case 'Invalid DiligenceType specified exist ACCEPTANCE':
	                                    return 'global.errors.exist_acceptance';
	                                default:
	                                	if(error.data && error.data.message && error.data.message == 'Diligence profile is not in register entry office profiles'){
	                                    	return 'global.errors.registerEntryDiligenceProfileNotInRegisterEntryOfficeProfile';
	                                	}else if(error.data && error.data.message && error.data.message == 'Is not valid document from template not found'){
											return 'global.documents.definitiveNotificationNoValidError';
										}
	                                    return 'global.errors.unknownNew';
	                            }
							}else if(error.data && error.data.message){
								switch (error.data.message) {
	                                case 'No register entry to insert':
	                                    return 'global.errors.registerEntryNoData';
	                                case 'No register entry type data received in insert':
	                                    return 'global.errors.registerEntryTypeNoData';
	                                case 'No register entry channel data received in insert':
	                                    return 'global.errors.registerEntryChannelNoData';
	                                case 'Unknown Host not found':
	                                    return 'global.errors.unknowHostError';
	                                case 'Sign error not found':
	                                    return 'global.errors.addQrError';
	                                case 'Invalid DiligenceType specified exist ACCEPTANCE':
	                                    return 'global.errors.exist_acceptance';
	                                default:
	                                	if(error.data && error.data.message && error.data.message == 'Diligence profile is not in register entry office profiles'){
	                                    	return 'global.errors.registerEntryDiligenceProfileNotInRegisterEntryOfficeProfile';
	                                	}else if(error.data && error.data.message && error.data.message == 'Is not valid document from template not found'){
											return 'global.documents.definitiveNotificationNoValidError';
										}
	                                    return 'global.errors.unknownNew';
	                            }
							}else{
								return 'global.errors.unknownNew';
							}
                            break;
                        case 'createBook':
                            switch (error.message) {
                                case 'Not found register book template id configuration key':
                                    return 'global.errors.notFoundRegisterBookTemplate';
                                case 'Unknown Host not found':
                                    return 'global.errors.unknowHostError';
                                default:
                                    return 'global.errors.unknownNew';
                            }
                            break;
                        case 'supportingdocument':
                            switch (error.message) {
                                case 'Not found supporting dcoument template key not found':
                                    return 'global.errors.notFoundSupportingDocumentTemplate';
                                case 'Not found supporting dcoument document type configuration key not found':
                                    return 'global.errors.notFoundSupportingDocumentTypeKey';
                                case 'Not found supporting document document type not found':
                                    return 'global.errors.notFoundSupportingDocumentType';
                                case 'Not found entity certificate configuration key not found':
                                    return 'global.errors.notFoundSupportingDocumentCertificateKey';
                                case 'Not found entity certificate pin configuration key not found':
                                    return 'global.errors.notFoundSupportingDocumentCertificatePin';
                                case 'Unknown Host not found':
                                    return 'global.errors.unknowHostError';
                                default:
                                    return 'global.errors.unknownNew';
                            }
                            break;
                        case 'attachToExp':
                            switch (error.message) {
                                case 'Not found registerEntry to attach not found':
                                    return 'global.errors.notFoundRegisterEntryToAttach';
                                case 'Not found dossier for attach not found':
                                    return 'global.errors.notFoundDossierForAttach';
                                case 'Not found documents and thirds to attach not found':
                                    return 'global.errors.notFoundDocumentsAndThirds';
                                case 'Dossier status is closed not found':
                                    return 'global.errors.dossierClosed';
                                default:
                                    return 'global.errors.unknownAttachtoExp';
                            }
                            break;
                        case 'pluginCreate':
                            if(error.message.startsWith('Error plugin plugin created')) {
                                return 'global.errors.error_call_plugin';
                            }else if(error.message.startsWith('Incorrect parameters format for plugin plugin created')){
                                return 'global.errors.incorrect_format_of_params';
                            }else if(error.message.startsWith('Error plugin no subject type plugin created')){
                                return 'global.errors.no_subject_type_param';
                            }else if(error.message.startsWith('Error plugin no destination organism plugin created')){
                                return 'global.errors.no_destination_organism_param';
                            }else if(error.message.startsWith('Error call WS Paeria plugin created')){
                                return 'global.errors.call_external_service_error';
                            }else if(error.message.startsWith('Error plugin no thirds plugin created')){
                                return 'global.errors.is_not_thirds_to_create';
                            }else if(error.message.startsWith('Error plugin object is no Register Entry plugin created')){
                                return 'global.errors.object_is_not_register_entry';
                            }else if(error.message.startsWith('Error plugin incompatible document type of third plugin created')){
                                return 'global.errors.incompatible_document_type';
                            }else if(error.message.startsWith('Error plugin incorrect format of identification document plugin created')){
                                return 'global.errors.incorrect_identification_document_format';
                            }else if(error.message.startsWith('Failed to create service. plugin created')){
                                return 'global.errors.fail_created_external_service';
                            }else{
                                if(error.message && error.message.endsWith("plugin created")){
                                    return 'global.errors.error_call_plugin';
                                }else{
                                    return 'global.errors.unknownNew';
                                }
                            }
                            break;
                    }
                    break;                    
                case 'registerEntryThird':
                    switch (type) {
                        case 'update':
                            switch (error.message) {                                   
                                default:
                                    return 'global.errors.unknownUpdate';
                            }
                            break;
                    }
                    break;                    
                case 'registerEntryClassification':
                    switch (type) {
                        case 'save':
                            switch (error.message) {
                                case 'No description data received':
                                    return 'global.errors.descriptionRequired';
                                case 'Classification is used can\'t modify entry type':
                                    return 'global.errors.classificationUsed';
                                default:
                                    return 'global.errors.unknownInsertOrUpdateClassification';
                            }
                            break;
                        case 'delete':
                            switch (error.message) {
                                case 'Classification can\'t delete':
                                    return 'global.errors.classificationUsedDeleted';
                                default:
                                    return 'global.errors.unknownDelete';
                            }
                            break;
                    }
                    break;
                case 'templateType':
                    switch (type) {
                        case 'delete':
                            switch (error.message) {
                                case 'Has related templates. not found':
                                    return 'global.errors.templateTypeUsedDeleted';
                                default:
                                    return 'global.errors.unknownDelete';
                            }
                            break;
                    }
                    break;
                case 'documents':
                    switch (type) {
                        case 'definitive':
                            switch (error.message) {
                                case 'Error document not found not found':
                                    return 'global.errors.notFoundDocument';
                                case 'Error document status not is NEW_AND_ATTACHMENT':
                                    return 'global.errors.documentStatusIncorrect';
                                case 'Unknown Host not found':
                                    return 'global.errors.unknowHostError';
                                case 'Sign error not found':
                                    return 'global.errors.addQrError';
                                default:
                                    return 'global.errors.unknownDefinitive';
                            }
                            break;
                        case 'publish':
                            switch (error.message) {
                                case 'No dossier transaction document for id received not found':
                                    return 'global.errors.notFoundDocument';
                                case 'No commment received not found':
                                    return 'global.errors.noComment';
                                case 'Unknown Host not found':
                                    return 'global.errors.unknowHostError';
                                case 'Sign error not found':
                                    return 'global.errors.addQrError';
                                default:
                                    return 'global.errors.unknownPublish';
                            }
                            break;
                        case 'checkDelete':
                            switch (error.message) {
                                case 'Have related registers':
                                    return 'global.errors.deleted_document_related_registers';
                                case 'Have related dossier transactions':
                                    return 'global.errors.deleted_document_related_dossier_transactions';
                                case 'Have related proposal thirds':
                                    return 'global.errors.deleted_document_related_proposals_thirds';
                                case 'Have related sessions':
                                    return 'global.errors.deleted_document_related_sessions';
                                case 'Have related proposals':
                                    return 'global.errors.deleted_document_related_proposals';
                                case 'Have related proposals organs':
                                    return 'global.errors.deleted_document_related_proposals_organs';
                                case 'Have related publishes':
                                    return 'global.errors.deleted_document_related_publishes';
                                case 'Have related tasks':
                                    return 'global.errors.deleted_document_related_tasks';
                                case 'Have related notifications':
                                    return 'global.errors.deleted_document_related_notifications';
                                case 'Have related thirds':
                                    return 'global.errors.deleted_document_related_thirds';
                                case 'Have open sign process':
                                    return 'global.errors.deleted_document_open_sign_process';
                               case 'Circuit used in document type':
                                    return 'global.errors.deleted_related_entities';
                               case 'Circuit used in template':
                                    return 'global.errors.deleted_related_entities';
                               case 'Circuit used in sec template':
                                    return 'global.errors.deleted_related_entities';
                               case 'Circuit used in tram template':
                                    return 'global.errors.deleted_related_entities';
                               default:
                                    return 'global.errors.unknownDelete';
                            }
                            break;
                        case 'voDocument':
                        	switch(error.message) {
                        		case 'No services recived':
                        			return 'global.errors.voDocumentErrorNoServicesRecived';
                                case 'Error reading response':
                                    return 'global.errors.voDocumentErrorReadingServices';
                                case 'No modality data recived':
                                    return 'global.errors.voDocumentErrorNoModalityDataRecived';
                                case 'Error read json modality':
                                    return 'global.errors.voDocumentErrorJsonModality';
                                case 'Error connecting VO':
                                    return 'global.errors.voDocumentErrorConnectingVO';
                                case 'No document recived':
                                    return 'global.errors.voDocumentErrorNoDocumentRecived';
                                case 'Error read json document':
                                    return 'global.errors.voDocumentErrorJsonDocument';
                                case 'No PDF recived':
                                    return 'global.errors.voDocumentErrorNoPdfDocumentRecived';
                                case 'No XML recived':
                                    return 'global.errors.voDocumentErrorNoXmlDocumentRecived';
                                case 'Error adding documents':
                                    return 'global.errors.voDocumentErrorAddingDocuments';
                                case 'No message available':
                                    return 'global.errors.voDocumentUnknownError';
                        	}
                        	break;
                    }
                    break;
                case 'documentType':
                    switch (type) {
                        case 'checkDelete':
                            switch (error.message) {
                                case 'Have documents':
                                    return 'global.literals.errorDeleteDocumentTypeHasDocs';
                            }
                            break;
                        case 'create':
                            switch (error.message) {
                                case 'The acronym already exists':
                                    return 'global.literals.errorCreateDocumentTypeExist';
                                case 'The acronym already exists in an eliminated document type':
                                    return 'global.literals.errorCreateDocumentTypeExistDeleted';
                            }
                            break;
                    }
                    break;
                case 'sign':
                    switch (type) {
                        case 'sendToSign':
                            switch (error.message) {
                                case 'Unknown Host not found':
                                    return 'global.errors.unknowHostError';
                                case 'No secretary user received in update':
                                    return 'global.sec.errors.noSecretary';
                                case 'No secretary position received in update':
                                    return 'global.sec.errors.noSecretaryPosition';
                                case 'No manager user received in update':
                                    return 'global.sec.errors.noManager';
                                default:
                                    return 'global.documents.modal.sendToSign.errorSignAction';
                            }
                            break;
                    }
                    break;
                case 'notification':
                    switch (type) {
                        case 'new':
                            switch (error) {
                                case 'Unknown Host not found':
                                    return 'global.errors.unknowHostError';
                                case 'No destinatari':
                                    return 'global.errors.noDestinataris';
                                case 'destinataris sense addresses':
                                    return 'global.errors.destinatarisWithoutAddress';
                                default:
                                    return 'global.errors.unknownNew';
                            }
                            break;
                        case 'update':
                            switch (error) {
                                case 'Unknown Host not found':
                                    return 'global.errors.unknowHostError';
                                case 'No destinatari':
                                    return 'global.errors.noDestinataris';
                                case 'destinataris sense addresses':
                                    return 'global.errors.destinatarisWithoutAddress';
                                default:
                                    return 'global.errors.unknownUpdate';
                            }
                            break;
                    }
                    break;
                case 'taskTemplates':
                    switch (type) {
                        case 'save':
                            switch (error.message) {
                                case 'No description data received':
                                    return 'global.errors.descriptionRequired';
                                case 'No days data received':
                                    return 'global.errors.daysRequired';
                                default:
                                    return 'global.errors.unknownInsertOrUpdateTaskTemplate';
                            }
                            break;
                        case 'delete':
                            switch (error.message) {
                                default:
                                    return 'global.errors.unknownDelete';
                            }
                            break;
                    }
                    break;
                case 'proposal':
                    switch (type) {
                        case 'documentSpec':
                            switch (error) {
                                case 'No proposal data reciveid in creation':
                                    return 'global.errors.proposalRequired';
                                case 'No profile data reciveid in creation':
                                    return 'global.errors.profileRequired';
                                case 'No section data reciveid in creation':
                                    return 'global.errors.sectionRequired';
                                case 'No template data reciveid in creation':
                                    return 'global.errors.templateRequired';
                                case 'No title data reciveid in creation':
                                    return 'global.errors.titleRequired';
                                default:
                                    return 'global.errors.unknownProposalCreateTemplate';
                            }
                        case 'new':
                            switch(error.message) {
                                default:
                                    return 'global.errors.unknownNew';
                            }
                        case 'delete':
                            switch (error.message) {
                                default:
                                    return 'global.errors.unknownDelete';
                            }
                        case 'send':
                        	if(error && error.message){
	                            switch (error.message) {
	                                case 'error-requestCompleteDossier-is-in-restictive-transaction not found':
                                    	return 'global.tram.errors.inRestrictiveTransaction';
                                	default:
	                                    return 'global.tram.errors.unknownSend';
	                            }
                        	}else{
                                return 'global.tram.errors.unknownSend';
                        	}
                        case 'update':
                            switch (error.message) {
                                case 'No content to update':
                                    return 'global.sec.errors.noContent';
                                case 'Proposal reciveid in update is cancelled':
                                    return 'global.sec.errors.proposalCancelled';
                                case 'No secretary observations reciveid in update':
                                    return 'global.sec.errors.noSecretaryObservations';
                                case 'No required documents reciveid in update':
                                    return 'global.sec.errors.noDocuments';
                                case 'Proposal document reciveid in update is not signed':
                                    return 'global.sec.errors.noProposalDocument';
                                case 'Certificate document reciveid in update is not signed':
                                    return 'global.sec.errors.noCertificateDocument';
                                case 'Resolution document reciveid in update is not signed':
                                    return 'global.sec.errors.noResolutionDocument';
                                case 'Transfer document reciveid in update is not signed':
                                    return 'global.sec.errors.noTransferDocument';
                                case 'No object data recived in creation of document from template':
                                    return 'global.sec.errors.noContent';
                                case 'No thirds data recived in creation of document from template':
                                    return 'global.sec.errors.noThirds';
                                case 'No section data recived in creation of document from template':
                                    return 'global.sec.errors.noSection';
                                case 'No profile data recived in creation of document from template':
                                    return 'global.sec.errors.noProfile';
                                case 'No title data recived in creation of document from template':
                                    return 'global.sec.errors.noTitle';
                                case 'No responsible user received in update':
                                    return 'global.sec.errors.noResponsible';
                                case 'No responsible position received in update':
                                    return 'global.sec.errors.noResponsiblePosition';
                                case 'No secretary user received in update':
                                    return 'global.sec.errors.noSecretary';
                                case 'No secretary position received in update':
                                    return 'global.sec.errors.noSecretaryPosition';
                                case 'No manager user received in update':
                                    return 'global.sec.errors.noManager';
                                case 'Organs not have executed orders':
                                    return 'global.sec.errors.noExecutedOrderOrgans';
                                case 'Dictamen document reciveid in update is not signed':
                                    return 'global.sec.errors.noDictamenDocument';
                                case 'No thirds in proposal received':
                                    return 'global.sec.errors.noThirds';
                                default:
                                    return 'global.errors.unknownUpdate';
                            }
                    }
                    break;
                case 'organ':
                    switch(type) {
                        case 'new':
                            switch(error.message) {
                                case 'No acronym to insert':
                                    return 'global.errors.noAcronym';
                                case 'Acronym exist.':
                                    return 'global.errors.existAcronym';
                                case 'Unknown Host not found':
                                    return 'global.errors.unknowHostError';
                                case 'No proposal in dates':
                                    return 'global.errors.errorPopupDecreeBook';
                                case 'No records in dates':
                                    return 'global.errors.errorPopupRegisterBook';
                                default:
                                    return 'global.errors.unknownNew';
                            }
                        case 'delete':
                            switch (error.message) {
                                default:
                                    return 'global.errors.unknownDelete';
                            }
                        case 'update':
                            switch (error.message) {
                                case 'No acronym to update':
                                    return 'global.errors.noAcronym';
                                case 'Acronym exist.':
                                    return 'global.errors.existAcronym';
                                case 'No global vote to insert':
                                    return 'global.errors.noGlobalVote';
                                default:
                                    return 'global.errors.unknownUpdate';
                            }
                    }
                    break;
                case 'template':
                    switch (type) {
                        case 'new':
                            switch (error.message) {
                                case 'No template file in creation':
                                    return 'global.errors.noTemplateFile';
                                case 'Error on upload template to repository':
                                    return 'global.errors.errorOnUploadTemplate'
                                case 'Template file information malformed':
                                    return 'global.errors.malformedTemplateFile'
                                default:
                                    return 'global.errors.unknownNew';
                            }
                    }
                    break;
                case 'session':
                	switch(type){
                		case 'close':
                			switch (error.message) {
                            case 'No transfered or agreement':
                                return 'global.sec.errors.noTransferedOrAgreement';
                            case 'No session found':
                                return 'global.sec.errors.noSession'
                            case 'No documents signed':
                                return 'global.sec.errors.noSignedDocs'
                            default:
                                return 'global.sec.errors.unknownClose';
                        }
                		case 'transfers':
                			switch (error.message) {
                            case 'No assigned proposals to executed order':
                                return 'global.sec.errors.noAssignedProposals';
                            case 'No session found':
                                return 'global.sec.errors.noSession'
                            case 'Executed session state vote wrong':
                                return 'global.sec.errors.voteStateRefusedOrNoVote'
							case 'Error certificate not signed':
								return 'global.sec.errors.cantCloseSession_certificateDocumentNotSigned'
                            case 'Error cannot do definitive document':
								return 'global.sec.errors.cantCloseSession_certificateDocumentNotSigned'
                            default:
                                return 'global.sec.errors.unknownTransfers';
                        }
                	}
                case 'bookType':
                    switch (type) {
                        case 'createBookType':
                            switch (error.message) {
                                case 'titleIsNull':
                                    return 'global.books.admin.titleIsNull';
                                case 'AttachTemplateIsNull':
                                    return 'global.books.admin.attachTemplateIsNull';
                                case 'BookElementIsNull':
                                    return 'global.books.admin.bookElementIsNull';
                                case 'IndexTemplateIsNull':
                                    return 'global.books.admin.indexTemplateIsNull';
                                case 'ProcedureIsNull':
                                    return 'global.books.admin.procedureIsNull';
                                case 'BookTypeIdIsNull':
                                    return 'global.books.admin.bookTypeIsNull';
                                case 'AttachDocumentTypesIsNull':
                                    return 'global.books.admin.attachDocumentTypesIsNull';
                                case 'bookTypeIsNull':
                                    return 'global.books.admin.bookTypeIsNull';
                                case 'Error on upload template to repository':
                                    return 'global.books.admin.uploadTemplateError';
                                case 'Template file information malformed':
                                    return 'global.books.admin.fileMalformed';
                                default:
                                    return 'global.errors.unknownNew';
                            }
                            break;
                        case 'updateBookType':
                            switch (error.message) {
	                            case 'titleIsNull':
	                                return 'global.books.admin.titleIsNull';
	                            case 'AttachTemplateIsNull':
	                                return 'global.books.admin.attachTemplateIsNull';
	                            case 'BookElementIsNull':
	                                return 'global.books.admin.bookElementIsNull';
	                            case 'IndexTemplateIsNull':
	                                return 'global.books.admin.indexTemplateIsNull';
	                            case 'ProcedureIsNull':
	                                return 'global.books.admin.procedureIsNull';
	                            case 'BookTypeIdIsNull':
	                                return 'global.books.admin.bookTypeIsNull';
	                            case 'AttachDocumentTypesIsNull':
	                                return 'global.books.admin.attachDocumentTypesIsNull';
	                            case 'bookTypeIsNull':
	                                return 'global.books.admin.bookTypeIsNull';
	                            case 'Error bookType have created books':
                                    return 'global.books.admin.haveCreatedBooks';
                                case 'Error no bookType to update':
                                    return 'global.books.admin.bookTypeIsNull';
                                case 'Error no bookTypeFilterElement':
                                    return 'global.books.admin.bookTypeFilterElementIsNull';
                                case 'AliasIsNull':
                                    return 'global.books.admin.aliasIsNull';
                                case 'BookTypeIsNull':
                                    return 'global.books.admin.bookTypeIsNull';
                                default:
                                    return 'global.errors.unknownUpdate';
                            }
                            break;
                    }
                    break;
                case 'publish':
                    switch (type) {
                        case 'do':
                            switch (error.data.message) {
                                case 'idEdicte not found':
                                    return 'global.publish.idEdicteIsNull';
                                case 'numExpedient not found':
                                    return 'global.publish.numExpedientIsNull';
                                case 'informacioTitol not found':
                                    return 'global.publish.informacioTitolIsNull';
                                case 'informacioDescripcio not found':
                                    return 'global.publish.informacioDescripcioIsNull';
                                case 'dataIniciPublicacio not found':
                                    return 'global.publish.dataIniciPublicacioIsNull';
                                case 'diligenciaIdioma not found':
                                    return 'global.publish.diligenciaIdiomaIsNull';
                                case 'diligenciaFormat not found':
                                    return 'global.publish.diligenciaFormatIsNull';
                                case 'edicteExternNomEnsOrigen not found':
                                    return 'global.publish.edicteExternNomEnsOrigenIsNull';
                                case 'edicteExternRegistreEntrada not found':
                                    return 'global.publish.edicteExternRegistreEntradaIsNull';
                                case 'edicteExternDataRecepcio not found':
                                    return 'global.publish.edicteExternDataRecepcioIsNull';
                                case 'classificacio1Tipus not found':
                                	return 'global.publish.classificacio1TipusIsNull';
                                case 'classificacio1Concepte not found':
                                	return 'global.publish.classificacio1ConcepteIsNull';
                                case 'classificacio1Categoria not found':
                                	return 'global.publish.classificacio1CategoriaIsNull';
                                case 'dadesAddicionalsRefURL not found':
                                	return 'global.publish.dadesAddicionalsRefURLIsNull';
                                case 'dadesAddicionalsRefEnllacTitol not found':
                                	return 'global.publish.dadesAddicionalsRefEnllacTitolIsNull';
                                default:
                                    return 'global.errors.unknownNew';
                            }
                            break;
                    }
                case 'tramClassification':
                	switch (type) {
	                	case 'create':
	                		switch (error.message) {
	                            case 'DataIntegrityViolationException.code':
	                                return 'global.errors.errorTramClassificationCodeExist';
	                            default:
	                                return 'global.errors.unknownNew';
	                	}
	                	break;
                	}
                case 'section':
                    switch (type) {
                        case 'create':
                            switch (error.message) {
                                case 'DataIntegrityViolationException.acronym':
                                    return 'global.errors.errorCreateSectionAcronymExist';
                                case 'DataIntegrityViolationException.code':
                                    return 'global.errors.errorCreateSectionCodeExist';
                                default:
                                    return 'global.errors.unknownNew';
                            }
                            break;
                    }
                case 'registerOffice':
                    switch (type) {
                        case 'create':
                            switch (error.message) {
                                case 'DataIntegrityViolationException.acronym':
                                    return 'global.errors.errorRegisterOfficeAcronymExist';
                                default:
                                    return 'global.errors.unknownNew';
                            }
                            break;
                    }
                    break;
            }

        };

        return factory;
    }]);