/**
 * Created by osirvent on 16/11/2015.
 */
angular
    .module('annexaApp')
    .factory('Language',['$q', '$http', '$translate', 'LANGUAGES', '$rootScope', '$localStorage', function ($q, $http, $translate, LANGUAGES, $rootScope, $localStorage) {
        return {
            getCurrent: function () {
                var deferred = $q.defer();
                var language = $translate.storage().get('NG_TRANSLATE_LANG_KEY');
                if(!language) {
                    language = 'es';
                }else {
            		var result = jQuery.grep(LANGUAGES,function(e) { return e == language; });
                    if(result.length == 0) {
                        language = 'es';
                    }                		
                }
                deferred.resolve(language);
                return deferred.promise;
            },
            setCurrent: function (key) {
                var deferred = $q.defer();
                $translate.storage().put('NG_TRANSLATE_LANG_KEY',key);
                angular.forEach($rootScope.app.languagedef, function(value, keyAux) {
                    if(value.acronym == key) {
                        value.active = true;
                    }else{
                        value.active = false;
                    }
                });
                deferred.resolve(key);
                return deferred.promise;
            },
            getByColumn: function (column, all) {
                var lang = undefined;

                var langDef = [];
                if(!$rootScope.app.languagedef || $rootScope.app.languagedef.length == 0) {
                	if(localStorage && localStorage.getItem("languagedef")) {
                        langDef = JSON.parse(JSOG.decode(localStorage.getItem("languagedef")));
                	}
                } else {
                	langDef = $rootScope.app.languagedef;
                }
                
                if(!all) {
                    lang = $linq(langDef).singleOrDefault({acronym: 'es'}, "x => x.column == '" + column + "'").acronym;
                } else {
                    lang = $linq(langDef).singleOrDefault({acronym: ''}, "x => x.column == '" + column + "'");
                }
                
                return lang
            },
            getAll: function () {
                var deferred = $q.defer();
               	deferred.resolve(LANGUAGES);
                return deferred.promise;
            },
            getActiveColumn: function() {
                var ret = 'language1';

                var langDef = [];
                if(!$rootScope.app.languagedef || $rootScope.app.languagedef.length == 0) {
                	if(localStorage && localStorage.getItem("languagedef")) {
                        langDef = JSON.parse(JSOG.decode(localStorage.getItem("languagedef")));
                	}
                } else {
                	langDef = $rootScope.app.languagedef;
                }
                
                angular.forEach(langDef, function(value, key) {
                   if(value.active) {
                       ret = value.column;
                   }
                });

                $rootScope.app.langColumn = ret;
                return ret;
            },
            getActiveLang: function(){
                var ret = 'es';
                
                var langDef = [];
                if(!$rootScope.app.languagedef || $rootScope.app.languagedef.length == 0) {
                	if(localStorage && localStorage.getItem("languagedef")) {
                        langDef = JSON.parse(JSOG.decode(localStorage.getItem("languagedef")));
                	}
                } else {
                	langDef = $rootScope.app.languagedef;
                }
                
                angular.forEach(langDef, function(value, key) {
                    if(value.active) {
                        ret = value.acronym;
                    }
                });
                return ret;
            },
            getAllTranslations: function(transkey) {
                var deferred = $q.defer();

                var ret = { language1: '', language2: '', language3: '' };

                var currentLanguage = $translate.use();
                var lang1 = this.getByColumn('language1');
                var lang2 = this.getByColumn('language2');
                var lang3 = this.getByColumn('language3');

                $translate.use(lang1).then(function (translation) {
                    ret.language1 = $translate.instant(transkey);

                    $translate.use(lang2).then(function (translation) {
                        ret.language2 = $translate.instant(transkey);

                        $translate.use(lang3).then(function (translation) {
                            ret.language3 = $translate.instant(transkey);

                            $translate.use(currentLanguage);

                            deferred.resolve(ret);
                        }).catch(function(error) {
                            $translate.use(currentLanguage);
                            deferred.reject(ret);
                        })
                    }).catch(function(error){
                        $translate.use(currentLanguage);
                        deferred.reject(ret);
                    });
                }).catch(function(error) {
                    $translate.use(currentLanguage);
                    deferred.reject(ret);
                });

                return deferred.promise;
            },
            getAllTranslationsMulti: function(transkeylist) {
                var deferred = $q.defer();

                var retList = [];

                var currentLanguage = $translate.use();
                var lang1 = this.getByColumn('language1');
                var lang2 = this.getByColumn('language2');
                var lang3 = this.getByColumn('language3');

                $translate.use(lang1).then(function (translation) {
                	_.forEach(transkeylist, function(value, key) {
                		var ret = { language1: '', language2: '', language3: '', id: value };
                		ret.language1 = $translate.instant(value);
                		retList.push(ret);
                    });

                    $translate.use(lang2).then(function (translation) {
                    	_.forEach(transkeylist, function(value, key) {
                    		var ret = $linq(retList).firstOrDefault({ language1: '', language2: '', language3: '', id: value }, "x => x.id == '" + value + "'");
                    		ret.language2 = $translate.instant(value);
                    		if(ret.language1 == '') {
                    			retList.push(ret);
                    		}
                        });

                        $translate.use(lang3).then(function (translation) {

                        	_.forEach(transkeylist, function(value, key) {
                        		var ret = $linq(retList).firstOrDefault({ language1: '', language2: '', language3: '', id: value }, "x => x.id == '" + value + "'");
                        		ret.language3 = $translate.instant(value);
                        		if(ret.language1 == '' && ret.language2 == '') {
                        			retList.push(ret);
                        		}
                            });

                            $translate.use(currentLanguage);

                            deferred.resolve(retList);
                        }).catch(function(error) {
                            $translate.use(currentLanguage);
                            deferred.reject(retList);
                        })
                    }).catch(function(error){
                        $translate.use(currentLanguage);
                        deferred.reject(retList);
                    });
                }).catch(function(error) {
                    $translate.use(currentLanguage);
                    deferred.reject(retList);
                });

                return deferred.promise;
            }
        };
    }])
    .constant('LANGUAGES', [
        'ca','es'
    ]);