/**
 * Created by osirvent on 15/03/2016.
 */
angular.module('annexaApp')
    .directive('languageinputsdisposition',['$compile', '$rootScope', function($compile, $rootScope) {
        var getTemplate = function(id, model, disposition, enabledField, languages, modelField, nameField) {
            var template = '';
            var lengthColumnLabel = 2;
            var lengthColumnInput = 5;
            if(disposition == 'horitzontal'){
                lengthColumnLabel = 1;
                lengthColumnInput = 3;
            }else if(disposition == 'vertical'){
                lengthColumnLabel = 2;
                lengthColumnInput = 10;
            }
            template += '<div id="'+id+'" class="row form-group">';
            if(languages && languages.length > 0) {
                var cont = 0;
                if(disposition == 'vertical'){
                    template += '<fieldset><legend class="label-strong">'+nameField+'</legend>';
                }else{
                    template += '<label for="'+id+'" class="col-sm-'+lengthColumnLabel+' form-control-label" translate="'+nameField+'">'+nameField+'</label>';
                }
                angular.forEach(languages, function (value, key) {
                    var column = value.column;
                    if(modelField){
                        column = modelField+(cont+1);
                    }
                    if(disposition == 'horitzontal21' && cont == 2){
                        template += '<div class="col-sm-'+lengthColumnInput+'"></div>';
                    }
                    template += '<div class="col-sm-'+lengthColumnInput+'">';
                    if(enabledField){
                        template += '<input id="'+id+column+'" type="text" ng-model="'+model+'.'+column+'" class="form-control m-b" ng-disabled="!'+enabledField+'" required/>';
                    }else{
                        template += '<input id="'+id+column+'" type="text" ng-model="'+model+'.'+column+'" class="form-control m-b" ng-disabled="true"/>';
                    }
                    template += '</div>';
                    if(disposition == 'horitzontal12' && cont == 0){
                        template += '<div class="col-sm-'+lengthColumnInput+'"></div>';
                    }
                    cont ++;
                });
                if(disposition == 'vertical'){
                    template += '</fieldset>';
                }
            }
            template += '</div>';
            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            link: function(scope, element, attr) {
                if(attr['idLanguage'] && attr['modelLanguage']) {
                    var disposition = attr['disposition'];
                    if(!disposition){
                        disposition = 'horitzontal';
                    }
                    element.html(getTemplate(attr['idLanguage'], attr['modelLanguage'], disposition, attr['enabledField'], $rootScope.app.languagedef, attr['modelField'], attr['nameField'])).show();
                }
                $compile(element.contents())(scope);
            }
        }
    }])
    .directive('languageinputs',['$compile', '$rootScope', 'Language', function($compile, $rootScope, Language) {
        var getTemplate = function(enabledField, languages, modelField, label, focus, required) {
            var template = '';
            if(languages && languages.length > 0) {
                var cont = 0;
                if(label && label != 'label'){
                    template += '<div class="multiLanguage row">';
                    template += '<span class="label-strong col-sm-12 small  p-b-0" translate="'+label+'">'+label+'</span>'; // TODO form-control-label
                    template += '<div class="form-group col-sm-12">';
                }else{
                    template += '<div class="multiLanguage">';
                    template += '<div class="form-group ">';
                }
                var firstColumn = "";
                var validation = '';
                angular.forEach(languages, function (value, key) {
                    var column = value.column;
                    if(modelField && modelField != 'model-field'){
                    	column = modelField+(column.charAt(column.length - 1));
                    }
                    if(cont == 0){
                        firstColumn = column;
                        template += '<small class="help-block langSelect">';
                        angular.forEach(languages, function (value2, key2) {
                            var column2 = value2.column;
                            if(modelField && modelField != 'model-field'){
                                column2 = modelField+(column2.charAt(column2.length - 1));
                            }
                            template += '<a href="" role="button" ng-class="styleLanguage(\''+value2.acronym+'\', filter.'+column2+')" ng-click="changeLanguage(\''+value2.acronym+'\')">'+value2.acronym.toUpperCase()+' </a>  ';
                        });
                        template += '</small>';
                    } else {
                        validation = '';
                    }
                    if(enabledField){
                        template += '<input formly-focus="'+focus+'" ng-show="\''+value.acronym+'\' == acronymLanguage" type="text" name="' + column + '" ng-model="filter.'+column+'" class="form-control" ng-disabled="!'+enabledField+'" ng-required="' + required + '" ng-change="validate(filter.'+column+', ' + required + ')" aria-label="' + value.acronym + '"/>';
                    }else{
                        template += '<input formly-focus="'+focus+'" ng-show="\''+value.acronym+'\' == acronymLanguage" type="text" name="' + column + '" ng-model="filter.'+column+'" class="form-control" ng-required="' + required + '" ng-change="validate(filter.'+column+', ' + required + ')" aria-label="' + value.acronym + '"/>';
                    }
                    if(cont==languages.length-1){
                        template += '<span class=" memory-text cursiva-grey help-block">{{filter.'+firstColumn+'}}</span>';
                    }
                    cont ++;
                });
                template += '	<div class="clearfix row validation-row small">';
                template += '		<div>';
                template += '			<div class="has-error error-messages text-danger ng-scope ng-active" ng-show="requiredError">';
                template += '				<p class="m-b-0 message text-nowrap ng-binding ng-scope">Aquest valor és obligatori</p>';
                template += '			</div>';
                template += '		</div>';
                template += '	</div>';
                template += '</div>';
            }
            template += '</div>';
            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            scope: {
                filter: '=modelLanguage'
            },
            link: function(scope, element, attr) {
                scope.acronymLanguage = 'es';
                scope.requiredError = false;
                Language.getCurrent().then(function (language) {
                    scope.acronymLanguage = language;
                });
                scope.changeLanguage = function(acronym) {
                    scope.acronymLanguage = acronym;
                };
                scope.styleLanguage = function(acronym, value) {
                    var style = "";
                    if(scope.acronymLanguage == acronym){
                        style = "label b-a-0";
                    }else if(value){
                        style = "no-bg b-a-0 text-primary";
                    }else{
                        style = "text-primary b-a-0 no-bg p-x-xs";
                    }
                    return style;
                };
                scope.validate = function(value, required) {
                	if(required) {
                		for (var i = 0; i < $rootScope.app.languagedef.length; ++i) {
                			var col = $rootScope.app.languagedef[i].column;
                		    if (!scope.filter[col]) {
                		    	scope.requiredError = true;
                		    	break;
                		    } else {
                        		scope.requiredError = false;
                        	}
                		}
                	}
            	};

                if(scope.filter) {

                    element.html(getTemplate(attr['enabledField'], $rootScope.app.languagedef, attr['modelField'], attr['label'], ((attr['formlyFocus'])?attr['formlyFocus']:false), attr['required'])).show();
                }
                $compile(element.contents())(scope);
            }
        }
    }])
    .directive('editableLanguageinputs',['editableDirectiveFactory',function(editableDirectiveFactory){
        return editableDirectiveFactory({
            directiveName:'editableLanguageinputs',
            inputTpl: '<languageinputs model-language="model" model-field="{{to.modelField}}" label="{{to.label}}"/>',
            render: function(){
                this.parent.render.call(this);
                // Add label to the input
                if (this.attrs.eLabel) {
                    var label = angular.element('<label>' + this.attrs.eLabel + '</label>');
                    this.inputEl.parent().prepend(label);
                }

                // Add classes to the form
                if (this.attrs.eFormclass) {
                    this.editorEl.addClass(this.attrs.eFormclass);
                }

            }
        });
    }])
    .directive('languagetextareas',['$compile', '$rootScope', 'Language', function($compile, $rootScope, Language) {
        var getTemplate = function(enabledField, languages, modelField, label, focus, rows) {
            var template = '';
            if(languages && languages.length > 0) {
                var cont = 0;
                if(label && label != 'label'){
                    template += '<div class="multiLanguage row">';
                    template += '<span class="label-strong col-sm-12 small  p-b-0" translate="'+label+'">'+label+'</span>'; // TODO form-control-label
                    template += '<div class="form-group col-sm-12">';
                }else{
                    template += '<div class="multiLanguage">';
                    template += '<div class="form-group ">';
                }
                if(!rows) {
                	rows = 3;
                }
                var firstColumn = "";
                var validation = '';
                angular.forEach(languages, function (value, key) {
                    var column = value.column;
                    if(modelField && modelField != 'model-field'){
                    	column = modelField+(column.charAt(column.length - 1));
                    }
                    if(cont == 0){
                        firstColumn = column;
                        template += '<small class="help-block langSelect">';
                        angular.forEach(languages, function (value2, key2) {
                            var column2 = value2.column;
                            if(modelField && modelField != 'model-field'){
                                column2 = modelField+(column2.charAt(column2.length - 1));
                            }
                            template += '<a href="" role="button" ng-class="styleLanguage(\''+value2.acronym+'\', filter.'+column2+')" ng-click="changeLanguage(\''+value2.acronym+'\')">'+value2.acronym.toUpperCase()+' </a>  ';
                        });
                        template += '</small>';
                    } else {
                        validation = '';
                    }
                    if(enabledField){
                        template += '<textarea style="width: 100%; height: auto;" formly-focus="'+focus+'" ng-show="\''+value.acronym+'\' == acronymLanguage" rows="'+ rows +'" ng-model="filter.'+column+'" class="form-control" ng-disabled="!'+enabledField+'" aria-label="' + value.acronym + '"/>';
                    }else{
                        template += '<textarea style="width: 100%; height: auto;" formly-focus="'+focus+'" ng-show="\''+value.acronym+'\' == acronymLanguage" rows="'+ rows +'" ng-model="filter.'+column+'" class="form-control" aria-label="' + value.acronym + '"/>';
                    }
                    if(cont==languages.length-1){
                        template += '<span class=" memory-text cursiva-grey help-block">{{filter.'+firstColumn+'}}</span>';
                    }
                    cont ++;
                });
                template += '</div>';
            }
            template += '</div>';
            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            scope: {
                filter: '=modelLanguage'
            },
            link: function(scope, element, attr) {
                scope.acronymLanguage = 'es';
                Language.getCurrent().then(function (language) {
                    scope.acronymLanguage = language;
                });
                scope.changeLanguage = function(acronym) {
                    scope.acronymLanguage = acronym;
                };
                scope.styleLanguage = function(acronym, value) {
                    var style = "";
                    if(scope.acronymLanguage == acronym){
                        style = "label b-a-0";
                    }else if(value){
                        style = "no-bg b-a-0 text-primary";
                    }else{
                        style = "text-primary b-a-0 no-bg p-x-xs";
                    }
                    return style;
                };

                if(scope.filter) {

                    element.html(getTemplate(attr['enabledField'], $rootScope.app.languagedef, attr['modelField'], attr['label'], ((attr['formlyFocus'])?attr['formlyFocus']:false), attr['rows'])).show();
                }
                $compile(element.contents())(scope);
            }
        }
    }])
    .directive('editableLanguagetextareas',['editableDirectiveFactory',function(editableDirectiveFactory){
        return editableDirectiveFactory({
            directiveName:'editableLanguagetextareas',
            inputTpl: '<languagetextareas model-language="model" model-field="{{to.modelField}}" label="{{to.label}}" rows="3"/>',
            render: function(){
                this.parent.render.call(this);
                // Add label to the input
                if (this.attrs.eLabel) {
                    var label = angular.element('<label>' + this.attrs.eLabel + '</label>');
                    this.inputEl.parent().prepend(label);
                }

                // Add classes to the form
                if (this.attrs.eFormclass) {
                    this.editorEl.addClass(this.attrs.eFormclass);
                }

            }
        });
    }]);