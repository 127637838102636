angular
    .module('annexaApp')
    .factory('DialogsFactory',['dialogs', '$filter', '$q', function(dialogs, $filter, $q) {
        var factory = {};

        var dlgParams = {
            animation: true,
            background: 'static',
            keyboard: true,
            size: ''
        }

        factory.notify = function(body, title, classes, bdClasses) {
            var title = title ? title : '';

            if(classes) {
                dlgParams.windowClass = classes;
            }else{
				try{
					var dlgParamsWindowClass = getDlgParamsWindowClass();
					if(dlgParamsWindowClass){
						dlgParams.windowClass =  dlgParamsWindowClass;
					}
				}catch(e){
				}
			}

            if(bdClasses) {
                dlgParams.backdropClass = bdClasses;
            }

            var dlg = dialogs.notify(title, $filter('translate')(body), dlgParams);
        }

        factory.confirm = function (title, body, classes, bdClasses) {
            var deferred = $q.defer();

            if(classes) {
                dlgParams.windowClass = classes;
            }else{
				try{
					var dlgParamsWindowClass = getDlgParamsWindowClass();
					if(dlgParamsWindowClass){
						dlgParams.windowClass =  dlgParamsWindowClass;
					}
				}catch(e){
				}
			}

            if(bdClasses) {
                dlgParams.backdropClass = bdClasses;
            }

            var dlg = dialogs.confirm(
                $filter('translate')(title),
                $filter('translate')(body),
                dlgParams
            )

            dlg.result.then(function(btn) {
                deferred.resolve(btn);
            }, function(btn) {
                deferred.reject(btn);
            });

            return deferred.promise;
        }

        factory.error = function(body, title, classes, bdClasses) {
            var title = title ? title : '';

            if(classes) {
                dlgParams.windowClass = classes;
            }else{
				try{
					var dlgParamsWindowClass = getDlgParamsWindowClass();
					if(dlgParamsWindowClass){
						dlgParams.windowClass =  dlgParamsWindowClass;
					}
				}catch(e){
				}
			}

            if(bdClasses) {
                dlgParams.backdropClass = bdClasses;
            }

            var dlg = dialogs.error(title, $filter('translate')(body), dlgParams);
        }

		var getDlgParamsWindowClass = function(){
			var obj = $('.modal:visible');
			if(obj){
				var length = obj.length;
				if(length > 0){
					if(length > 20){
						return undefined;
					}else{
						return 'zIndexDialog'+length;
					}
				}
			}	
		}
        return factory;
    }])