/**
 * Created by osirvent on 15/03/2016.
 */
angular.module('annexaApp')
    .directive('navtree',['$filter', '$state', '$compile', '$rootScope', 'RestService', 'Language', 'AnnexaPermissionsFactory', function($filter, $state, $compile, $rootScope, RestService, Language, AnnexaPermissionsFactory) {
        var getTemplate = function(scope, actions) {
            var template = '';
            if(scope && actions && scope[actions] && scope[actions].length > 0) {
            	if(scope[actions] && scope[actions].length > 0 && scope[actions][0].orderBy){
            		actions = createTree($linq(scope[actions]).orderBy("x => x.orderBy").toArray());
            	}else{
            		actions = createTree(scope[actions]);
            	}
                actions = fixTreeWithPermissions(actions);
                var template =  '<div class="m-b nav-active-info">';
                template +=     '       <ul class="nav list-unstyled nav-pills nav-stacked" ui-nav role="menu">';
                for(var i=0; i<actions.length; i++)
                {
                    var action = actions[i];
                    template += '           <li class="nav-item '+action.active()+'">';
                    if(action.children && action.children.length > 0){
                        template += '           <a href="" class="nav-link auto" aria-haspopup="true" aria-expanded="false" ><span class="pull-right text-muted m-r-xs"><i class="fa fa-chevron-right"></i><i class="fa fa-chevron-down none"></i></span>'+ action[Language.getActiveColumn()] + '</a>';
                        template += createSubNav(action);
                    }else{
                        template += '           <a  ui-sref="'+action.url+'" class="nav-link">'+ action[Language.getActiveColumn()] + '</a>';
                    }
                    template += '           </li>';
                }
                template +=     '       </ul>';
                template +=     ' </div>';
            }
            return template;
        };

        var fixTreeWithPermissions = function(tree) {
            var fixedTree = [];

            angular.forEach(tree, function (element) {
                if(element.children && element.children.length > 0) {
                    var child = [];

                    angular.forEach(element.children, function (item) {
                        if(AnnexaPermissionsFactory.haveSinglePermission(item.permission.keyId)) {
                            child.push(item);
                        }
                    });

                    if(child.length > 0) {
                        element.children = child;
                        fixedTree.push(element);
                    }
                }
            });

            return fixedTree;
        };

        var createTree = function(dades) {
            if (dades != null && dades != undefined) {
                var noParent = $.grep(dades, function (n, j) {if (n.parent) {return;} else {return n;}});
                if (noParent) {
                    for (var i = 0; i < noParent.length; i++) {
                        var signaction = noParent[i];
                        var children = $.grep(dades, function (n, j) {if (n.parent && n.parent.id == signaction.id) {return n;} else {return;}});
                        if (children && children.length > 0) {
                            signaction.children = addTreeChildren(children, dades);
                        }
                        signaction.active = function () {
                            var actual = $state.current.name;
                            if (this.url == actual) {
                                return "active";
                            } else {
                                return "";
                            }
                        };
                    }
                }
                return noParent;
            }
        };

        var addTreeChildren = function(children, dades){
            for(var i = 0; i < children.length; i++){
                var signaction=children[i];
                var childrenSub = $.grep(dades, function( n, j ) {if(n.parent && n.parent.id == signaction.id){return n;}else{return ;}});
                if(childrenSub && childrenSub.length > 0){
                    signaction.children = addTreeChildren(childrenSub,dades);
                }
                signaction.active=function(){
                    var actual = $state.current.name;
                    if(this.url==actual) {
                        return "active";
                    }else{
                        return "";
                    }
                };
            }
            return children;
        };

        var createSubNav = function(actualAction){
            var template =  '<ul class="nav nav-sub text-sm" role="menu">';
            if(actualAction.children && actualAction.children.length > 0){
                for(var i=0; i<actualAction.children.length; i++){
                    var children = actualAction.children[i];
                    template += ' <li class="nav-item '+children.active()+'">';
                    if(children.url && children.url != "none"){
                        template += ' <a ui-sref="'+children.url+'" class="nav-link"><span class="fa fa-angle-right fa-fw text-muted"></span>'+ children[Language.getActiveColumn()] + '</a>';
                    }else{
                    	template += ' <a href="" class="nav-link auto"><span class="fa fa-angle-right fa-fw text-muted"></span><span class="pull-right text-muted m-r-xs"><i class="fa fa-chevron-right "></i><i class="fa fa-chevron-down none"></i></span>'+ children[Language.getActiveColumn()] +'</a>';
                        template += createSubNav(children);
                    }
                    template += ' </li>';
                }
            }else{
                template += ' <a tabindex="0" ui-sref="'+actualAction.url+'" class="nav-link"><span class="fa fa-angle-right fa-fw text-muted"></span>'+ actualAction[Language.getActiveColumn()] + '</a>';
            }
            template += ' </ul>';
            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            link: function(scope, element, attr) {
                if(attr['moduleActions']) {
                    scope.$watch(attr['moduleActions'], function() {
                        element.html(getTemplate(scope, attr['moduleActions'])).show();
                        $compile(element.contents())(scope);
                    });
                }
                $compile(element.contents())(scope);
            }
        }
    }]);