/**
 * Created by aguerrero on 12/09/2016.
 */

// region Models

var third = function(model) {

    this.name           = model.name;
    this.surename1       = model.surename1;
    this.surename2       = model.surename2;
    this.corporateName  = model.corporateName;
    this.thirdType      = model.thirdType;
    this.createUser     = model.createUser;
    this.identificationDocumentType = model.identificationDocumentType;
    this.identificationDocument     = model.identificationDocument;

};

// endregion

