/**
 * Created by oscar on 21/01/16.
 */
angular.module('annexaApp')
    .directive('checkImage', function($http) {
        return {
            restrict: 'A',
            link: function(scope, element, attrs) {
                attrs.$observe('ngSrc', function(ngSrc) {
                	if(ngSrc){
                		$http.get(ngSrc).success(function(){
                			//Si la trova no s'ha de fer res
                		}).error(function(){
                			//En cas que hi hagi un error al anar a buscar la imatge fiquem la general
                			element.attr('src', './assets/images/users/general-user.png'); // set default image
                		});
                	}else{
            			//En cas que no tinguem url anar buscar la imatge fiquem la general
            			element.attr('src', './assets/images/users/general-user.png'); // set default image
                	}
                });
            }
        };
    });
