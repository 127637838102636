/**
 * Created by osirvent on 15/03/2016.
 */
var SearchDataBox = function(addFunction,idSearch, idTable, defTable, titleText, literalNoObjects, origin, objectType, buttonSearch, buttonNew, nameShow, searchText, modalSearch, modalAdd, modalNew, jsonDepth, disabled, collapsed) {
    this.titleText = titleText;
    this.literalNoObjects = literalNoObjects;
    this.idSearch = idSearch;
    this.idTable = idTable;
    this.defTable = defTable;
    this.origin = origin;
    this.objectType = objectType;
    this.collapsed = collapsed == undefined ? false : collapsed;
    this.searchData = new SearchData(addFunction,idTable, origin, objectType, buttonSearch, buttonNew, nameShow, searchText, modalSearch, modalAdd, modalNew, jsonDepth,disabled);
}