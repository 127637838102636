/**
 * Created by osirvent on 15/03/2016.
 */
angular.module('annexaApp')
    .directive('editableSearchAttendee',['editableDirectiveFactory',function(editableDirectiveFactory) {
        return editableDirectiveFactory({
            directiveName: 'editableSearchAttendee',
            inputTpl: '<div class=""></div>',
            useCopy: true,
            render: function() {
                this.parent.render.call(this);

                var checkEl = '<div class="ico-floating-wrapper">';

                if(this.attrs.eOnSelect) {
                    checkEl += '    <input type="text" class="form-control" ng-model="$data.attendee" uib-typeahead="result as result.value for result in ' + this.attrs.eSearchAttendee + '({value: $viewValue})" typeahead-loading="loadingBox" typeahead-no-results="noResultBox" typeahead-wait-ms="1000" typeahead-on-select="' + this.attrs.eOnSelect + '" typeahead-editable="false">';
                } else {
                    checkEl += '    <input type="text" class="form-control" ng-model="$data.attendee" uib-typeahead="result as result.value for result in ' + this.attrs.eSearchAttendee + '({value: $viewValue})" typeahead-loading="loadingBox" typeahead-no-results="noResultBox" typeahead-wait-ms="1000" typeahead-editable="false">';
                }

                checkEl += '    <span class="ico-floating" aria-hidden="true">';
                checkEl += '        <i ng-show="loadingBox" class="fa fa-spinner fa-pulse "></i>';
                checkEl += '        <i ng-show="noResultBox" class="fa fa-ban "></i>';
                checkEl += '    </span>';
                checkEl += '</div>';

                this.inputEl.html(checkEl);

                if (this.attrs.eFormclass) {
                    this.editorEl.addClass(this.attrs.eFormclass);
                }
            }
        });
    }])
    .directive('editableLoadUser',['editableDirectiveFactory',function(editableDirectiveFactory){
        return editableDirectiveFactory({
            directiveName:'editableLoadUser',
            inputTpl: '<div class=""></div>',
            useCopy: true,
            render: function(){
                this.parent.render.call(this);
                // Add label to the input
                var checkEl = '<div class="ico-floating-wrapper">';
                if(this.attrs.eOnSelect) {
                    checkEl += '    <input type="text" class="form-control" ng-model="$data.user" uib-typeahead="result as result.value for result in ' + this.attrs.eSearchUser + '({value: $viewValue})" typeahead-loading="loadingBox" typeahead-no-results="noResultBox" typeahead-wait-ms="1000" typeahead-on-select="' + this.attrs.eOnSelect + '" typeahead-editable="false">';
                } else {
                    checkEl += '    <input type="text" class="form-control" ng-model="$data.user" uib-typeahead="result as result.value for result in ' + this.attrs.eSearchUser + '({value: $viewValue})" typeahead-loading="loadingBox" typeahead-no-results="noResultBox" typeahead-wait-ms="1000" typeahead-editable="false">';
                }
                checkEl += '    <span class="ico-floating" aria-hidden="true">';
                checkEl += '        <i ng-show="loadingBox" class="fa fa-spinner fa-pulse "></i>';
                checkEl += '        <i ng-show="noResultBox" class="fa fa-ban "></i>';
                checkEl += '    </span>';
                checkEl += '</div>';
                this.inputEl.html(checkEl);
                // Add classes to the form
                if (this.attrs.eFormclass) {
                    this.editorEl.addClass(this.attrs.eFormclass);
                }
            }
        });
    }])
    .directive('editableLoadDecree',['editableDirectiveFactory',function(editableDirectiveFactory){
        return editableDirectiveFactory({
            directiveName:'editableLoadDecree',
            inputTpl: '<div class=""></div>',
            useCopy: true,
            render: function(){
                this.parent.render.call(this);
                // Add label to the input
                var checkEl = '<div class="ico-floating-wrapper">';
                if(this.attrs.eOnSelect) {
                    checkEl += '    <input type="text" class="form-control" ng-model="$data.decree.decreeAmendingDecree" uib-typeahead="result as result.value for result in ' + this.attrs.eSearchDecree + '({value: $viewValue})" typeahead-loading="loadingBox" typeahead-no-results="noResultBox" typeahead-wait-ms="1000" typeahead-on-select="' + this.attrs.eOnSelect + '" typeahead-editable="false">';
                } else {
                    checkEl += '    <input type="text" class="form-control" ng-model="$data.decree.decreeAmendingDecree" uib-typeahead="result as result.value for result in ' + this.attrs.eSearchDecree + '({value: $viewValue})" typeahead-loading="loadingBox" typeahead-no-results="noResultBox" typeahead-wait-ms="1000" typeahead-editable="false">';
                }
                checkEl += '    <span class="ico-floating" aria-hidden="true">';
                checkEl += '        <i ng-show="loadingBox" class="fa fa-spinner fa-pulse "></i>';
                checkEl += '        <i ng-show="noResultBox" class="fa fa-ban "></i>';
                checkEl += '    </span>';
                checkEl += '</div>';
                this.inputEl.html(checkEl);
                // Add classes to the form
                if (this.attrs.eFormclass) {
                    this.editorEl.addClass(this.attrs.eFormclass);
                }
            }
        });
    }])
    .directive('editableRepresentative',['editableDirectiveFactory',function(editableDirectiveFactory){
        return editableDirectiveFactory({
            directiveName:'editableRepresentative',
            inputTpl: '<div></div>',
            useCopy: true,
            render: function(){
                this.parent.render.call(this);

                var template = '';

                template += '<div class="col-xs-12">';
                template += '   <input type="checkbox" ng-model="$data.check" id="dataCheck"> <label class="label-breakable" for="dataCheck">{{ \'' + this.attrs.eCheckboxLabel + '\' | translate }}</label>';
                template += '</div>';
                template += '<div class="col-xs-12" ng-show="$data.check">';
                template += '    <div class="ico-floating-wrapper">';
                template += '        <input type="text" class="form-control" ng-model="$data.user" uib-typeahead="result as result.value for result in '+this.attrs.eSearchRepresentative+'({value: $viewValue})" typeahead-loading="loadingBox" typeahead-no-results="noResultBox" typeahead-wait-ms="1000" typeahead-editable="false">';
                template += '        <span class="ico-floating" aria-hidden="true">';
                template += '            <i ng-show="loadingBox" class="fa fa-spinner fa-pulse"></i>';
                template += '            <i ng-show="noResultBox" class="fa fa-ban"></i>';
                template += '        </span>';
                template += '    </div>';
                template += '</div>';

                this.inputEl.html(template);
            }
        });
    }])
    .directive('editableSelectTree',['editableDirectiveFactory',function(editableDirectiveFactory){
        return editableDirectiveFactory({
            directiveName:'editableSelectTree',
            inputTpl: '<div></div>',
            useCopy: true,
            render: function(){
                this.parent.render.call(this);

                var template = '';

                template += '<div class="col-12">';
                template += '   <ui-tree-select ng-model="$data.model" data="$data.options" required="$data.required"></ui-tree-select>'
                template += '</div>';

                this.inputEl.html(template);
            }
        });
    }])
    .directive('editableAnnexaCheckboxList',['editableDirectiveFactory', function(editableDirectiveFactory) {
        return editableDirectiveFactory( {
            directiveName: 'editableAnnexaCheckboxList',
            inputTpl: '<div></div>',
            useCopy: true,
            render: function() {
                this.parent.render.call(this);
                var template = '';
                template += '<annexa-checkbox-list class="checkbox-list" label-col="' + this.attrs.eLabelCol + '" items="$data"></annexa-checkbox-list>';
                this.inputEl.html(template);
            }
        });
    }])
    .directive('editableThirdComposedName',['editableDirectiveFactory',function(editableDirectiveFactory) {
        return editableDirectiveFactory( {
            directiveName: 'editableThirdComposedName',
            inputTpl: '<div></div>',
            useCopy: true,
            render: function() {
                this.parent.render.call(this);
                var template = '';
                template += '<div class="row">';
                if(this.attrs.ePropName != 'PHISICAL'){
                    template += '   <div class="col-sm-12">';
                    template += '       <input id="thirdCorporateName" name="thirdCorporateName" class="form-control" type="text" ng-model="$data.corporateName" maxlength="255" style="width:100%;"/>';
                    template += '   </div>';
                }
                else {
                    template += '   <div class="col-sm-4">';
                    template += '       <input id="thirdName" name="thirdName" class="form-control" type="text" ng-model="$data.name" style="width:100%;"  maxlength="255"/>';
                    template += '   </div>';
                    template += '   <div class="col-sm-4">';
                    template += '       <input id="thirdSurename1" name="thirdSurename1" class="form-control" type="text" ng-model="$data.surename1" style="width:100%;"  maxlength="255"/>';
                    template += '   </div>';
                    template += '   <div class="col-sm-4">';
                    template += '       <input id="thirdSurename2" name="thirdSurename2" class="form-control" type="text" ng-model="$data.surename2" style="width:100%;"  maxlength="255"/>';
                    template += '   </div>';
                }
                template += '</div>';
                this.inputEl.html(template);
            }
        });
    }])
    .directive('editableUibTimepicker',['editableDirectiveFactory',function(editableDirectiveFactory) {
        return editableDirectiveFactory({
            directiveName: 'editableUibTimepicker',
            inputTpl: '<div></div>',
            useCopy: true,
            render: function () {
                this.parent.render.call(this);

                var template = '';

                template += '   <div uib-timepicker ng-model="$data.modelDate" hour-step="1" minute-step="15" show-meridian="false" show-spinners="false"></div>';

                this.inputEl.html(template);
            }
        })
    }])
    .directive('editableUibDatepicker',['editableDirectiveFactory',function(editableDirectiveFactory) {
        return editableDirectiveFactory( {
            directiveName: 'editableUibDatepicker',
            inputTpl: '<div class="agafaData "></div>',
            useCopy: true,
            render: function() {
                this.parent.render.call(this);

                var template = '';

                var dpOptions = '{ format: \'dd/MM/yyyy\', showWeeks: false, startingDay: 1}';

                template += '   <input type="text" class="form-control" uib-datepicker-popup="dd/MM/yyyy" datepicker-options="' + dpOptions + '" ng-model="$data.modelDate" is-open="datepicker.opened" showWeeks="false" current-text="{{ \'global.alerts.today\' | translate}}" clear-text="{{ \'global.literals.clear\' | translate}}" close-text="{{ \'global.literals.close\' | translate}}" ng-click="datepicker.open($event)">';
                template += '   <span class="input-group-btn">';
                template += '       <button type="button" class="btn btn-default" ng-click="datepicker.open($event)"><i class="fa fa-calendar" title="{{\'global.literals.showCalendar\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.showCalendar\' | translate}}</span></button>';
                template += '   </span>';
                this.inputEl.html(template);
            }
        });
    }])
    .directive('editableFileUpload',['editableDirectiveFactory',function(editableDirectiveFactory) {
        return editableDirectiveFactory( {
            directiveName: 'editableFileUpload',
            inputTpl: '<div></div>',
            useCopy: true,
            render: function() {
                this.parent.render.call(this);

                var template = '';
                template += '<input type="file" file-model="$data" ng-model="$data" name="' + this.attrs.eId + '"/>'

                this.inputEl.html(template);
            }
        });
    }])
    .directive('editableRadiolistCircleIcon', ['editableDirectiveFactory','editableNgOptionsParser',
        function(editableDirectiveFactory, editableNgOptionsParser) {
            return editableDirectiveFactory({
                directiveName: 'editableRadiolistCircleIcon',
                inputTpl: '<span></span>',
                render: function() {
                    this.parent.render.call(this);
                    var parsed = editableNgOptionsParser(this.attrs.eNgOptions);

                    var html = '<label data-ng-repeat="'+parsed.ngRepeat+'">'+
                        '<input type="radio" data-ng-disabled="::' + this.attrs.eNgDisabled + '" data-ng-model="$parent.$parent.$data" data-ng-value="{{::'+parsed.locals.valueFn+'}}"> '+
                        ' <circleicon bgcolor="'+parsed.locals.displayFn+'">'+this.attrs.literalShow+'</circleicon></label>';

                    this.inputEl.removeAttr('ng-model');
                    this.inputEl.removeAttr('ng-options');
                    this.inputEl.html(html);
                },
                autosubmit: function() {
                    var self = this;
                    self.inputEl.bind('change', function() {
                        setTimeout(function() {
                            self.scope.$apply(function() {
                                self.scope.$form.$submit();
                            });
                        }, 500);
                    });
                }
            });
    }])
    .directive('radiolistCircleIcon', ['$compile', function($compile) {
        var getTemplate = function(scope) {
            var template = '';
            template += '<circleicon bgcolor="{{selectedObject.description}}" title ="{{selectedObject.description}}">{{acronym}}</circleicon>';
            return template;
        }

        return {
            restrict: 'E',
            replace: true,
            scope: {
                modelValue: '=',
                selectOptions: '='
            },
            link: function (scope, element, attr) {
                scope.calculateSelectedObject = function() {
                    var selectedObjects = $linq(scope.selectOptions).where("x => x.id == " + scope.modelValue).toArray();
                    scope.selectedObject = {};
                    if (selectedObjects && selectedObjects.length > 0) {
                        scope.selectedObject = selectedObjects[0];
                    }
                }
                scope.acronym = scope.$parent.$parent.$parent.modal.data.acronym;
                scope.$watch("modelValue", function() {
                    scope.calculateSelectedObject();
                    element.html(getTemplate(scope)).show();
                    $compile(element.contents())(scope);
                });
                element.html(getTemplate(scope)).show();
                $compile(element.contents())(scope);
            }
        }
    }])
    .directive('editableSummernote',['editableDirectiveFactory',function(editableDirectiveFactory){
        return editableDirectiveFactory({
            directiveName:'editableSummernote',
            inputTpl: '<div></div>',
            useCopy: true,
            render: function(){
                this.parent.render.call(this);
                var template = '';

                template += '<div class="col-12">';
                template += '   <summernote ng-if="$parent.preventPaste" config="$parent.optionsSummerNote" ng-model="$data.modelSummerNote" on-paste="$parent.onPaste(evt)"></summernote>';
                template += '   <summernote ng-if="!$parent.preventPaste" config="$parent.optionsSummerNote" ng-model="$data.modelSummerNote"></summernote>';
                template += '</div>';

                this.inputEl.html(template);
            }
        });
    }])
    .directive('editableAnnexaFilterInput',['editableDirectiveFactory', 'SecFactory',function(editableDirectiveFactory, SecFactory){
        return editableDirectiveFactory({
            directiveName:'editableAnnexaFilterInput',
            inputTpl: '<div class=""></div>',
            render: function(){
                this.parent.render.call(this);
                // Add label to the input
                var checkEl =  '    <div class="input-group box-tool-group">';
                checkEl += '            <span class="input-group-btn">';
                checkEl += '                <span class="btn-sm p-l-xs p-r-0 text-grey"><i class="fa fa-search" aria-hidden="true"></i></span>';
                checkEl += '            </span>';
                checkEl += '            <div class="ico-floating-wrapper">';
                checkEl += '                <input type="text" class="form-control" ng-model="$data[\''+this.attrs.eModelProperty+'\']" uib-typeahead="result as result.value for result in ' + this.attrs.eSearchUser + '({value: $viewValue})" typeahead-loading="loadingBox" typeahead-no-results="noResultBox" typeahead-wait-ms="1000"  typeahead-editable="false"/>';
                checkEl += '                <span class="ico-floating" aria-hidden="true">';
                checkEl += '                    <i ng-show="loadingBox" class="fa fa-spinner fa-pulse "></i>';
                checkEl += '                    <i ng-show="noResultBox" class="fa fa-ban "></i>';
                checkEl += '                </span>';
                checkEl += '            </div>';
                checkEl += '        </div>';
                checkEl += '        <p class=""m-b-0">';
                checkEl += '            <a ng-click="'+this.attrs.eAdvancedSearch+'()" href="" class="text-primary cursiva-grey no-bg no-border pull-left m-b-neg-1">';
                checkEl += '                <span translate="global.literals.advanced_search">Busqueda avanzada</span>';
                checkEl += '            </a>';
                checkEl += '        </p>';
                checkEl += '    </div>';
                this.inputEl.html(checkEl);
                // Remove class to the form
                this.editorEl.removeClass("form-inline");
            }
        });
    }])
    .directive('annexaEditableField',['$compile', 'CommonService', 'HelperService','$rootScope', 'AnnexaPermissionsFactory', function ($compile, CommonService, HelperService, $rootScope, AnnexaPermissionsFactory) {
        var getTemplate = function(scope) {
            var template = '';

            if(scope.type == "composedThirdName"){
                template += '<div class="row">';
                template += '   <div class="col-sm-12">';
                template += '       <h4 class="modal-title main-title m-b">';
                template += '           <a editable-third-composed-name="modelValue" onbeforesave="save({val: $data, prop: modelProperty})" e-form="'+scope.modelValue.thirdType+'Form" e-prop-name="{{modelValue.thirdType}}" blur="ignore">';
                template += '               {{getThirdName(modelValue)}}';
                template += '           </a>';
                template += '           <a ng-click="'+ scope.modelValue.thirdType +'Form.$show();" href="" ng-if="!'+ scope.modelValue.thirdType +'Form.$visible" permission permission-only="permissions" role="button" aria-label="{{\'global.literals.edit\' | translate}}">';
                template += '               <i class="fa fa-pencil-square grey-pencil text-14p" title="{{\'global.literals.edit\' | translate}}"></i>';
                template += '               <span class="sr-only">{{\'global.literals.edit\' | translate}}</span>';
                template += '           </a>';
                template += '       </h4>';
                template += '   </div>';
                template += '</div>';
                return template;
            }

            if(!scope.header) {
                if (scope.childClass) {
                    template += '<div class="clearfix m-b-sm ' + scope.childClass + '">';
                } else {
                    template += '<div class="clearfix m-b-sm">';
                }


                template += '   <span ng-if="label" class="block small m-b-0" translate="{{label}}">{{label}}</span>';
                template += '   <div class="pos-relative '+((scope.deleteFunction)?'d-flex':'')+'">';
                if (scope.type == 'radio' || scope.type == 'radio-color') {
                    template += '       <p class="radio-list label-strong m-b-xs';
                } else if(scope.type == 'summerNote') {
                    template += '       <p class="m-b-xs';
                }else{
                    template += '       <p class="_600 m-b-xs p-r-md '+((scope.deleteFunction)?'pos-relative flex-1':'');
                }

                if (scope.type == 'representative') {
                    template += ' buttons-m-t-200'
                }
                ; // representative es maqueta diferent
                template += '">';
            } else {
                template += '<span class="inline m-r-xs">{{label | translate}}:</span>';
            }

            if(scope.header) {
                template += '<span class="inline">';
            }

            switch (scope.type) {
                case 'time':
                    template += '   <span class="editable-click inline wi-100" editable-uib-timepicker="modelValueDate" onbeforesave="save({val: $data.modelDate, prop: modelProperty})" e-form="' + scope.modelProperty + 'Form" '+ scope.elementRequired + ' blur="{{blur}}">';
                    template += '        {{ modelValueDate.modelDate | date:\'HH:mm\' || \' \'}}';
                    template += '   </span>';
                    break;
                case 'date':
                    template += '    <span class="editable-click inline wi-100" editable-uib-datepicker="modelValueDate" onbeforesave="save({val: $data.modelDate, prop: modelProperty})" e-form="' + scope.modelProperty + 'Form" '+ scope.elementRequired + ' blur="{{blur}}">';
                    template += '        {{ modelValueDate.modelDate | date:\'dd/MM/yyyy\' || \' \'}}';
                    template += '    </span>';
                    break;
                case 'datetime':
                    template += '    <span class="editable-click inline wi-100" editable-uib-datepicker="modelValueDate" onbeforesave="save({val: $data.modelDate, prop: modelProperty})" e-form="' + scope.modelProperty + 'Form" '+ scope.elementRequired + ' blur="{{blur}}">';
                    template += '        {{ modelValueDate.modelDate | date:\'dd/MM/yyyy HH:mm:ss\' || \' \'}}';
                    template += '    </span>';
                    break;
                case 'select':
                    if(scope.isIdentifier){
                        template += ' <span class="editable-click inline wi-100"  editable-select="modelValue" onbeforesave="save({val: $data, prop: modelProperty})" e-form="' + scope.modelProperty + 'Form" ' + scope.elementRequired + ' blur="{{blur}}" e-ng-options="item.id as item[langCol] | translate for item in selectOptions"' + scope.elementChange + '>';
                    }else {
                        if(!scope.isBoolean) {
                            var obj = HelperService.selectSelected(scope.selectOptions, scope.modelValue, scope.modelProperty, false, false);
                            if (obj && obj.length > 0) {
                                scope.modelValue = obj[0];
                            }
                        } else {
                            scope.modelValue = scope.modelValue ? true : false;
                        }

                        template += ' <span class="editable-click inline wi-100"  editable-select="modelValue" onbeforesave="save({val: $data, prop: modelProperty})" e-form="' + scope.modelProperty + 'Form" ' + scope.elementRequired + ' blur="{{blur}}" e-ng-options="item as item[langCol] | translate for item in selectOptions"' + scope.elementChange + '>';
                    }
                    if(scope.printFunction) {
                        template += '       {{ printFunction(modelValue) }}';
                    } else if(scope.printFunctionHtml) {
                        template +=  '      <span ng-bind-html="printFunctionHtml(modelValue)"></span>'
                    }else{
                        template += '       {{ modelValue[langCol] }}';
                    }
                    template += '   </span>';
                    break;
                case 'select-multiple':
                    if(scope.isIdentifier){
                        template += '   <span class="editable-click inline wi-100" editable-select="modelValue" onbeforesave="save({val: $data, prop: modelProperty})" e-multiple e-form="' + scope.modelProperty + 'Form" ' + scope.elementRequired + ' blur="{{blur}}" e-ng-options="item.id as item[langCol] for item in selectOptions"' + scope.elementChange + '>';
                    }else {
                        var obj = HelperService.selectSelected(scope.selectOptions, scope.modelValue, scope.modelProperty, false, true);
                        if(obj && obj.length> 0){
                            scope.modelValue = obj;
                        }
                        template += '   <span class="editable-click inline wi-100" editable-select="modelValue" onbeforesave="save({val: $data, prop: modelProperty})" e-multiple e-form="' + scope.modelProperty + 'Form" ' + scope.elementRequired + ' blur="{{blur}}" e-ng-options="item as item[langCol] for item in selectOptions track by item.id"' + scope.elementChange + '>';
                    }
                    template += '       {{ printFunction(modelValue) }}';
                    template += '   </span>';
                    break;
                case 'select-tree':
                    template += '   <span class="editable-click inline wi-100 " editable-select-tree="modelValue" onbeforesave="save({val: $data.model, prop: modelProperty})" e-form="' + scope.modelProperty + 'Form" ' + scope.elementRequired + ' blur="{{blur}}">';
                    if(scope.printFunction) {
                        template += '       {{ printFunction(modelValue) }}';
                    } else {
                        template += '       {{ modelValue.model.$selected.title }}';
                    }
                    template += '   </span>';
                    break;
                case 'textarea':
                	template += '  <span class="editable-click inline wi-100 " editable-textarea="modelValue" onbeforesave="save({val: $data, prop: modelProperty})" e-form="' + scope.modelProperty + 'Form" '+ scope.elementRequired + ' blur="{{blur}}" e-maxlength="{{maxlength}}" e-rows="{{rows}}"';
                    if(scope.updateOnBlur == "true"){
                        template += '  e-ng-model-options="{ updateOn: \'blur\' }"';
                    }
                    template +='>';
                    if(scope.langCol) {
                        template += '       {{ modelValue[langCol] }}';
                    } else {
                        template += '       {{ modelValue }}';
                    }
                    template += '   </span>';
                    break;
                case 'representative':
                    template += '   <span class="editable-click inline wi-100 " editable-representative="modelValue" onbeforesave="save({val: $data, prop: modelProperty})" e-form="' + scope.modelProperty + 'Form" '+ scope.elementRequired + ' blur="{{blur}}" e-search-representative="search" e-checkbox-label="{{checkboxLabel}}">';
                    template += '       {{ modelValue.check ? \'global.literals.actionOtherUserName\' : \'global.literals.noActionOtherUser\' | translate }} {{ (modelValue.check && modelValue.user && modelValue.user.value) ? modelValue.user.value : \'\' }}';
                    template += '   </span>';
                    break;
                case 'loadUser':
                    template += '   <span class="editable-click inline wi-100 " editable-load-user="modelValue" onbeforesave="save({val: $data, prop: modelProperty})" e-form="' + scope.modelProperty + 'Form" '+ scope.elementRequired + ' blur="{{blur}}" e-search-user="search">';
                    template += '       {{modelValue.user.value || \'\'}}';
                    template += '   </span>';
                    break;
                case 'filterInput':
                    template += '   <span class="editable-click inline wi-100 " editable-annexa-filter-input="modelValue" onbeforesave="save({val: $data, prop: modelProperty})" oncancel="cancel()" e-model-property="'+scope.modelProperty+'" e-form="' + scope.modelProperty + 'Form" '+ scope.elementRequired + ' blur="{{blur}}" e-search-user="search" e-advanced-search="advancedSearch">';
                    template += '       {{modelValue.'+scope.modelProperty+'.value || \'\'}}';
                    template += '   </span>';
                    break;
                case 'annexa-checkbox-list':
                    template += '   <span class="editable-click wi-100 show" editable-annexa-checkbox-list="modelValue" onbeforesave="save({val: $data, prop: modelProperty})" e-form="' + scope.modelProperty + 'Form" '+scope.elementRequired + ' blur="{{blur}}" e-label-col="{{labelCol}}">';
                    template += '       {{ printFunction(modelValue) }}';
                    template += '   </span>';
                    break;
                case 'text':
                	if(scope.modelLength && scope.modelLength > 0){
                		template += '   <span href="" class="editable-click inline wi-100" editable-text="modelValue" onbeforesave="save({val: $data, prop: modelProperty})" e-form="' + scope.modelProperty + 'Form"'+ scope.elementRequired + 'blur="{{blur}}" e-maxlength="'+scope.modelLength+'"';
                	}else{
                		template += '   <span href="" class="editable-click inline wi-100" editable-text="modelValue" onbeforesave="save({val: $data, prop: modelProperty})" e-form="' + scope.modelProperty + 'Form"'+ scope.elementRequired + 'blur="{{blur}}"';
                	}
                	if(scope.updateOnBlur == "true"){
                        template += '  e-ng-model-options="{ updateOn: \'blur\' }"';
                    }
                    template +='>';
                    if(scope.langCol) {
                        template += '       {{ modelValue[langCol] }}';
                    } else {
                        template += '       {{ modelValue }}';
                    }
                    template += '   </span>';
                    break;
                case 'mail':
                    if(scope.modelLength && scope.modelLength > 0){
                        template += '   <span class="editable-click  inline wi-100" editable-email="modelValue" onbeforesave="save({val: $data, prop: modelProperty})" e-form="' + scope.modelProperty + 'Form"'+ scope.elementRequired + 'blur="{{blur}}" e-maxlength="'+scope.modelLength+'">';
                    }else{
                        template += '   <span class="editable-click  inline wi-100" editable-email="modelValue" onbeforesave="save({val: $data, prop: modelProperty})" e-form="' + scope.modelProperty + 'Form"'+ scope.elementRequired + 'blur="{{blur}}">';
                    }
                    template += '       {{ modelValue }}';
                    template += '   </span>';
                    break;
                case 'language':
                    template += '   <span class="editable-click  inline wi-100" editable-languageinputs="modelValue" e-model-language="modelValue" e-label="" onbeforesave="saveInternal({val: $data, prop: modelProperty})" oncancel="cancelLanguage()" e-form="' + scope.modelProperty + 'Form" '+ scope.elementRequired + ' blur="{{blur}}">';
                    template += '       {{ modelValue[modelProperty] }}';
                    template += '   </span>';
                    break;
                case 'file-upload':
                    template += '   <span class="editable-click  inline wi-100" editable-file-upload="modelValue" e-id="modelProperty" onbeforesave="save({val: $data, prop: modelProperty})" e-accept="{{accept}}" e-form="' + scope.modelProperty + 'Form" '+ scope.elementRequired + ' blur="{{blur}}" e-ng-change="setFile({object: $data})" >';
                    template += '       {{ printFunction(model) }}';
                    template += '   </span>';
                    break;
                case 'checkbox':
                    template += '   <span class="editable-click inline wi-100" editable-checkbox="modelValue" onbeforesave="save({val: $data, prop: modelProperty})" e-form="' + scope.modelProperty + 'Form" '+scope.elementRequired + ' blur="{{blur}}" e-title="{{label | translate}}">';
                    template += '       {{ printFunction(modelValue) }}';
                    template += '   </span>';
                    break;
                case 'radio':
                    template += '   <span class="editable-click wi-100 show" ng-show="!' + scope.modelProperty + 'Form.$visible" editable-radiolist="modelValue" e-ng-options="item.id as item.description for item in selectOptions" onbeforesave="save({val: $data, prop: modelProperty})" e-form="' + scope.modelProperty + 'Form" '+scope.elementRequired + ' blur="{{blur}}" e-title="{{label}}">';
                    template += '       {{ printFunction(modelValue) }}';
                    template += '   </span>';
                    break;
                case 'radio-color':
                    template += '   <span class="editable-click wi-100 show" ng-show="!' + scope.modelProperty + 'Form.$visible" editable-radiolist-circle-icon="modelValue" literal-show="'+scope.$parent.modal.data.acronym+'" e-ng-options="item.id as item.description for item in selectOptions" onbeforesave="save({val: $data, prop: modelProperty})" e-form="' + scope.modelProperty + 'Form" '+scope.elementRequired + ' blur="{{blur}}" e-title="{{label}}">';
                    template += '       <radiolist-circle-icon model-value="modelValue" select-options ="selectOptions"></radiolist-circle-icon>';
                    template += '   </span>';
                    break;
                case 'number':
                    template += '   <span class="editable-click wi-100 show" ng-show="!' + scope.modelProperty + 'Form.$visible" editable-number="modelValue" e-min="1" e-max="{{modelLength}}"e-form="' + scope.modelProperty + 'Form" '+scope.elementRequired + ' blur="{{blur}}" onbeforesave="save({val: $data, prop: modelProperty})">';
                    template += '      {{modelValue}}';
                    template += '   </span>';
                    break;
                case 'numberMin':
                    template += '   <span class="editable-click wi-100 show" ng-show="!' + scope.modelProperty + 'Form.$visible" editable-number="modelValue" e-min="'+((scope.minLength)?scope.minLength:0)+'" e-max="{{modelLength}}"e-form="' + scope.modelProperty + 'Form" '+scope.elementRequired + ' blur="{{blur}}" onbeforesave="save({val: $data, prop: modelProperty})">';
                    template += '      {{modelValue}}';
                    template += '   </span>';
                    break;
                case 'summerNote':
                    scope.optionsSummerNote = {
                        height: 300,
                        toolbar: [
                            ['headline', ['style']],
                            ['style', ['bold', 'italic', 'underline', 'superscript', 'subscript', 'strikethrough', 'clear']],
                            ['alignment', ['ul', 'ol', 'paragraph']],
                            ['insert', ['link']],
                            ['view', ['codeview']]
                        ]
                    };
                    if(scope.preventPaste) {
                        scope.onPaste = function(e) {
                            e.preventDefault();
                        }
                    }
                    template += '   <span href="" class="editable-click inline wi-100 " editable-summernote="modelValueSumernote" onbeforesave="save({val: $data, prop: modelProperty})" e-form="' + scope.modelProperty + 'Form" '+ scope.elementRequired + '  blur="{{blur}}" options="{{optionsSummerNote}}" ng-bind-html="modelValue">';
                    template += '   </span>';
                    break;
                case 'loadDecree':
                    template += '   <span class="editable-click inline wi-100 " editable-load-decree="modelValue" onbeforesave="save({val: $data, prop: modelProperty})" e-form="' + scope.modelProperty + 'Form" '+ scope.elementRequired + ' blur="{{blur}}" e-search-decree="search">';
                    if(scope.printFunction) {
                        template += '       {{ printFunction(modelValue) }}';
                    } else {
                        template += '       {{ modelValue.decreeAmending.extract || \'\'}}';
                    }
                    template += '   </span>';
                    break;
                default:
                    break;
            }
            if(scope.header) {
                template += '</span>';
            }

                if(!scope.isReadOnly || scope.isReadOnly != "false") {
                    var classAbs = scope.header ? '' : 'editable-click__button grey-pencil';

                    if(scope.header) {
                        template += '<span class="inline">';
                    }

                    if (scope.editFunction) {
                        template += '            <a href="" ng-click="editFunction()" ng-if="!' + scope.modelProperty + 'Form.$visible && canEdit" class="' + classAbs + '" role="button" aria-label="{{\'global.literals.edit\' | translate}}"  annexa-permission annexa-permission-only="permissions">';
                        template += '               <i class="fa fa-pencil-square " title="{{\'global.literals.edit\' | translate}}" aria-hidden="true" ></i>';
                        template += '               <span class="sr-only">{{\'global.literals.edit\' | translate}}</span>';
                        template += '            </a>';                    	
                    } else if(scope.isArchivedDossier || !scope.profile) {
                        template += '            <a href="" ng-click="' + scope.modelProperty + 'Form.$show()" ng-if="!' + scope.modelProperty + 'Form.$visible && canEdit" class="' + classAbs + '" role="button" aria-label="{{\'global.literals.edit\' | translate}}"  annexa-permission annexa-permission-only="permissions">';
                        template += '               <i class="fa fa-pencil-square " title="{{\'global.literals.edit\' | translate}}" aria-hidden="true" ></i>';
                        template += '               <span class="sr-only">{{\'global.literals.edit\' | translate}}</span>';
                        template += '            </a>';
                    } else {
                        template += '            <a href="" ng-click="' + scope.modelProperty + 'Form.$show()" ng-if="!' + scope.modelProperty + 'Form.$visible && canEdit" class="' + classAbs + '" role="button" aria-label="{{\'global.literals.edit\' | translate}}" annexa-permission annexa-permission-only="permissions" annexa-permission-only-profile="profile">' +
                            '<i class="fa fa-pencil-square" title="{{\'global.literals.edit\' | translate}}" ></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span>' +
                            '</a>';
                    }
                    if(scope.header) {
                        template += '</span>';
                    }
                }

            if(!scope.header) {
                template += '       </p>';
                if(scope.deleteFunction){
                	template += '            <a href="" ng-click="deleteInternalFunction()" ng-if="!' + scope.modelProperty + 'Form.$visible && canEdit" class="grey-pencil" role="button" aria-label="{{\'global.literals.deleteContent\' | translate}}"  annexa-permission annexa-permission-only="permissions">';
                    template += '               <i class="fa fa-remove " title="{{\'global.literals.deleteContent\' | translate}}" aria-hidden="true" ></i>';
                    template += '               <span class="sr-only">{{\'global.literals.deleteContent\' | translate}}</span>';
                    template += '            </a>';
                }
                template += '   </div>';
                template += '</div>';
            }

            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            scope: {
                type: '@',
                label: '@',
                modelValue: '=',
                modelProperty: '@',
                modelLength: '=',
                save: '&',
                required: '@',
                blur: '@',
                selectOptions: '=',
                langCol: '=',
                changeOption: '&',
                maxlength: '@',
                rows: '@',
                search: '&',
                checkboxLabel: '@',
                printFunction: '=',
                printFunctionHtml: '=',
                isIdentifier: '@',
                accept: '@',
                model: '=',
                labelCol: '=',
                isReadOnly: '@',
                permissions: '=',
                profile: '=',
                childClass:'@',
                canEdit: '=?',
                header: '=?',
                advancedSearch: '&',
                cancel: '&',
                preventPaste: '=?',
                updateOnBlur:'@',
                isBoolean: '@',
                editFunction: '=?',
                deleteFunction: '=?',
                minLength:'=?',
                isArchivedDossier: '=?'
            },
            link: function (scope, element, attr) {
                if(scope.canEdit == undefined) {
                    scope.canEdit = true;
                }

                if(scope.type == 'date' || scope.type == 'time' || scope.type == 'datetime') {
                    if(scope.modelValue) {
                        scope.modelValueDate = {modelDate:new Date(scope.modelValue)};
                    }else{
                        scope.modelValueDate = {modelDate:null};
                    }
                }

                if(scope.type == 'select-tree') {
                    var options = CommonService.getTreeData(scope.selectOptions, scope.langCol);
                    var selected = undefined;
                    if(scope.modelValue) {
                        var found = false;

                        angular.forEach(options, function (val, key) {
                            if (!found) {
                                selected = $linq(val).singleOrDefault(undefined, "x => x.id == " + scope.modelValue.id);
                                if (selected) {
                                    found = true;
                                }
                            }
                        });

                        scope.modelValue = {
                            model: {$selected: selected},
                            options: options,
                            required: scope.required
                        };
                    } else {
                        scope.modelValue = {
                            model: {$selected: selected},
                            options: options,
                            required: scope.required
                        };
                    }
                }

                if(scope.type == 'summerNote') {
                    if(scope.modelValue) {
                        scope.modelValueSumernote = {modelSummerNote:scope.modelValue};
                    }else{
                        scope.modelValueSumernote = {modelSummerNote:""};
                    }
                }

                if(scope.required) {
                    if(scope.required == true || scope.required == 'true') {
                        scope.elementRequired = ' e-required ';
                    }else{
                        scope.elementRequired = ' ';
                    }
                } else {
                    scope.elementRequired = ' ';
                }

                scope.datepicker = {};

                scope.datepicker.opened = false;

                scope.datepicker.open = function ($event) {
                    scope.datepicker.opened = !scope.datepicker.opened;
                };

                scope.elementChange = '';
                if(scope.changeOption) {
                    scope.elementChange =' e-ng-change="changeOption({object: $data})"';
                }

                scope.representative_username = '';
                if(scope.type == 'representative' && scope.modelValue.check && scope.modelValue.user && scope.modelValue.user.value) {
                    scope.representative_username = scope.modelValue.user.value;
                }

                scope.getThirdName = function(data){
                    if(data.thirdType == "PHISICAL"){
                        return data.name + ", "+ data.surename1+((data.surename2)?" "+ data.surename2: "");
                    }
                    else {
                        return data.corporateName;
                    }
                };

                scope.setFile = function(object) {
                    scope.$apply(function(scope) {
                        scope.modelValue = object.files[0];
                    })
                };
                
                if(scope.type == 'language') {
            		scope.modelValueLanguage = {
                			language1: scope.modelValue.language1,
                			language2: scope.modelValue.language2,
                			language3: scope.modelValue.language3
                	}
            		scope.saveInternal = function(data) {
            			scope.save(data);
            			scope.modelValueLanguage = {
                    			language1: scope.modelValue.language1,
                    			language2: scope.modelValue.language2,
                    			language3: scope.modelValue.language3
                    	}
            		}
                    scope.cancelLanguage = function() {
                    	if(scope.modelValueLanguage) {
                    		scope.modelValue.language1 = scope.modelValueLanguage.language1;
                    		scope.modelValue.language2 = scope.modelValueLanguage.language2;
                    		scope.modelValue.language3 = scope.modelValueLanguage.language3;
                    	}
                    }
                }

                scope.deleteInternalFunction = function(){
                	scope.deleteFunction(scope.modelProperty);
                }
                scope.openWindow = function(element, modelValue, isUpdate){
                	if(scope.canOpenWindow(modelValue)){
                		var modelValueString = ((modelValue && scope.langCol && modelValue[scope.langCol])?modelValue[scope.langCol]:modelValue);
                		if(modelValueString){
                			window.open(modelValueString);
                		}else if(isUpdate){
                			if(scope.isArchivedDossier){
                    			element.$show(); 
                    		}else if(!scope.isReadOnly || scope.isReadOnly != "false") {
                                if (scope.editFunction && scope.canEdit) {
                    				scope.editFunction();                                            	
                                } else if(!scope.profile  && scope.canEdit) {
                    				element.$show();
                                } else if(scope.canEdit){
                    				element.$show();
                                }
                            }
                		}
                	}else if(isUpdate){
                		if(scope.isArchivedDossier){
                			element.$show(); 
                		}else if(!scope.isReadOnly || scope.isReadOnly != "false") {
                            if (scope.editFunction && scope.canEdit) {
                				scope.editFunction();                                           	
                            } else if(!scope.profile  && scope.canEdit) {
                				element.$show();
                            } else if(scope.canEdit){
                            	if(scope.profile && haveProfile(scope.profile)){
                            		element.$show();
                            	}
                            }
                        }
                	}
                }
                var haveProfile = function(profile){
                	var have = false;
                	angular.forEach(scope.permissions, function (permission) {
	                	if(profile) {
	                        if(!Array.isArray(profile) && profile.id) {
	                            have = have || AnnexaPermissionsFactory.haveSinglePermissionDual(permission, permissionOnlyProfile.id);
	                        } else {
	                            have = have || AnnexaPermissionsFactory.haveSinglePermissionDual(permission, profile);
	                        }
	                    }else{
	                        have = have || AnnexaPermissionsFactory.haveSinglePermissionDual(permission);
	                    }
                	});
                	return have;
                }
                scope.canOpenWindow = function(modelValue){
            		var exist = false;
                	if(modelValue){
                		var startUrls = ['http://','https://'];
                		if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.url_editable_inputs && $rootScope.app.configuration.url_editable_inputs.urls && $rootScope.app.configuration.url_editable_inputs.urls.length > 0){
                			_.forEach($rootScope.app.configuration.url_editable_inputs.urls, function(item){
                				startUrls.push(item);
                			});
                		}
                		var modelValueString = ((modelValue && scope.langCol && modelValue[scope.langCol])?modelValue[scope.langCol]:modelValue);
                		if(modelValueString){
	                		_.forEach(startUrls, function(item){
	                			if(modelValueString.startsWith(item.toLowerCase())){
	                				exist = true;
	                			}
	                		});
                		}
                	}
                	return exist;
                }
                
                element.html(getTemplate(scope)).show();

                $compile(element.contents())(scope);
            }
        }
    }])
    .directive('annexaEditableTable',['$compile', 'HelperService', function ($compile, HelperService) {
        var getTemplate = function (scope) {
            var template = '';

            template += '<div class=" p-a grey-100 p-y-sm b-all ">';
            template += '   <h6 class="p-x-0 form-control-label"><span translate="{{definition.title}}">{{definition.title}}</span></h6>';
            template += '   <table class="table table-hover m-a-0 ">';
            template += '       <thead>';
            template += '           <tr style="font-weight: bold">';
            template += '               <th ng-repeat="column in definition.columns" ng-style="column.style">';
            template += '                   <span translate="{{column.title}}">{{column.title}}</span>';
            template += '               </th>';
            template += '               <th style="width:8%"><span ng-show="rowform' + scope.definition.id + '.$visible"></span></th>';
            template += '           </tr>';
            template += '       </thead>';
            template += '       <tbody>';
            template += '           <tr ng-repeat="item in definition.items">';
            template += '               <td ng-repeat="column in definition.columns">';
            template += '                   <span ng-if="column.allProfiles == \'falso\'">';
            template += '                       <span ng-if="column.type == \'select\'" editable-select="item[column.key]" e-form="rowform' + scope.definition.id + '" e-ng-options="element as element[column.elementLabel] | translate for element in column.getElements()" onbeforesave="column.elementCheckFunction($data, item, this);">';
            template += '                           {{ column.print(item) }}';
            template += '                       </span>';
            template += '                   </span>';
            template += '                   <span ng-if="column.allProfiles == \'verdad\'">';
            template += '                       <span ng-if="column.type == \'select\'" editable-select="item[column.key]" e-form="rowform' + scope.definition.id + '" e-ng-options="element as element[column.elementLabel] | translate for element in column.elements" onbeforesave="column.elementCheckFunction($data, item, this);">';
            template += '                           {{ column.print(item) }}';
            template += '                       </span>';
            template += '                   </span>';
            template += '                   <span ng-if="column.type == \'select-multiple\'" editable-ui-select="item[column.key]" data-e-multiple data-e-form="rowform' + scope.definition.id + '" theme="bootstrap" data-e-ng-model="item[column.key]" onbeforesave="column.elementCheckFunction($data, item, this);" data-e-style="min-width: 100%;">';
            template += '                       {{ column.print(item) }}';
            template += '                       <editable-ui-select-match placeholder="">';
            template += '                           {{$item[column.elementLabel]}}';
            template += '                       </editable-ui-select-match>'
            template += '                       <editable-ui-select-choices repeat="element as element in column.elements | filter: $select.search track by $index">';
            template += '                           {{element[column.elementLabel]}}';
            template += '                       </editable-ui-select-choices>';
            template += '                   </span>';
            template += '                   <span ng-if="column.type == \'load-user\'" editable-text="item.userSelect" e-form="rowform' + scope.definition.id + '" e-uib-typeahead="result as result.value for result in column.loadUsers({val: $viewValue})" e-typeahead-loading="loadingBox" e-typeahead-no-results="noResultBox" e-typeahead-min-length="3" e-typeahead-wait-ms="1000" onbeforesave="column.checkRequired($data, column, item)" e-required="{{column.required}}">';
            template += '                   	{{ column.elementPrint(item.userSelect) }}';
            template += '                   </span>';
            template += '               </td>';
            template += '               <td class="text-nowrap">';
            template += '                   <form editable-form="" name="rowform' + scope.definition.id + '" ng-show="rowform' + scope.definition.id + '.$visible" class="form-buttons form-inline" shown="definition.inserted == item" onbeforesave="definition.updateFunction($data, $index)">';
            template += '                       <button type="submit" ng-disabled="rowform' + scope.definition.id + '.$waiting" class="btn btn-sm primary"><span class="fa fa-check"></span></button>';
            template += '                       <button type="button" ng-disabled="rowform' + scope.definition.id + '.$waiting" class="btn btn-sm btn-danger" ng-click="definition.updateFunction(rowform' + scope.definition.id + ', $index, true)"><span class="fa fa-times"></span></button>';
            template += '                   </form>';
            template += '                   <div class="buttons" ng-show="!rowform' + scope.definition.id + '.$visible">';
            template += '                       <button type="button" class="btn btn-sm primary" ng-click="rowform' + scope.definition.id + '.$show()"><span class="fa fa-pencil"></span></button>';
            template += '                       <button type="button" class="btn btn-sm btn-danger" ng-click="definition.removeFunction($index)"><span class="fa fa-trash"></span></button>';
            template += '                   </div>';
            template += '               </td>';
            template += '           </tr>';
            template += '       </tbody>';
            template += '   </table>';
            template +='    <div class="p-a dker b-t" ng-show="definition.showTableError">';
            template +='        <div class="editable-error help-block">{{ definition.tableErrorText }}</div>';
            template +='    </div>';
            template += '   <div class="p-a dker b-t">';
            template += '       <button type="button" class="btn btn-sm grey text-white" ng-click="definition.addFunction()">';
            template += '           <i class="fa fa-plus"></i> <span translate="{{definition.addTitle}}">{{definition.addTitle}}</span>';
            template += '       </button>';
            template += '   </div>';
            template += '</div>';
            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            scope: {
                definition: '='
            },
            link: function (scope, element, attr) {
                angular.forEach(scope.definition.items, function(value1, key){
                    angular.forEach(scope.definition.columns, function(value2, key){
                        if(value2.type == 'select-multiple') {
                            var obj = HelperService.selectSelected(value2.elements, value1[value2.key], undefined, false, true);
                            if (obj && obj.length > 0) {
                                value1[value2.key] = obj;
                            }
                        }else if(value2.type == 'select'){
                            var obj = HelperService.selectSelected(value2.elements, value1[value2.key], undefined, false, false);
                            if (obj && obj.length > 0) {
                                value1[value2.key] = obj[0];
                            }
                        }
                    });
                });

                element.html(getTemplate(scope)).show();

                $compile(element.contents())(scope);
            }
        }
    }])
	.directive('fixFocusOnTouch',['$timeout',function($timeout){
	  return {
	    require: 'uiSelect',
		restrict: 'A',
	    link: function($scope, el, attrs, uiSelect) {
	      var closing = false;
	      angular.element(uiSelect.focusser).on('focus', function() {
	        if(!closing && uiSelect.searchInput && uiSelect.searchInput[0]) {
	          uiSelect.activate();
			  $timeout(function () {
                 uiSelect.searchInput[0].focus();
			  }, 250);
	        }
	      });
	      $scope.$on('uis:close', function () {
                closing = true;
                $timeout(function () {
                    closing = false;
                }, 250);
            });
	    }
	  }
	}]);