/**
 * Created by osirvent on 03/08/2016.
 */
var CustomFieldsDefinition = function(title, loggedUser, languageColumn, isEditable, customFields, customFieldsProcedure, customFieldRequiredList, isMulti) {
    var self = this;
    this.title = title;
    this.loggedUser = loggedUser;
    this.languageColumn = languageColumn;
    this.isEditable = isEditable;
    if(isMulti) {
        if (customFieldsProcedure && customFieldsProcedure.length > 0) {
            this.customFields = [];
            angular.forEach(customFields, function(value, key){
                var exist = $linq(customFieldsProcedure).where("x => x.customField.id == "+value.id).toArray();
                if(!exist || exist.length == 0){
                    self.customFields.push(value);
                }
            });
        } else {
            this.customFields = customFields;
        }
    }else{
        this.customFields = customFields;
    }
    this.customFieldsProcedure = $linq(customFieldsProcedure).orderBy("x => x.viewOrder").toArray();
    this.customFieldRequiredList = customFieldRequiredList;
    this.listValuesNew = [];
    this.onnCreateCustomField = undefined;

    this.addUpdateCustomField = function (updateFunction) {
        this.updateFunction = function(customField){
            customField.required = customField.requiredString == 'REQUIRED';
            customField.requiredForEndDossier = customField.requiredString == 'REQUIRED_TO_END';
            customField.hiddenField = customField.requiredString == 'HIDDEN_FIELD';
            customField.noEditable = customField.requiredString == 'NO_EDITABLE';
            if(updateFunction){
                updateFunction(customField);
            }
        }
    };

    this.addEditModalCustomField = function (modalConstant, AnnexaFormlyFactory, CommonAdminService, HelperService, ErrorFactory) {
        var selfPrototype = this;
        this.editCustomFieldFunction = function(){
            var customFieldTemplateTag  = '';
            var self = this;
            var data = this.annexaFormly.model.modal_body;
            data.pressButton = true;
            if(this.annexaFormly.form['modal.annexaFormly.form_annexaLanguageFieldSet_listValues_3']) {
                this.annexaFormly.form['modal.annexaFormly.form_annexaLanguageFieldSet_listValues_3'].$validate();
            }
            if(this.annexaFormly.form.$valid) {
                var customField = self.extra.customField;
                customField.language1 = data.language1;
                customField.language2 = data.language2;
                customField.language3 = data.language3;
                customField.frontendType = data.frontendType;
                customField.backendType = data.backendType;
                customFieldTemplateTag  = customField.templateTag;
                customField.templateTag = data.templateTag;
                if(data.listValues){
                    if(!customField.listValues) {
                        customField.listValues = [];
                    }
                    angular.forEach(data.listValues, function(value, key){
                        if(value.id){
                            var aux = HelperService.findFirstElementWhere(customField.listValues, 'id', value.id, -1);
                            if(aux != -1){
                                aux.language1 =  value.language1;
                                aux.language2 = value.language2;
                                aux.language3 = value.language3;
                                aux.deleted = value.deleted;
                                aux.removedDate = value.removedDate;
                            }
                        }else{
                            customField.listValues.push(value);
                        }
                    });
                }else{
                    if(customField.listValues){
                        angular.forEach(customField.listValues, function(value, key){
                            value.deleted = true;
                            value.removedDate = new Date();
                        });
                    }
                }
                var editable = customField.disableElement;
                CommonAdminService.updateCustomField(customField)
                    .then(function (data) {
                        var cfType = JSOG.decode(data);
                        cfType.disableElement = editable;
                        if(selfPrototype.customFieldsProcedure && self.extra && selfPrototype.customFieldsProcedure.length > self.extra.index){
                            selfPrototype.customFieldsProcedure[self.extra.index].customField=cfType;
                        }
                        self.close();
                }).catch(function (error) {
                    self.alerts.push({ msg: ErrorFactory.getErrorMessage('customFields', 'update', error) });
                    customField.templateTag = customFieldTemplateTag;
                });
            }
            data.pressButton = undefined;
            if(this.annexaFormly.form['modal.annexaFormly.form_annexaLanguageFieldSet_listValues_3']) {
                this.annexaFormly.form['modal.annexaFormly.form_annexaLanguageFieldSet_listValues_3'].$validate();
            }
        }
        this.editModalFunction = function(index){
            if(this.customFieldsProcedure && this.customFieldsProcedure.length > index){
                var customField = {};
                var view = false;
                if(this.customFieldsProcedure[index].customField){
                    var aux = angular.copy(this.customFieldsProcedure[index].customField);
                    var auxList =[];
                    angular.forEach(aux.listValues, function(value, key){
                        var deleted = false;
                        if(value.deleted){
                            deleted = value.deleted;
                        }
                        auxList.push({language1:value.language1, language2:value.language2, language3:value.language3, deleted:deleted, removedDate:value.removedDate, id: value.id, value: value.value});
                    });
                    if(aux.disableElement){
                        view = true;
                    }
                    customField = {language1:aux.language1, language2:aux.language2, language3:aux.language3, frontendType:aux.frontendType, backendType:aux.backendType, listValues:auxList, id: aux.id, templateTag:aux.templateTag, view:view};
                }

                var modal = modalConstant;
                modal.alerts = [];
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = customField;
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = { readOnly: false};
                modal.extra = {index:index, customField:this.customFieldsProcedure[index].customField};
                modal.annexaFormly.options.formState.readOnly = view;
                var hideButton = false;
                if(aux.frontendType != 'SELECT' && aux.frontendType != 'MULTIPLESELECT' && aux.frontendType != 'CHECKBOX' && aux.frontendType != 'RADIO'){
                    if(aux.disableElement){
                        hideButton = true;
                    }
                }
                AnnexaFormlyFactory.showModal("modalCustomFields", modal, selfPrototype.editCustomFieldFunction, false, hideButton);
            }
        }
    };

    this.addRemoveCustomField = function (removeFunction) {
        this.removeFunction = function(index){
            if(this.customFieldsProcedure && this.customFieldsProcedure.length > index) {
                var customField = this.customFieldsProcedure[index];
                if (removeFunction) {
                    removeFunction(customField, this.customFieldsProcedure);
                }
                this.customFieldsProcedure.splice(index, 1);
                this.customFields.push(customField.customField);
            }
        }
    };

    this.setOnCreateCustomField = function (onFuction) {
        this.onnCreateCustomField = onFuction;
    }

    this.addCreateModalCustomField = function (modalConstant, AnnexaFormlyFactory, CommonAdminService, createFunction, propertyObject, valueObject, ErrorFactory) {
        var selfPrototype = this;


        this.createCustomFieldFunction = function(){
            var self = this;
            var data = this.annexaFormly.model.modal_body;
            data.pressButton = true;
            if(this.annexaFormly.form['modal.annexaFormly.form_annexaLanguageFieldSet_listValues_3']) {
                this.annexaFormly.form['modal.annexaFormly.form_annexaLanguageFieldSet_listValues_3'].$validate();
            }
            if(this.annexaFormly.form.$valid) {
                var customField =
                {
                    language1: data.language1,
                    language2: data.language2,
                    language3: data.language3,
                    backendType: data.backendType,
                    frontendType: data.frontendType,
                    listValues: data.listValues,
                    templateTag: data.templateTag
                }
                CommonAdminService.insertCustomField(customField)
                    .then(function (data) {
                        var cfType = JSOG.decode(data);
                        if (!selfPrototype.customFieldsProcedure) {
                            selfPrototype.customFieldsProcedure = [];
                        }
                        var transactionCustomField = {
                            customField: cfType,
                            required: false,
                            requiredForEndDossier: false,
                            hiddenField: false,
                            noEditable:false,
                            requiredString: "OPTIONAL",
                            value: ''
                        }
                        if(selfPrototype.customFieldsProcedure && selfPrototype.customFieldsProcedure.length > 0 && selfPrototype.customFieldsProcedure[selfPrototype.customFieldsProcedure.length-1]){
                            transactionCustomField.viewOrder =  selfPrototype.customFieldsProcedure[selfPrototype.customFieldsProcedure.length-1].viewOrder+1;
                        }else{
                            transactionCustomField.viewOrder = selfPrototype.customFieldsProcedure.length;
                        }
                        if(propertyObject){
                            transactionCustomField[propertyObject] = valueObject;
                        }
                        if(createFunction){
                            createFunction(transactionCustomField, selfPrototype.customFieldsProcedure);
                        }else{
                            selfPrototype.customFieldsProcedure.push(transactionCustomField);
                        }
                        if(selfPrototype.onnCreateCustomField) {
                            selfPrototype.onnCreateCustomField(transactionCustomField);
                        }

                        self.close();

                }).catch(function (error) {
                    self.alerts.push({ msg: ErrorFactory.getErrorMessage('customFields', 'new', error) });
                });
            }
            data.pressButton = undefined;
            if(this.annexaFormly.form['modal.annexaFormly.form_annexaLanguageFieldSet_listValues_3']) {
                this.annexaFormly.form['modal.annexaFormly.form_annexaLanguageFieldSet_listValues_3'].$validate();
            }
        }

        this.createModalFunction = function(customFieldType){
            if(customFieldType.id == -1){
                var modal = modalConstant;
                modal.alerts = [];
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {language1:'', language2:'', language3:'', frontendType:'', backendType:'', listValues:[]};
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = { readOnly: false};
                AnnexaFormlyFactory.showModal("modalCustomFields"+new Date().getTime(), modal, selfPrototype.createCustomFieldFunction, false);
            }else{
                if(!this.customFieldsProcedure){
                    this.customFieldsProcedure = [];
                }
                var transactionCustomField = {
                    customField: customFieldType,
                    required: false,
                    requiredForEndDossier: false,
                    hiddenField: false,
                    noEditable: false,
                    viewOrder:this.customFieldsProcedure.length,
                    requiredString: "OPTIONAL",
                    value: ''
                }
                if(this.customFieldsProcedure && this.customFieldsProcedure.length > 0 && this.customFieldsProcedure[this.customFieldsProcedure.length-1]){
                    transactionCustomField.viewOrder = this.customFieldsProcedure[this.customFieldsProcedure.length-1].viewOrder+1;
                }else{
                    transactionCustomField.viewOrder = this.customFieldsProcedure.length;
                }
                if(propertyObject){
                    transactionCustomField[propertyObject] = valueObject;
                }
                if(createFunction){
                    createFunction(transactionCustomField, this.customFieldsProcedure);
                }else{
                    this.customFieldsProcedure.push(transactionCustomField);
                }
                var cont = 0;
                var index = -1;
                angular.forEach(this.customFields, function(value, key){
                    if(value.id == customFieldType.id){
                        index = cont;
                    }
                    cont++;
                });
                if(cont != -1){
                    this.customFields.splice(index, 1);
                }
            }
        };
    };

    this.sort = function(item, partFrom, partTo, indexFrom, indexTo){
        var self = this;
        if(partTo){
            angular.forEach(partTo, function(value, key){
                value.viewOrder = key+1;
                if(self.updateFunction){
                    self.updateFunction(value);
                }
            });
        }
    }
}


