/**
 * Created by osirvent on 22/07/2016.
 */
angular
    .module('annexaApp')
    .factory('CustomFieldFactory',['$q', '$http','Language', 'HelperService', '$filter', '$rootScope', 'globalModals', 'AnnexaFormlyFactory', '$state', function($q, $http, Language, HelperService, $filter, $rootScope, globalModals, AnnexaFormlyFactory, $state) {
        var factory = {};
        factory.procedure = {};
        factory.procedureStartProfilesAux = [];
        factory.procedureTramitationProfilesAux = [];
        factory.procedureViewProfilesAux = [];
        factory.procedureRolesInterestedAux = [];
        factory.procedureResponsibleProfilesAux = [];
        factory.customFieldsProcedure = [];
        factory.customFields = [];
        factory.customOk = [];
        factory.classificationBoxs = [];
        factory.classificationTree = {};
        factory.transactionTypesAux = [];

        factory.getModelValues = function(model) {
            var cf = [];

            angular.forEach(model, function(value, key) {
                if(key.startsWith('cf_')) {
                    if(value && value instanceof Date) {
                        cf.push({id: key, value: new Date(Date.UTC(value.getFullYear(),value.getMonth(),value.getDate(),00,00,00))});
                    }else{
                        cf.push({id: key, value: value});
                    }
                }
            });

            return cf;
        };
        factory.addFormlyFieldRow = function(form, customField, required, id, noEditable) {
            var data = {
                row: true,
                colClass: ' col-sm-12',
                labelClass: 'label-strong'
            };

            var selectedCustomFieldSelect = function ($item, options) {
                $rootScope.$broadcast('customFieldSelectSelected', { customField: customField.id, selectedValue: $item.id });
            };

            switch (customField.frontendType) {
                case 'INPUT':
                    var type = 'text';
                    var step = undefined;

                    switch (customField.backendType) {
                        case 'INTEGER':
                            type = 'number';
                            step = '1';
                            break;
                        case 'DECIMAL':
                            type = 'number';
                            step = '0.' + Array(customField.decimalPositions).join('0') + '1';
                            break;
                    }

                    if(customField.backendType == 'DATETIME') {
                        var datepickerOptions = {
                            format: 'dd/MM/yyyy',
                            initDate: new Date(),
                            showWeeks: false,
                            startingDay: 1
                        }

                        form.addField(
                            'cf_' + id,
                            'annexaDatepickerRow',
                            '',
                            new AnnexaFormlyFieldDatepickerTemplateOptions(
                                'text',
                                customField[Language.getActiveColumn()],
                                required,
                                datepickerOptions
                            ),
                            data,
                            ((!noEditable)?"false":"true"));

                    } else if(customField.backendType == 'ADDRESS') {
                    	
                    	form.addField(
                    		'cf_' + id,
		                    'annexaHidden',
		                    '',
		                    new AnnexaFormlyFieldTemplateOptions(
		                        'hidden',
		                        '',
		                        required
		                    ),
		                    data,
		                    undefined
		                );
                    	form.addField(
                            'cfValue_' + id,
                            'annexaInputRow',
                            '',
                            new AnnexaFormlyFieldTemplateOptions(
                                type,
                                customField[Language.getActiveColumn()],
                                required,
                                false,
                                step,
                                900
                            ),
                            data,
                            ((!noEditable)?"false":"true"));
                    	form.addField(
                                'addressBtn_' + id,
                                'annexaLabelButton',
                                '',
                                new AnnexaFormlyFieldLabelButtonTemplateOptions(
                                    'text',
                                    undefined,
                                    customField[Language.getActiveColumn()],
                                    undefined,undefined,undefined,
                                    'btn-sm',id,
                                    undefined,undefined
                                ),
                                data,
                                ((!noEditable)?"false":"true"));
                    } else {
                        form.addField(
                            'cf_' + id,
                            'annexaInputRow',
                            '',
                            new AnnexaFormlyFieldTemplateOptions(
                                type,
                                customField[Language.getActiveColumn()],
                                required,
                                false,
                                step,
                                900
                            ),
                            data,
                            ((!noEditable)?"false":"true"));
                    }
                    break;
                case 'JSON':
                case 'TEXTAREA':
                    form.addField(
                        'cf_' + id,
                        'annexaTextAreaRow',
                        '',
                        new AnnexaFormlyFieldTextAreaTemplateOptions(
                            customField[Language.getActiveColumn()],
                            5,
                            required,
                            undefined,
                            9999
                        ),
                        data,
                        ((!noEditable)?"false":"true"));
                    break;
                case 'SELECT':
                    form.addField(
                        'cf_' + id,
                        'annexaSelectRow',
                        '',
                        new AnnexaFormlyFieldSelectTemplateOptions(
                            customField[Language.getActiveColumn()],
                            'value',
                            Language.getActiveColumn(),
                            customField.listValues,
                            required,
                            undefined,
                            selectedCustomFieldSelect
                        ),
                        data,
                        ((!noEditable)?"false":"true"),
                        undefined,
                        undefined,
                        customField,
                        $rootScope
                    );
                    break;
                case 'SELECT_LINKED':
                    var controllerFunction = function ($scope) {
                        $scope.$on('customFieldSelectSelected', function (event, args) {
                            if(args.customField && args.selectedValue) {
                                var options = $linq(customField.listValues).where("x => x.linkedCustomFieldId == " + args.customField + " && x.parentValue.id == " + args.selectedValue).toArray();

                                $scope.options.resetModel();
                                $scope.to.options = options;
                            } else if (args.customField && !args.selectedValue) {
                                $scope.options.resetModel();
                                $scope.to.options = [];
                            }
                        });
                    }

                    form.addField(
                        'cf_' + id,
                        'annexaSelectRow',
                        '',
                        new AnnexaFormlyFieldSelectTemplateOptions(
                            customField[Language.getActiveColumn()],
                            'value',
                            Language.getActiveColumn(),
                            [], //customField.listValues,
                            required
                        ),
                        data,
                        ((!noEditable)?"false":"true"),
                        undefined,
                        controllerFunction
                    );
                    break;
                case 'MULTIPLESELECT':
                    form.addField(
                        'cf_' + id,
                        'annexaMultipleSelectRow',
                        '',
                        new AnnexaFormlyFieldSelectTemplateOptions(
                            customField[Language.getActiveColumn()],
                            'value',
                            Language.getActiveColumn(),
                            customField.listValues,
                            required
                        ),
                        data,
                        ((!noEditable)?"false":"true")
                    );
                    break;
                case 'CHECKBOX':
                    form.addField(
                        'cf_' + id,
                        'annexaRadioCheckboxRow',
                        '',
                        new AnnexaFormlyFieldSelectTemplateOptions(
                            customField[Language.getActiveColumn()],
                            'value',
                            Language.getActiveColumn(),
                            customField.listValues,
                            required,
                            'checkbox'
                        ),
                        data,
                        ((!noEditable)?"false":"true")
                    );
                    break;
                case 'RADIO':
                    form.addField(
                        'cf_' + id,
                        'annexaRadioCheckboxRow',
                        '',
                        new AnnexaFormlyFieldSelectTemplateOptions(
                            customField[Language.getActiveColumn()],
                            'value',
                            Language.getActiveColumn(),
                            customField.listValues,
                            required,
                            'radio'
                        ),
                        data,
                        ((!noEditable)?"false":"true")
                    );
                    break;
            }

            return form;
        };
        factory.getCustomFields = function(){
            var deferrend = $q.defer();
            $http({
                method: 'GET',
                url: './api/procedures/getProcedure',
                data: { idProcedure: null }
            }).success(function(data, status) {
                deferrend.resolve(data);
            }).error(function(msg,code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        }

        factory.getProcedure = function (idProcedure) {
            return $http({
                url: './api/procedures/getProcedure',
                method: 'GET',
                params: { idProcedure: idProcedure }
            }).then(function (data) {
                if (data && data.data){
                    var response = JSOG.decode(data.data);
                    factory.procedure = response.procedure;
                    if(!factory.procedure.archiveClassification){
                        factory.procedure.archiveClassification = {};
                    }
                    factory.customFields = response.customFields;
                    angular.forEach(response.customFieldsDisable, function(value, key){
                        var aux = HelperService.findFirstElementWhere(factory.customFields, 'id', value.id, -1);
                        if(aux != -1){
                            aux.disableElement = true;
                        }
                    });
                    factory.customFields = $linq(factory.customFields).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
                    factory.customFields.unshift({id:"-1", language1:$filter('translate')('global.literals.new'), language2:$filter('translate')('global.literals.new'), language3:$filter('translate')('global.literals.new')});
                    if(factory.procedure.procedureStartProfiles){
                        factory.procedureStartProfilesAux.length = 0;
                        angular.forEach(factory.procedure.procedureStartProfiles, function(value, key){
                            factory.procedureStartProfilesAux.push(value.profile.id);
                        });
                    }
                    if(factory.procedure.procedureTramitationProfiles){
                        factory.procedureTramitationProfilesAux.length = 0;
                        angular.forEach(factory.procedure.procedureTramitationProfiles, function(value, key){
                            factory.procedureTramitationProfilesAux.push(value.profile.id);
                        });
                    }
                    if(factory.procedure.procedureViewProfiles){
                        factory.procedureViewProfilesAux.length = 0;
                        angular.forEach(factory.procedure.procedureViewProfiles, function(value, key){
                            factory.procedureViewProfilesAux.push(value.profile.id)
                        });
                    }
                    if(factory.procedure.procedureRoleInterested){
                        factory.procedureRolesInterestedAux.length = 0;
                        angular.forEach(factory.procedure.procedureRoleInterested, function(value, key){
                            factory.procedureRolesInterestedAux.push(value.roleInterested.id)
                        });
                    }
                    if(factory.procedure.procedureResponsibleProfiles){
                        factory.procedureResponsibleProfilesAux.length = 0;
                        angular.forEach(factory.procedure.procedureResponsibleProfiles, function(value, key){
                            factory.procedureResponsibleProfilesAux.push(value.profile.id);
                        });
                    }
                    if(factory.procedure.transactionTypes){
                        factory.transactionTypesAux.length = 0;
                        angular.forEach(factory.procedure.transactionTypes, function(value, key){
                            factory.transactionTypesAux.push(value.transactionType.id)
                        });
                        if(factory.transactionTypesAux && factory.transactionTypesAux.length > 0){
                        	factory.transactionTypesAux = $linq(factory.transactionTypesAux).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
                        }
                    }
                    if(factory.procedure.customFields){
						var customsOk = [];
                        factory.customFieldsProcedure.length = 0;
	 					var customFieldsAux = $linq(factory.procedure.customFields).orderBy("x => x.viewOrder").toArray();
                       	angular.forEach(customFieldsAux, function (value, key) {
							var customField = {
                                    customField: value.customField,
                                    id: value.id,
                                    delete: value.deleted,
                                    viewOrder:value.viewOrder,
                                    required: value.required,
                                    requiredForEndDossier: value.requiredForEndDossier,
                                    hiddenField: value.hiddenField,
                                    noEditable: value.noEditable,
                                    procedure: {id:factory.procedure.id},
                                    createdDate: value.createdDate,
                                    value: value.value,
									customFieldType: value.customFieldType,
									transactionTypes: value.transactionTypes
                                };
                                customField.requiredString = 'OPTIONAL';
                                if(value.required){
                                    customField.requiredString = 'REQUIRED';
                                }else if(value.requiredForEndDossier){
                                    customField.requiredString = 'REQUIRED_TO_END';
                                }else if(value.hiddenField){
                                    customField.requiredString = 'HIDDEN_FIELD';
                                }else if(value.noEditable) {
                                    customField.requiredString = 'NO_EDITABLE';
                                }
                                var aux = HelperService.findFirstElementWhere(response.customFieldsDisable, 'id', value.id, -1);
                                if(aux != -1){
                                    customField.disableElement = true;
                                }
                                factory.customFieldsProcedure.push(customField);
                                customsOk.push(value.customField.id);
                        });
						if(customsOk && customsOk.length > 0) {
                            factory.customFields = $linq(factory.customFields).except(customsOk, "(x, y) => x == y.id ").toArray();
                        }
					}
                    if(response.classifications){
                        factory.classificationBoxs.length = 0;
                        angular.forEach(response.classifications, function(value, key){
                            factory.classificationBoxs.push(value);
                        });
                    }
                }else{
                    $state.transitionTo('annexa.admin.unguided_procedure');
                }
            }).catch(function (error) {
                $state.transitionTo('annexa.admin.unguided_procedure');
            });
        }

        factory.calculateValueCustomField = function(field, model, id){
            if(field && field.customField){
                if(field.customField.backendType  == 'DATETIME' && field.value){
                    var dateParts = field.value.split("/");
                    var dateObject = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]); // month is 0-based
                    return dateObject;
                }else  if (field.customField.frontendType == 'INPUT' && field.customField.backendType == 'INTEGER') {
                    if(field.value){
                        return  parseInt(field.value);
                    }
                } else if (field.customField.frontendType == 'INPUT' && field.customField.backendType == 'DECIMAL') {
                    if(field.value){
                        var valueLiteral_parts = (field.value+'').split('.');
                        var finalValue = 0.00;
                        if(valueLiteral_parts && valueLiteral_parts.length > 0){
                            if(valueLiteral_parts[1]){
                                if(field.customField.decimalPositions){	 
                                    finalValue =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0."+valueLiteral_parts[1]).toFixed(field.customField.decimalPositions));
                                }else{
                                    finalValue =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0."+valueLiteral_parts[1]).toFixed(2));
                                }
                            }else{
                                if(field.customField.decimalPositions){
                                    finalValue =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0.00").toFixed(field.customField.decimalPositions));
                                }else {
                                    finalValue =  parseFloat(parseFloat(valueLiteral_parts[0]).toLocaleString() + ".00");
                                }
                            }
                        }
                        return finalValue;
                    }
                } else if(field.customField.frontendType == 'RADIO' || field.customField.frontendType == 'SELECT'){
                    if(field.value && field.customField.listValues && $linq(field.customField.listValues).indexOf("x => x.id == "+field.value) > -1){
                        return $linq(field.customField.listValues).first("x => x.id == "+field.value).value;
                    }
                }else if(field.customField.frontendType == 'CHECKBOX'){
                    if(field.customField.listValues){
                        var values = [];
                        if(field.value && Array.isArray(field.value)){
							values = field.value;
							_.forEach(field.customField.listValues, function(option){
	                            if( model != undefined ) {
	                                if (_.contains(values, option['value'])) {
	                                    model[id + '_' + option.value] = true;
	                                } else {
	                                    model[id + '_' + option.value] = false;
	                                }
	                            }
	                        });
						}else if(field.value){
                            values = field.value.split(',');
							_.forEach(field.customField.listValues, function(option){
	                            if( model != undefined ) {
	                                if (_.contains(values, "" + option['id'])) {
	                                    model[id + '_' + option.value] = true;
	                                } else {
	                                    model[id + '_' + option.value] = false;
	                                }
	                            }
	                        });
                        }
                    }
                    return undefined;
                }else if(field.customField.frontendType == 'MULTIPLESELECT'){
                    var finalValue = [];
                    if(field.customField.listValues){
                        var values = [];
                        if(field.value && Array.isArray(field.value)){
							values = field.value;
							_.forEach(values, function(option){
	                            if($linq(field.customField.listValues).indexOf("x => x.value == "+option) > -1) {
	                                finalValue.push($linq(field.customField.listValues).first("x => x.value == " + option).value);
	                            }
	                        });
						}else if(field.value){
                            values = field.value.split(',');
							_.forEach(values, function(option){
	                            if($linq(field.customField.listValues).indexOf("x => x.id == "+option) > -1) {
	                                finalValue.push($linq(field.customField.listValues).first("x => x.id == " + option).value);
	                            }
	                        });
                        }
                    }
                    return finalValue;
                }
            }
            return field.value;
        }

		factory.calculateValueCustomFieldDossier = function(field){
            if(field && field.customField){
                if(field.customField.backendType  == 'DATETIME' && field.value){
                    var dateParts = field.value.split("/");
                    var dateObject = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]); // month is 0-based
                    return dateObject;
                }else  if (field.customField.frontendType == 'INPUT' && field.customField.backendType == 'INTEGER') {
                    if(field.value){
                        return  parseInt(field.value);
                    }
                } else if (field.customField.frontendType == 'INPUT' && field.customField.backendType == 'DECIMAL') {
                    if(field.value){
                        var valueLiteral_parts = (field.value+'').split('.');
                        var finalValue = 0.00;
                        if(valueLiteral_parts && valueLiteral_parts.length > 0){
                            if(valueLiteral_parts[1]){
                                if(field.customField.decimalPositions){	 
                                    finalValue =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0."+valueLiteral_parts[1]).toFixed(field.customField.decimalPositions));
                                }else{
                                    finalValue =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0."+valueLiteral_parts[1]).toFixed(2));
                                }
                            }else{
                                if(field.customField.decimalPositions){
                                    finalValue =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0.00").toFixed(field.customField.decimalPositions));
                                }else {
                                    finalValue =  parseFloat(parseFloat(valueLiteral_parts[0]).toLocaleString() + ".00");
                                }
                            }
                        }
                        return finalValue;
                    }
                } else if(field.customField.frontendType == 'RADIO' || field.customField.frontendType == 'SELECT'){
                    if(field.value && field.customField.listValues && $linq(field.customField.listValues).indexOf("x => x.id == "+field.value) > -1){
                        return $linq(field.customField.listValues).first("x => x.id == "+field.value).value;
                    }
                }else if(field.customField.frontendType == 'CHECKBOX' || field.customField.frontendType == 'MULTIPLESELECT'){
                    var finalValue = [];
                    if(field.customField.listValues){
                        var values = [];
                        if(field.value && Array.isArray(field.value)){
							values = field.value;
							_.forEach(values, function(option){
	                            if($linq(field.customField.listValues).indexOf("x => x.value == "+option) > -1) {
	                                finalValue.push($linq(field.customField.listValues).first("x => x.value == " + option).value);
	                            }
	                        });
						}else if(field.value){
                            values = field.value.split(',');
							_.forEach(values, function(option){
	                            if($linq(field.customField.listValues).indexOf("x => x.id == "+option) > -1) {
	                                finalValue.push($linq(field.customField.listValues).first("x => x.id == " + option).value);
	                            }
	                        });
                        }
                    }
                    return finalValue;
                }else if(field.customField.backendType  == 'ADDRESS' && field.value){
                	 return angular.fromJson(field.value);
				}
            }
            return field.value;
        }
        
        return factory;
    }]);