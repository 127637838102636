/**
 * Created by osirvent on 10/11/2015.
 */
'use strict';

angular.module('annexaApp')
    .factory('authExpiredInterceptor',['$rootScope', '$q', '$injector', function ($rootScope, $q, $injector) {
        return {
            responseError: function(response) {
                if ((response.status == 401 || response.status == 403) && response.data.path !== undefined && response.data.path.indexOf("/api/account") == -1){
                    var Auth = $injector.get('Auth');
                    var to = $rootScope.toState;
                    var params = $rootScope.toStateParams;
                    Auth.logout();
                    $rootScope.previousStateName = to;
                    $rootScope.previousStateNameParams = params;
                }
                return $q.reject(response);
            }
        };
    }]);
