/**
 * Created by osirvent on 15/03/2016.
 */
var AnnexaModal = function(title, size, data, content) {
    this.title = title;
    this.size = size || '';
    this.data = data;
    this.content = content;
    this.modal = undefined;
    this.close = undefined;
}

var modalData = function(objects, classes,title,tfilterId,tfilter,tdata,defdata,cancelFunction1,submitFunction1,cancelFunction2,submitFunction2,searchFunction,addModal,jsonDepth) {
    this.classes = classes;
    this.title = title;
    this.searchFunction = searchFunction;
    this.tfilterId = tfilterId;
    this.tfilter = tfilter;
    this.tdata = tdata;
    this.defdata = defdata;
    this.cancelFunction1 = cancelFunction1;
    this.submitFunction1 = submitFunction1;
    this.cancelFunction2 = cancelFunction2;
    this.submitFunction2 = submitFunction2;
    this.addModal = addModal;
    this.jsonDepth = jsonDepth == undefined ? 3 : jsonDepth;
    this.objects = objects == undefined? []: objects;
}