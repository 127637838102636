/**
 * Created by osirvent on 03/08/2016.
 */
var PresetSignActionsDefinition = function(title, loggedUser, languageColumn, isEditable, presetSignActions, signatoryTypes, signactionTypes, saveActionFunction,  removeActionFunction) {
    this.title = title;
    this.loggedUser = loggedUser;
    this.languageColumn = languageColumn;
    this.isEditable = isEditable;
    this.presetSignActions = presetSignActions;
    this.signatoryTypes = signatoryTypes;
    this.signactionTypes = signactionTypes;
    this.saveActionFunction = saveActionFunction;
    this.removeActionFunction = removeActionFunction;
}


