/**
 * Created by osirvent on 15/03/2016.
 */

angular.module('annexaApp')
    .directive('searchboxdata',['$compile', '$rootScope', 'RestService', function($compile, $rootScope, RestService) {
        var getTemplate = function(id, def, definitions, collapsed, scope) {
            var template = '<div class="' +
                'adjuntarNewDocument m-b-md"> <div class="p-a bg-grey"><div class="row"> <div class="col-sm-5">';
            template += ' <h5 class="text-uppercase m-t-sm m-b-0">';
            if(collapsed && collapsed == "true"){
                template += ' <a tabindex="0" href class="nav-link auto collapsed" data-toggle="collapse" data-target="#box'+id+'"><span class="pull-right text-muted m-r-xs"><i class="fa fa-minus inline"></i><i class="fa fa-plus none"></i></span></a>';
            }
            template += ' <span translate="'+definitions.titleText+'"></span></h5>';
            template += ' </div> <div class="col-sm-7">';
            template += ' <searchdata id="'+definitions.idSearch+'" search-data-definition="'+def+'.searchData"/>';
            template += ' </div></div></div>';
            if(collapsed && collapsed == "true"){
                template += ' <div class="p-a collapse" id="box'+id+'"><div class="row m-t-md">';
            }else{
                template += ' <div class="p-a" id="box'+id+'"><div class="row m-t-md">';
            }
            template += ' <div class="col-sm-12"> <div class="box"> <div class="table-responsive annexa-table-responsive">';
            template += ' <tabledata id="'+definitions.idTable+'" tabledata-definition="'+definitions.defTable+'" class="table table-striped withoutHeader b-t b-b"/>';
            template += ' </div></div></div></div></div>';
            template += ' </div>';
            return template;
        };

        var getObjectsBySearch = function(scope, id, dataDef){
            var definitions = scope[dataDef];
            var idObject = scope[id];
            RestService.loadData(definitions.origin,definitions.objectType, idObject.search).then(function(dataSearch) {
                if(dataSearch.data){
                   idObject.loadObjects = JSOG.decode(dataSearch.data).content;
                }
            }).catch(function(error) {
            });
        };

        var getObjectBySearch = function(scope, id, dataDef){
            var idObject = scope[id];
            var definitions = scope[dataDef];
            var trobat = false;
            if(idObject && idObject.loadObjects && definitions) {
                for (var i = 0; i < idObject.loadObjects.length; i++) {
                    var object = idObject.loadObjects[i];
                    if (idObject.search == object[definitions.nameShow]) {
                        idObject.objAdd = object;
                        trobat = true;
                        break;
                    }
                }
                if (!trobat) {
                    idObject.objAdd = {};
                }
            }
        };

        return {
            restrict: 'E',
            replace: true,
            link: function(scope, element, attr) {
                if(attr['id'] && attr['searchDataDefinition']) {
                    /*scope.validObject = function(id, def) {
                        getObjectBySearch(scope, id, def);
                    };

                    scope.loadObject = function(id, def) {
                        getObjectsBySearch(scope, id, def);
                    };

                    scope.addObject = function(id, def) {
                        getObjectBySearch(scope, id, def);
                    };

                    scope.newObject = function(id, def) {
                    };

                    scope.searchObject = function(id, def) {
                    };*/
                    element.html(getTemplate(attr['id'], attr['searchDataDefinition'], scope[attr['searchDataDefinition']], attr['collapsed'])).show();
                }
                $compile(element.contents())(scope);
            }
        }
    }]);