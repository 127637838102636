/**
 * Created by osirvent on 15/03/2016.
 */
var SearchData = function(addFunction, idTable, origin, objectType, buttonSearch, buttonNew, nameShow, searchText, modalSearch, modalAdd, modalNew, jsonDepth, disabled) {
    this.addFunction = addFunction;
    this.origin = origin;
    this.idTable = idTable;
    this.objectType = objectType;
    this.buttonSearch = buttonSearch == undefined ? false : buttonSearch;
    this.searchText = searchText;
    this.modalSearch = modalSearch;
    this.modalAdd = modalAdd;
    this.buttonNew = buttonNew == undefined ? false : buttonNew;
    this.modalNew = modalNew;
    this.jsonDepth = jsonDepth == undefined ? 3 : jsonDepth;
    this.nameShow = nameShow;
    this.disabled = disabled == undefined ? false: disabled;
}