/**
 * Created by osirvent on 10/11/2015.
 */
'use strict';

angular.module('annexaApp')
    .factory('errorHandlerInterceptor',['$q', '$rootScope', function ($q, $rootScope) {
        return {
            'responseError': function (response) {
                if (!(response.status == 401 && response.data.path.indexOf("/api/account") == 0 )){
                    $rootScope.$emit('annexaApp.httpError', response);
                }
                return $q.reject(response);
            }
        };
    }]);