/**
 * Created by osirvent on 27/09/2016.
 */

var AnnexaEntity = function (id, type, form, boxes) {
    this.id = id;
    this.type = type;
    this.form = form;
    this.boxes = boxes;

    this.setTitle = function (title) {
        this.title = title;
        return this;
    }

    this.setCancelState = function (state) {
        this.cancelState = state;
        return this;
    }
}