/**
 * Created by osirvent on 30/11/2015.
 */
angular
    .module('annexaApp')
    .filter('languagefilter',['Language', function (Language) {
        return function(input, search) {
            if(!input) return input;
            if(!search) return input;

            return $linq(input).where(function (x) {
                if(!x.hasOwnProperty(Language.getActiveColumn())) return false;

                var res = x[Language.getActiveColumn()].toUpperCase().match(new RegExp(search.toUpperCase(), 'gi'));
                if(res) {
                    return true;
                }

                return false;
            }).toArray();
        }
    }])
    .filter('annexatranslate',['$filter', 'LANGUAGES', function($filter, LANGUAGES) {
        return function(name,languagefilter) {
            if(name  && name.localizedTexts) {
                var elements = [];
                elements = name.localizedTexts;
                if(elements.length > 0) {
                    if (!languagefilter) {
                        languagefilter = "es";
                    } else {
                        var result = jQuery.grep(LANGUAGES, function (e) {
                            return e == languagefilter;
                        });
                        if (result.length == 0) {
                            languagefilter = 'es';
                        }
                    }
                    for (var i = 0; i < elements.length; i++) {
                        var lt = elements[i];
                        if (lt.language.acronym == languagefilter) {
                            return lt.text;
                        }
                    }
                }else{
                    if(name.id){
                        return $filter('translate')(name.id);
                    }else{
                        return $filter('translate')(name);
                    }
                }
            } else if(name) {
                if(name.id){
                    return $filter('translate')(name.id);
                }else{
                    return $filter('translate')(name);
                }
            }
            return '';
        };
    }]);