/**
 * Created by dpardell on 09/11/2016.
 */
angular.module('annexaApp')
    .directive('documentFileOrTemplate',['$compile', '$rootScope', 'HelperService', '$filter', function($compile, $rootScope, HelperService, $filter) {
        var getTemplate = function(enabledField, languages, labelFile, labelTemplate, labelPhysical, scope) {
            var template = '';
            template += '<div class="row" >';
            template += '   <div class="col-md-12">';
            template += '       <ul class="nav nav-tabs annexaTabs d-flex">';
            template += '           <li class=" ng-class: {\'active\' : ngModel[inputType] == keyFile}" ng-show="ngModel.showFile">';
            template += '               <div class="radio">';
            template += '                   <span class="ui-check">';
            template += '                       <input tabindex="0" type="radio" name="radioType" id="fileOption1" value="{{keyFile}}" ng-model="ngModel[inputType]" ng-checked="ngModel[inputType] == keyFile" ng-disabled="disabled" ng-click="clickType(\'file\')"/>';
            template += '                       <i class="dark-white"></i>';
            template += '                   </span>';
            template += '                   <label for="fileOption1" translate="'+labelFile+'">'+labelFile+'</label>';
            template += '               </div>';
            template += '           </li>';
            template += '           <li class=" ng-class: {\'active\' : ngModel[inputType] == keyTemplate}"  ng-show="ngModel.showTemplate">';
            template += '               <div class="radio">';
            template += '                   <span class="ui-check">';
            template += '                       <input tabindex="0"  type="radio" name="radioType" id="fileOption2" value="{{keyTemplate}}" ng-model="ngModel[inputType]" ng-checked="ngModel[inputType] == keyTemplate" ng-disabled="disabled" ng-click="clickType(\'template\')"/>';
            template += '                       <i class="dark-white"></i>';
            template += '                   </span>';
            template += '                   <label for="fileOption2" translate="'+labelTemplate+'">'+labelTemplate+'</label>';
            template += '               </div>';
            template += '           </li>';
            template += '           <li class=" ng-class: {\'active\' : ngModel[inputType] == keyPhysical}"  ng-show="ngModel.showPhysical">';
            template += '               <div class="radio">';
            template += '                   <span class="ui-check">';
            template += '                       <input tabindex="0"  type="radio" name="radioType" id="fileOption3" value="{{keyPhysical}}" ng-model="ngModel[inputType]" ng-checked="ngModel[inputType] == keyPhysical" ng-disabled="disabled" ng-click="clickType(\'physical\')"/>';
            template += '                       <i class="dark-white"></i>';
            template += '                   </span>';
            template += '                   <label for="fileOption3" translate="'+labelPhysical+'">'+labelPhysical+'</label>';
            template += '               </div>';
            template += '           </li>';
            template += '       </ul>';
            template += '   </div>';
            template += '</div>';
            template += '<div class="p-x-9375" >';
            template += '   <div class="row tab-panel" id="listCircuitsPredef" ng-if="ngModel[inputType] == keyFile && ngModel.showFile">';
            template += '       <label for="newDoc-{{keyFile}}" class="sr-only">{{ \'global.literals.SelectUploadFile\' | translate }}</label>';
            template += '       <input type="file" ng-model="ngModel[keyFile]" class="form-control" id="newDoc-{{keyFile}}" name="{{keyFile}}" accept="{{options.data.accept}}" onchange="angular.element(this).scope().onSelected(this)" ng-disabled="disabled">';
            template += '   </div>';
            template += '   <div class="row tab-panel" id="listCircuitsPerso" ng-if="ngModel[inputType] == keyTemplate && ngModel.showTemplate">';
            template += '       <ui-tree-select ng-model="ngModel[keyTemplate]" data="data" required="required" only-last-level-click="onlyLastLevelClick" change="change" disabled="disabled" extra-change="fieldOptions"></ui-tree-select>';
            template += '   </div>';
            template += '   <div class="row tab-panel" id="listCircuitsPhysical" ng-if="ngModel[inputType] == keyPhysical && ngModel.showPhysical">';
			template += '   	<div class="col-sm-12">';
			template += '   		<div class="row">';
			template += '	       		<div class="col-sm-12">';
			template += '           		<label for="physicalSupport" class="label-strong small m-b-0 block" translate="global.documents.EMGDE.supportFile">global.documents.EMGDE.supportFile</label>';
			template += '           		<input type="text" required class="form-control" ng-model="ngModel.physicalSupport" id="physicalSupport" aria-label="{{\'global.documents.EMGDE.supportFile\' | translate}}" maxlength="1024">';
			template += '   	    	</div>';
			template += '	   		</div>';
			template += '   		<div class="row">';
			template += '	       		<div class="col-sm-12">';
			template += '           		<label for="physicalLocation" class="label-strong small m-b-0 block" translate="global.documents.EMGDE.locationFile">global.documents.EMGDE.locationFile</label>';
			template += '           		<input type="text" required class="form-control" ng-model="ngModel.physicalLocation" id="physicalLocation" aria-label="{{\'global.documents.EMGDE.locationFile\' | translate}}" maxlength="1024">';
			template += '   	    	</div>';
			template += '	   		</div>';
			template += '   		<div class="row">';
			template += '	       		<div class="col-sm-6">';
			template += '           		<label for="physicalLength" class="label-strong small m-b-0 block" translate="global.literals.length">global.literals.length</label>';
			template += '           		<input type="number" class="form-control" required ng-model="ngModel.physicalLength" id="physicalLength" aria-label="{{\'global.literals.length\' | translate}}" min="0" step="1">';
			template += '   	    	</div>';
			template += '	       		<div class="col-sm-6">';
			template += '           		<label for="physicalWidth" class="label-strong small m-b-0 block" translate="global.literals.width">global.literals.width</label>';
			template += '           		<input type="number" class="form-control" required ng-model="ngModel.physicalWidth" id="physicalWidth" aria-label="{{\'global.literals.width\' | translate}}" min="0" step="1">';
			template += '   	    	</div>';
			template += '	   		</div>';
			template += '   		<div class="row">';
			template += '	       		<div class="col-sm-6">';
			template += '           		<label for="physicalDepth" class="label-strong small m-b-0 block" translate="global.literals.depth">global.literals.depth</label>';
			template += '           		<input type="number" class="form-control" required ng-model="ngModel.physicalDepth" id="physicalDepth" aria-label="{{\'global.literals.depth\' | translate}}" min="0" step="1">';
			template += '   	    	</div>';
			template += '	       		<div class="col-sm-6">';
			template += '           		<label for="physicalUnits" class="label-strong small m-b-0 block" translate="global.literals.units">global.literals.units</label>';
			template += '					<ui-select class="form-control" required data-ng-model="ngModel.physicalUnits" id="physicalUnits">';
			template += '						<ui-select-match>';
	        template += '							<span class="op-selected">';
	        template += '								<span>{{$select.selected.description}}</span>';
	        template += '							</span>';
	        template += '						</ui-select-match>';
	        template += '						<ui-select-choices data-repeat="option.id as option in phisicUnits | filter: $select.search">';
	        template += '							<div>{{option.description | highlight: $select.search}}</div>';
	        template += '						</ui-select-choices>';
	        template += '					</ui-select>';
			template += '   	    	</div>';
			template += '	   		</div>';
			template += '   		<div class="row">';
			template += '	       		<div class="col-sm-6">';
			template += '           		<label for="physicalWeight" class="label-strong small m-b-0 block" translate="global.literals.weight">global.literals.weight</label>';
			template += '           		<input type="number" class="form-control-no-error" ng-model="ngModel.physicalWeight" id="physicalWeight" aria-label="{{\'global.literals.weight\' | translate}}" min="0" step="1">';
			template += '   	    	</div>';
			template += '	       		<div class="col-sm-6">';
			template += '           		<label for="weightUnits" class="label-strong small m-b-0 block" translate="global.literals.units">global.literals.units</label>';
			template += '					<ui-select class="form-control-no-error" data-ng-model="ngModel.weightUnits" id="weightUnits">';
			template += '						<ui-select-match>';
	        template += '							<span class="op-selected">';
	        template += '								<span>{{$select.selected.description}}</span>';
	        template += '							</span>';
	        template += '						</ui-select-match>';
	        template += '						<ui-select-choices data-repeat="option.id as option in weightUnits | filter: $select.search">';
	        template += '							<div>{{option.description | highlight: $select.search}}</div>';
	        template += '						</ui-select-choices>';
	        template += '					</ui-select>';
			template += '   	    	</div>';
			template += '	   		</div>';
            template += '   		<div class="row">';
			template += '	       		<div class="col-sm-6">';
			template += '           		<label for="physicalVolume" class="label-strong small m-b-0 block" translate="global.literals.volume">global.literals.volume</label>';
			template += '           		<input type="number" class="form-control-no-error" ng-model="ngModel.physicalVolume" id="physicalVolume" aria-label="{{\'global.literals.volume\' | translate}}" min="0" step="1">';
			template += '   	    	</div>';
			template += '	       		<div class="col-sm-6">';
			template += '           		<label for="volumeUnits" class="label-strong small m-b-0 block" translate="global.literals.units">global.literals.units</label>';
			template += '					<ui-select class="form-control-no-error" data-ng-model="ngModel.volumeUnits" id="volumeUnits">';
			template += '						<ui-select-match>';
	        template += '							<span class="op-selected">';
	        template += '								<span>{{$select.selected.description}}</span>';
	        template += '							</span>';
	        template += '						</ui-select-match>';
	        template += '						<ui-select-choices data-repeat="option.id as option in volumeUnits | filter: $select.search">';
	        template += '							<div>{{option.description | highlight: $select.search}}</div>';
	        template += '						</ui-select-choices>';
	        template += '					</ui-select>';
			template += '   	    	</div>';
			template += '	   		</div>';
            template += '   	</div>';
            template += '   </div>';
            template += '</div>';

            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            scope: {
                ngModel: '=',
                data: '=',
                change: '=',
                required: '=',
                onlyLastLevelClick: '=',
                changeFilter: '&',
                keyFile:'=',
                keyTemplate:'=',
				keyPhysical:'=',
                types:'=',
                inputType:'=',
                onSelected:'=',
				onSelectedPhysical:'=',
                fieldOptions: '='
            },
            link: function(scope, element, attr) {
                element.html(getTemplate(attr['enabledField'], $rootScope.app.languagedef, attr['labelFile'], attr['labelTemplate'], attr['labelPhysical'], scope)).show();
                scope.disabled = ((scope.fieldOptions && scope.fieldOptions.templateOptions && scope.fieldOptions.templateOptions.disabled)?true:false);
				scope.ngModel.weightUnits = "KG";
				scope.ngModel.volumeUnits = "CM3";
				scope.clickType = function(type){
					if(type == 'physical'){
						if(scope.onSelectedPhysical){
							scope.onSelectedPhysical();
						}
					}else if(type == 'file'){
						if(scope.onSelected){
							scope.onSelected();
						}
					}else if(type == 'template'){
						if(scope.change){
							scope.change();
						}	
					}
				}
				
				scope.phisicUnits = [
	                { id: "MM", description: $filter('translate')('global.documents.EMGDE.phisicUnits.milimeters')},
	                { id: "CM", description: $filter('translate')('global.documents.EMGDE.phisicUnits.centimeters')},
	                { id: "M", description: $filter('translate')('global.documents.EMGDE.phisicUnits.meters')}
				];
				
				scope.weightUnits = [
	                { id: "G", description: $filter('translate')('global.documents.EMGDE.weightUnits.gram')},
	                { id: "KG", description: $filter('translate')('global.documents.EMGDE.weightUnits.kilogram')}
				];
				
				scope.volumeUnits = [
	                { id: "CM3", description: $filter('translate')('global.documents.EMGDE.volumeUnits.cubicMilimeters')},
	                { id: "M3", description: $filter('translate')('global.documents.EMGDE.volumeUnits.cubicMeters')}
				];

                $compile(element.contents())(scope);
            }
        }
}]);