/**
 * Created by osirvent on 31/10/2016.
 */
angular.module('annexaApp')
    .directive('annexaCheckboxList',['$compile', function ($compile) {
        var getTemplate = function (scope) {
            var template = '';

            template += '<label class="form-check-inline" ng-repeat="item in items">';
            template += '   <input class="form-check-input" type="checkbox" ng-model="item.checked">';
            template += '    {{item[labelCol] | translate}} ';
            template += '</label>';

            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            scope: {
                items: '=',
                labelCol: '@'
            },
            link: function (scope, element, attr) {
                var el = getTemplate(scope);

                 var compiled = $compile(el)(scope);
                 element.html(compiled).show();
            }
        }
    }])
    .directive('annexaCounter',['$compile', function ($compile) {
        var getTemplate = function () {
            var template = '';

            template += '<div class="col-xs-2 col-md-3 col-lg-2 text-right" ng-if="number > 0">';
            template += '   <span class="label rounded label warn text-black">{{number}}</span>'
            template += '</div>';

            return template;
        }

        return {
            restrict: 'E',
            replace: true,
            scope:  {
                number: '='
            },
            link: function (scope, element, attr) {
                var el = getTemplate();

                var compiled = $compile(el)(scope);
                element.html(compiled).show();
            }
        }
    }])
    .directive('annexaUiSelectRepeat', ['$compile', function ($compile) {
        return {
            priority: 1001,
            scope: {
                options: '@'
            },
            compile: function($elem, $attrs) {
                $elem.removeAttr('annexa-ui-select-repeat');
                return function (scope, elem, attrs) {
                    $elem.attr('repeat', scope.options)
                    $compile($elem)(scope);
                }
            }
        }
    }])
;