/**
 * Created by osirvent on 27/04/2016.
 */

var AnnexaFormlyTypeaheadTemplateOptions = function(label, search, placeholder, required) {
    this.label = label;
    this.required = required || false;
    this.placeholder = placeholder;
    this.search = search;
}

var AnnexaFormlyFieldTemplateOptions = function (type, label, required, focus, step, maxlength, updateOnBlur) {
    this.type = type;
    this.label = label;
    this.required = required || false;
    this.focus = focus || false;
    this.step = step;
    this.validate = true;
    if(type && type == 'text'){
        this.maxlength = maxlength;
    }
    this.updateOnBlur = ((updateOnBlur)? true : false);
}

var AnnexaFormlyFieldTemplateOptionsValidator = function (type, label, required, focus, step, validators, maxlength) {
    this.type = type;
    this.label = label;
    this.required = required || false;
    this.focus = focus || false;
    this.step = step;
    this.validate = true;
    if(validators) {
        this.validators = validators;
        this.watcher = {
            type: '$watchCollection',
            expression: 'model',
            listener: function (field, _new) {
                if (field.formControl) {
                	if (field.formControl.$validate) {
                        field.formControl.$validate();
                    } else {
                    	if(field.formControl.length > 0){
                    		field.formControl[field.formControl.length - 1].$validate();
                    	}
                    }
                }
            }
        };
    }
    if(type && type == 'text'){
        this.maxlength = maxlength;
    }
}

var AnnexaFormlyLoadUserFieldTemplateOptions = function(type, label, required, focus, options, loadFunction, saveFunction, inputType, buttonLabel, atributes, step, placeholder, showTitle, showOr){
    this.type = type;
    this.label = label;
    this.required = required || false;
    this.focus = focus || false;
    this.step = step;
    this.validate = true;
    this.loadFunction = loadFunction;
    this.options = options;
    this.saveFunction = saveFunction;
    this.inputType = inputType;
    this.buttonLabel = buttonLabel;
    this.atributes = atributes;
    this.placeholder = placeholder;
    this.showTitle = showTitle;
    this.showOr = showOr;
    if(this.showOr == undefined) {
        this.showOr = true;
    }

    if(!this.saveFunction) {
        this.validators = {
            "required": {
                "expression": function (viewValue, modelValue) {
                    if (modelValue && modelValue.id) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
        };
        this.watcher = {   type: '$watchCollection',
            expression: 'model',
            listener: function(field, _new) {
            	if (field.formControl) {
                	if (field.formControl.$validate) {
                        field.formControl.$validate();
                    } else {
                    	if(field.formControl.length > 0){
                    		field.formControl[field.formControl.length - 1].$validate();
                    	}
                    }
                }
            }
        };
    }
} 

var AnnexaFormlyFieldLabelTemplateOptions = function(label, value) {
    this.label = label;
    this.value = value;
}

var AnnexaFormlyFieldLabelButtonTemplateOptions = function (type, label, buttonLabel, saveFunction, required, focus, atributes, step, modal, executeFunction) {
    this.type = type;
    this.label = label;
    this.buttonLabel = buttonLabel;
    this.saveFunction = saveFunction;
    this.required = required || false;
    this.focus = focus || false;
    this.step = step;
    this.validate = true;
    this.atributes = atributes;
    this.modal = modal;
    this.executeFunction = executeFunction;
}

var AnnexaFormlyFieldDatepickerTemplateOptions = function (type, label, required, datepickerOptions) {
    this.type = type;
    this.label = label;
    this.required = required || false;
    this.validate = true;
    this.datepickerOptions =  datepickerOptions;
}

var AnnexaFormlyFieldSelectTemplateOptions = function(label, valueProp, labelProp, options, required, type, onSelected, clearHide, tree, isMultiSelect) {
    this.type = type;
    this.optionsAttr = 'bs-options';
    this.ngOptions = 'option[to.valueProp] as option in to.options | filter: $select.search';
    if(isMultiSelect && labelProp) {
        this.ngOptions = 'option[to.valueProp] as option in to.options | filter: {'+labelProp+': $select.search}';
    }
    this.label = label;
    this.valueProp = valueProp;
    this.labelProp = labelProp;
    this.placeholder = '';
    this.options = options;
    this.required = required || false;
    this.onSelected = onSelected || function($item) {
    };
    this.clearHide = clearHide;

    if(tree && required){
        this.validators = {
            "required": {
                "expression": function (viewValue, modelValue) {
                    return modelValue.hasOwnProperty('$selected')
                }
            }
        };

        this.watcher = {   type: '$watchCollection',
            expression: 'model',
            listener: function(field, _new) {
            	if (field.formControl) {
                	if (field.formControl.$validate) {
                        field.formControl.$validate();
                    } else {
                    	if(field.formControl.length > 0){
                    		field.formControl[field.formControl.length - 1].$validate();
                    	}
                    }
                }
            }
        };

    }
}

var AnnexaFormlyFieldFilterInputSelectTemplateOptions = function(required, label, placeholder, search, advancedSearch, advancedSearchAdd) {
    this.required = required;
    this.label = label;
    this.placeholder = placeholder;
    this.search = search;
    this.advancedSearch = advancedSearch;
    this.advancedSearchAdd = advancedSearchAdd;
}

var AnnexaFormlyFieldSelectTemplateOptionsHtml = function(label, valueProp, labelProp, options, required, type, onSelected, labelHtml, clearHide) {
    this.type = type;
    this.optionsAttr = 'bs-options';
    this.ngOptions = 'option[to.valueProp] as option in to.options | filter: $select.search';
    this.label = label;
    this.valueProp = valueProp;
    this.labelProp = labelProp;
    this.labelHtml = labelHtml;
    this.placeholder = '';
    this.options = options;
    this.required = required || false;
    this.onSelected = onSelected || function($item) {
    };
    this.clearHide = clearHide;
}


var AnnexaFormlyFieldTextAreaTemplateOptions = function(label, rows, required, options, maxlength, updateOnBlur) {
    this.label = label;
    this.rows = rows || 5;
    this.required = required || false;
    this.options = options;
    this.maxlength = maxlength;
    this.updateOnBlur = ((updateOnBlur)? true : false);
}

var AnnexaFormlyFieldTextAreaLanguageTemplateOptions = function(id, modelField, label, disposition, required, focus, validator, languages, rows) {
    this.id = id;
    this.rows = rows || 5;
    this.modelField = modelField;
    this.type = 'text';
    this.label = label;
    this.disposition = disposition;
    this.required = required || false;
    this.focus = focus;
    var self = this;
    if(validator){
        this.validators = {
             "required": {
                "expression": function (viewValue, modelValue) {
                    var valid = true;
                    if(self.required) {
                        if (self.modelField) {
                            var column = self.modelField;
                            if (languages) {
                                angular.forEach(languages, function (value2, key2) {
                                    if (value2 && value2.column) {
                                        var cont = value2.column.charAt(value2.column.length - 1);
                                        if (modelValue && !modelValue[column + cont]) {
                                            valid = false;
                                        }
                                    }
                                });
                            }
                        }else{
                        	if (languages) {
                                angular.forEach(languages, function (value2, key2) {
                                    if (value2 && value2.column) {
                                        if (modelValue && !modelValue[value2.column]) {
                                            valid = false;
                                        }
                                    }
                                });
                            }
                        }
                    }
                    return valid;
                }
            }
        };

        this.watcher = {   type: '$watchCollection',
            expression: 'model',
            listener: function(field, _new) {
            	if (field.formControl) {
                	if (field.formControl.$validate) {
                        field.formControl.$validate();
                    } else {
                    	if(field.formControl.length > 0){
                    		field.formControl[field.formControl.length - 1].$validate();
                    	}
                    }
                }
            }
        };

    }

}

var AnnexaFormlyFieldLanguageTemplateOptions = function(id, modelField, label, disposition, required, focus, validator, languages) {
    this.id = id;
    this.modelField = modelField;
    this.type = 'text';
    this.label = label;
    this.disposition = disposition;
    this.required = required || false;
    this.focus = focus;
    var self = this;
    if(validator){
        this.validators = {
             "required": {
                "expression": function (viewValue, modelValue) {
                    var valid = true;
                    if(self.required) {
                        if (self.modelField) {
                            var column = self.modelField;
                            if (languages) {
                                angular.forEach(languages, function (value2, key2) {
                                    if (value2 && value2.column) {
                                        var cont = value2.column.charAt(value2.column.length - 1);
                                        if (modelValue && !modelValue[column + cont]) {
                                            valid = false;
                                        }
                                    }
                                });
                            }
                        }else{
                        	if (languages) {
                                angular.forEach(languages, function (value2, key2) {
                                    if (value2 && value2.column) {
                                        if (modelValue && !modelValue[value2.column]) {
                                            valid = false;
                                        }
                                    }
                                });
                            }
                        }
                    }
                    return valid;
                }
            }
        };

        this.watcher = {   type: '$watchCollection',
            expression: 'model',
            listener: function(field, _new) {
            	if (field.formControl) {
                	if (field.formControl.$validate) {
                        field.formControl.$validate();
                    } else {
                    	if(field.formControl.length > 0){
                    		field.formControl[field.formControl.length - 1].$validate();
                    	}
                    }
                }
            }
        };

    }

}

var AnnexaFormlySubmitButton = function (classes, label, icon) {
    this.classes = classes;
    this.label = label;
    this.icon = icon;
}

var AnnexaFormlyField = function (field_key, field_type, field_class, field_templateOptions) {
    this.key = field_key;
    this.type = field_type;
    this.className = field_class;
    this.templateOptions = field_templateOptions;
}

var AnnexaFormlyFieldAccordionTemplateOptions = function (label) {
    this.label = label;
}

var AnnexaFormlyFieldFieldSetTemplateOptions = function(label, options, modelField, placeholder, addFunction, removeFunction, optionAdd, showAdd, required, validator, languages) {
    this.label = label;
    this.modelField = modelField;
    this.addFunction = addFunction;
    this.removeFunction = removeFunction;
    this.placeholder = placeholder;
    this.options = options;
    this.optionAdd = optionAdd;
    this.showAdd = showAdd;
    this.required = required || false;
    var self = this;
}


var AnnexaFormly = function (submit, form,rootEl,name) {
    this.submit = submit;
    this.form = form;
    this.rootEl = rootEl || 'form';
    this.model = {};
    this.options = { watchAllExpressions: true }
    this.fields = [];
    this.name = name || 'formly'
    this.inModal = false;
    this.alerts = [];
}

AnnexaFormly.prototype.addAlert = function (alert) {
    this.alerts.push(alert);
}

AnnexaFormly.prototype.addGroup = function(group_name, class_name, fields,wrapper, hide_expression) {
    var self = this;

    var fieldGroup = self.createGroup(group_name, class_name, fields, {}, wrapper, hide_expression);

    if(self.inModal) {
        self.fields[0].fieldGroup.push(fieldGroup);
    } else {
        self.fields.push(fieldGroup);
    }
}

AnnexaFormly.prototype.addField = function(field_key, field_type, field_class, field_templateOptions, field_data, field_hideExpression, field_defaultValue, controller, customField, $rootScope) {
    var self = this;

    var nField = self.createField(field_key, field_type, field_class, field_templateOptions, field_data, field_hideExpression, field_defaultValue, customField, $rootScope);

    if(controller) {
        nField.controller = controller;
    }

    if(self.inModal) {
        self.fields[0].fieldGroup.push(nField);
    } else {
        self.fields.push(nField);
    }
}

AnnexaFormly.prototype.addFieldGroup = function(field_key, field_wrapper, field_templateOptions, fields_data, field_hideExpression) {
    var self = this;

    var nGroup = self.createGroup(field_key, '', fields_data, field_templateOptions, field_wrapper, field_hideExpression);

    if(self.inModal) {
        self.fields[0].fieldGroup.push(field);
    } else {
        self.fields.push(nGroup);
    }

}

AnnexaFormly.prototype.createGroup = function(group_key, group_className, group_data, group_templateOptions, group_wrapper, group_hideExpression){

    var group = {
        key: group_key,
        className: group_className,
        templateOptions: group_templateOptions,
        fieldGroup: group_data,
        wrapper: group_wrapper,
        hideExpression:group_hideExpression
    };
    return group;
};

AnnexaFormly.prototype.createField = function(field_key, field_type, field_class, field_templateOptions, field_data, field_hideExpression, field_defaultValue, customField, $rootScope) {
    if(field_data) {
        field_data.informed = true;
        field_data.clear = function($event,model,key, $select) {
            $event.stopPropagation();
            model[key] = undefined;
            if($select) {
                $select.selected = undefined;
                $select.search = undefined;
            }

            if(customField) {
                if(customField.frontendType == 'SELECT') {
                    $rootScope.$broadcast('customFieldSelectSelected', { customField: customField.id, selectedValue: '' });
                }
            }
        }
    } else {
        field_data = {
            informed: false,
            clear: function($event,model,key, $select) {
                $event.stopPropagation();
                model[key] = undefined;
                if($select) {
                    $select.selected = undefined;
                    $select.search = undefined;
                }
            }
        };
    }

    var field = {
        key: field_key,
        type: field_type,
        className: field_class,
        data: field_data,
        templateOptions: field_templateOptions,
        validation: {
            show: true
        },
        expressionProperties: {
            "validation.show": "formControl.$submitted"
        },
        hideExpression:field_hideExpression
    };
    if(field_templateOptions){
        if(field_type == 'annexaMultipleSelectRow' && field_templateOptions.required == true){
            field.validators = {
                "required": {
                    "expression": function (viewValue, modelValue) {
                        if (modelValue && modelValue.length > 0) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                }
            };
            field.watcher = {type: '$watchCollection',
                expression: field_key,
                listener: function(field, _new) {
                	if (field.formControl) {
                    	if (field.formControl.$validate) {
                            field.formControl.$validate();
                        } else {
                        	if(field.formControl.length > 0){
                        		field.formControl[field.formControl.length - 1].$validate();
                        	}
                        }
                    }
                }
            };
        }else {
            if (field_templateOptions.validators) {
                field.validators = field_templateOptions.validators;
            }
            if (field_templateOptions.watcher) {
                field.watcher = field_templateOptions.watcher;
            }
        }
    }

    if(field_defaultValue) {
        field.defaultValue = field_defaultValue;
    }

    return field;
}

AnnexaFormly.prototype.createModal = function() {
    var self = this;

    self.addGroup('modal_body', 'modal-body p-lg show', []);
    self.inModal = true;
}

AnnexaFormly.prototype.addSubmitCallback = function (customCallback){
    var self = this;
    self.onSubmit   = customCallback;  // Trigger per al modal
    self.submit     = customCallback;  // Trigger per a la fitxa
}

var AnnexaFormlyModal = function(title, annexaFormly, submitModal,size) {
    this.title = title;
    this.size = size || '';
    var afFileds = annexaFormly.fields;
    annexaFormly.fields =  [];
    annexaFormly.addGroup('modal_body','modal-body p-lg show', afFileds);
    this.annexaFormly = annexaFormly;
    this.submitModal = submitModal;
    this.modal = undefined;
    this.close = undefined;
}


