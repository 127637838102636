/**
 * Created by dpardell on 08/03/2016.
 */
var App = function(name, version, baseUrl, primary, accent, warn, info, success, warning, danger, white, light, dark, black, setting, entity, loggeduser, pendingct, languagedef) {
    this.name = name;
    this.version = version;
    this.baseUrl = baseUrl;
    this.color = {};
    this.color.primary = primary;
    this.color.accent = accent;
    this.color.warn = warn;
    this.color.info = info;
    this.color.success = success;
    this.color.warning = warning;
    this.color.danger = danger;
    this.color.white = white;
    this.color.light = light;
    this.color.dark = dark;
    this.color.black = black;
    this.setting = setting;
    this.entity = entity;
    this.loggeduser = loggeduser;
    this.pendingct = pendingct;
    this.language = '';
    this.languagedef = languagedef
    this.menu = {};
    this.configuration = {};
}

var LanguageDef = function(acronym, label, column, active) {
    this.acronym = acronym;
    this.label = label;
    this.column = column;
    this.active = active;
}

var Entity = function(type, id, logo, name, holidays){
    this.type = type;
    this.id = id;
    this.logo = logo;
    this.name = name;
    this.holidays = holidays;
}

var Setting = function(primary, accent, warn, folded, boxed, container, themeID, bg) {
    this.theme = {};
    this.theme.primary = primary;
    this.theme.accent = accent;
    this.theme.warn = warn;
    this.folded = folded;
    this.boxed = boxed;
    this.container = container;
    this.themeID = themeID;
    this.bg = bg;
}