/**
 * Created by osirvent on 05/07/2016.
 */
angular
    .module('annexaApp')
    .factory('AnnexaModalFactory',['ModalService', function(ModalService) {

        return {
            showModal: function(idModal, modalDefinition) {
                ModalService.showModal({
                    template: '<annexa-modal id="' + idModal + '" modal-object="modalObj" class="modal black-overlay" data-backdrop="false"/>',
                    controller: 'AnnexaModalController',
                    modalObj: modalDefinition
                }).then(function(modal) {
                    modalDefinition.modal = modal;
                    modalDefinition.close = modal.element.scope().close;
                    modal.element.modal();
                    if(modalDefinition.callCloseFunction){
                        modal.close.then(function(){
                            modalDefinition.callCloseFunction();
                        })
                    }
                    angular.element(".modal-title").focus();
                });
            },
            showBoxAdvancedFilter: function(modalDefinition) {
                ModalService.showModal({
                    template: '<annexa-box-filter id="' + modalDefinition.id + '" modal-object="modalObj" class="modal black-overlay" data-backdrop="false"/>',
                    controller: 'AnnexaModalController',
                    modalObj: modalDefinition
                }).then(function(modal) {
                    modalDefinition.modal = modal;
                    modalDefinition.close = modal.element.scope().close;
                    modal.element.modal();
                    if(modalDefinition.callCloseFunction){
                        modal.close.then(function(){
                            modalDefinition.callCloseFunction();
                        })
                    }
                    angular.element(".modal-title").focus();
                })
            }
        }
    }]);
