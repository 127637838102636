/**
 * Created by osirvent on 18/01/2017.
 */
angular.module('annexaApp')
    .directive('annexaBpmModeler',['$rootScope', '$filter', 'dialogs', 'Language', 'ABMComponentFactory', 'ABMValidationFactory', '$timeout', function($rootScope, $filter, dialogs, Language, ABMComponentFactory, ABMValidationFactory, $timeout) {
        return {
            restrict: 'E',
            replace: true,
            templateUrl: './views/templates/annexabpmmodeler.html',
            scope: {
                procedure: '='
            },
            controller: ['$scope', 'ABMModelFactory', 'ABMValidationFactory', 'ValidationService', '$rootScope', '$filter', 'ABMShapesFactory', 'ABMInspectorFactory', function($scope, ABMModelFactory, ABMValidationFactory, ValidationService, $rootScope, $filter, ABMShapesFactory, ABMInspectorFactory) {
                var inspectorValidation = new  ValidationService({ preValidateFormElements: true });

                $scope.$validationOptions = {
                    debounce: 1500,                // set the debounce globally
                    preValidateFormElements: true  // pre-validate all form elements, false by default
                };
                
                $scope.setProfileSelectableOptions = function(options, modelKey) {
                	var selectableOptions = [];
                    _.forEach(options, function (value, key) {
                		if(modelKey != null && (value.expiryDate == null || $scope.model[modelKey].includes(value.id))) {
                			selectableOptions.push(value);
                		} else if(value.expiryDate == null) {
                			selectableOptions.push(value);
                		}
                    });
                    return $linq(selectableOptions).orderBy("x => x." + Language.getActiveColumn(), linq.caseInsensitiveComparer).toArray();
                }

                $scope.model = ABMModelFactory.InitializeModel();
                $scope.options = ABMModelFactory.getOptions();
                $scope.optionsAdd = ABMModelFactory.getOptionsAdd();
                var procedure = angular.copy($scope.procedure);
                if(procedure) {
                    $scope.model.language1 = procedure.language1;
                    $scope.model.language2 = procedure.language2;
                    $scope.model.language3 = procedure.language3;
                    $scope.model.descriptionLanguage1 = procedure.descriptionLanguage1;
                    $scope.model.descriptionLanguage2 = procedure.descriptionLanguage2;
                    $scope.model.descriptionLanguage3 = procedure.descriptionLanguage3;
                    $scope.model.maxDays = procedure.maxDays;
                    $scope.model.expireType = procedure.expireType;
                    $scope.model.expirationDaysNotification = procedure.expirationDaysNotification;
                    $scope.model.allowUserChangeExpirationDaysNotification = procedure.allowUserChangeExpirationDaysNotification ? 'YES' : 'NO';
                    $scope.model.accessLevelNotification = procedure.accessLevelNotification;
                    $scope.model.daysBeforeAlertTransaction = procedure.daysBeforeAlertTransaction;
                    $scope.model.daysBeforeAlertDossier = procedure.daysBeforeAlertDossier;
                    $scope.model.acronym = procedure.acronym;
                    $scope.model.showSubject = procedure.showSubject;
                    $scope.model.showClassification = procedure.showClassification;
                    $scope.model.active = procedure.active ? 'YES' : 'NO';
                    $scope.model.guided = procedure.guided;
                    $scope.model.procedureViewProfilesOriginal = procedure.procedureViewProfiles;
                    _.forEach(procedure.procedureViewProfiles, function(item) {
                        $scope.model.procedureViewProfiles.push(item.profile.id);
                    })
                    $scope.options.procedureViewProfiles = $scope.setProfileSelectableOptions($scope.options.procedureViewProfiles, 'procedureViewProfiles');
                    $scope.model.procedureStartProfilesOriginal = procedure.procedureStartProfiles;
                    _.forEach(procedure.procedureStartProfiles, function(item) {
                        $scope.model.procedureStartProfiles.push(item.profile.id);
                    })
                    $scope.options.procedureStartProfiles = $scope.setProfileSelectableOptions($scope.options.procedureStartProfiles, 'procedureStartProfiles');
                    $scope.model.procedureTramitationProfilesOriginal = procedure.procedureTramitationProfiles;
                    _.forEach(procedure.procedureTramitationProfiles, function(item) {
                        $scope.model.procedureTramitationProfiles.push(item.profile.id);
                    })
                    $scope.options.procedureTramitationProfiles = $scope.setProfileSelectableOptions($scope.options.procedureTramitationProfiles, 'procedureTramitationProfiles');
                    $scope.model.procedureRoleInterestedOriginal = procedure.procedureRoleInterested;
                    _.forEach(procedure.procedureRoleInterested, function(item) {
                        $scope.model.procedureRoleInterested.push(item.roleInterested.id);
                    })
                    $scope.model.procedureResponsibleProfilesOriginal = procedure.procedureResponsibleProfiles;
                    _.forEach(procedure.procedureResponsibleProfiles, function(item) {
                        $scope.model.procedureResponsibleProfiles.push(item.profile.id);
                    })
                    $scope.options.procedureResponsibleProfiles = $scope.setProfileSelectableOptions($scope.options.procedureResponsibleProfiles, 'procedureResponsibleProfiles');
                    _.forEach(procedure.procedureClassifications, function(item) {
                        $scope.model.procedureClassifications.push({ language1: item.language1, language2: item.language2, language3: item.language3, id: item.id });
                    });
                    $scope.model.informationLanguage1 = procedure.informationLanguage1;
                    $scope.model.informationLanguage2 = procedure.informationLanguage2;
                    $scope.model.informationLanguage3 = procedure.informationLanguage3;
                    $scope.model.family = ((procedure.family && procedure.family != null)?procedure.family:{});
                    if($scope.model.family && $scope.model.family.id) {
                        $scope.model.family.$selected = { id: $scope.model.family.id, title: $scope.model.family[Language.getActiveColumn()] };
                    }
                    $scope.model.archiveClassification = ((procedure.archiveClassification && procedure.archiveClassification != null)?procedure.archiveClassification:{});
                    if($scope.model.archiveClassification && $scope.model.archiveClassification.id){
                        $scope.model.archiveClassification.$selected = {id:$scope.model.archiveClassification.id, title:$scope.model.archiveClassification[Language.getActiveColumn()]};
                    }
                    $scope.model.procedureType = procedure.procedureType;
                    $scope.model.id = procedure.id;
                    $scope.model.createdDate = procedure.createdDate;
                    $scope.model.allowUserChangeMaxDays = procedure.allowUserChangeMaxDays ? 'YES' : 'NO';
                    if(procedure.tramitationType && procedure.tramitationType.id){
                        $scope.model.tramitationTypeProcedure = procedure.tramitationType.id;
                    }
                    $scope.model.operationsActive = procedure.operationsActive ? 'YES' : 'NO';
                    $scope.model.operationTypesOriginal = procedure.operationTypes;
                    _.forEach(procedure.operationTypes, function(item) {
                        $scope.model.operationTypes.push(item.operationType.id);
                    })
                    $scope.model.inspectionIntervention = procedure.inspectionIntervention;
                    $scope.model.inspectionInterventionType = procedure.inspectionInterventionType;
                    $scope.model.closeExpireType = procedure.closeExpireType;
                    $scope.model.closeExpireQuantity = procedure.closeExpireQuantity;
                    $scope.model.automaticClose = procedure.automaticClose;
                    $scope.model.transferExpireType = procedure.transferExpireType;
                    $scope.model.transferExpireQuantity = procedure.transferExpireQuantity;
                    $scope.model.automaticTransfer = procedure.automaticTransfer;
                    $scope.model.archiveExpireType = procedure.archiveExpireType;
                    $scope.model.archiveExpireQuantity = procedure.archiveExpireQuantity;
                    $scope.model.automaticArchive = procedure.automaticArchive;
					$scope.model.emgdeSecurityLevel = procedure.emgdeSecurityLevel;
					$scope.model.emgdeAccesType = procedure.emgdeAccesType;
					$scope.model.emgdeLimitCause = procedure.emgdeLimitCause;
				    $scope.model.emgdeLegalLimitCause = procedure.emgdeLegalLimitCause;
					$scope.model.emgdeReuseCondition = procedure.emgdeReuseCondition;
					$scope.model.emgdeValueType = procedure.emgdeValueType;
					$scope.model.emgdeRatingTerms = procedure.emgdeRatingTerms;
					$scope.model.emgdeSecundaryValue = procedure.emgdeSecundaryValue;
					$scope.model.emgdeOpinionType = procedure.emgdeOpinionType;
					$scope.model.emgdeOpinionAction = procedure.emgdeOpinionAction;
					$scope.model.emgdeOpinionActionTerms = procedure.emgdeOpinionActionTerms;
					$scope.model.emgdeTransferArchive = procedure.emgdeTransferArchive;
					$scope.model.emgdeTransferTerms = procedure.emgdeTransferTerms;
					$scope.model.emgdeEssentialDocument = procedure.emgdeEssentialDocument;
					$scope.model.customFieldsOriginal = procedure.customFields;
                    _.forEach(procedure.customFields, function (value) {
                        var customField = {
                            customField: value.customField,
                            id: value.id,
                            delete: value.deleted,
                            viewOrder:value.viewOrder,
                            required: value.required,
                            requiredForEndDossier: value.requiredForEndDossier,
                            hiddenField: value.hiddenField,
                            noEditable: value.noEditable,
                            createdDate: value.createdDate,
                            procedure: {id:value.id},
                            value: value.value,
							customFieldType: value.customFieldType,
							transactionTypes: value.transactionTypes
                        };
                        customField.requiredString = 'OPTIONAL';
                        if(value.required){
                            customField.requiredString = 'REQUIRED';
                        }else if(value.requiredForEndDossier){
                            customField.requiredString = 'REQUIRED_TO_END';
                        }else if(value.hiddenField) {
                            customField.requiredString = 'HIDDEN_FIELD';
                        }else if(value.noEditable) {
                            customField.requiredString = 'NO_EDITABLE';
                        }
                        $scope.model.customFields.push(customField);
                    });

                    $scope.model.transactionsOriginal = procedure.transactions;
                    _.forEach(procedure.transactions, function(item) {
                        var trans = item.transaction;

                        var modelTransaction = ABMModelFactory.InitializeTransaction(false);

                        modelTransaction.language1 = trans.language1;
                        modelTransaction.language2 = trans.language2;
                        modelTransaction.language3 = trans.language3;
                        modelTransaction.descriptionLanguage1 = trans.descriptionLanguage1;
                        modelTransaction.descriptionLanguage2 = trans.descriptionLanguage2;
                        modelTransaction.descriptionLanguage3 = trans.descriptionLanguage3;
                        modelTransaction.informationLanguage1 = trans.informationLanguage1;
                        modelTransaction.informationLanguage2 = trans.informationLanguage2;
                        modelTransaction.informationLanguage3 = trans.informationLanguage3;
                        modelTransaction.maxDays = trans.maxDays;
                        modelTransaction.allowThirds = trans.allowThirds ? 'YES' : 'NO';
                        modelTransaction.allowExpedientRelations = trans.allowExpedientRelations ? 'YES' : 'NO';
                        modelTransaction.daysComputeGlobal = trans.daysComputeGlobal ? 'YES' : 'NO';
                        modelTransaction.showEndProcess = trans.showEndProcess ? 'YES' : 'NO';
                        modelTransaction.userInitialStateInheritance = trans.userInitialStateInheritance ? 'YES' : 'NO';
                        modelTransaction.processByProfile = trans.processByProfile;
                        modelTransaction.id = trans.id;
                        modelTransaction.createdDate = trans.createdDate;
                        modelTransaction.processProfilesOriginal = trans.processProfiles;
                        _.forEach(trans.processProfiles, function (item) {
                            modelTransaction.processProfiles.push(item.profile.id);
                        });
                        modelTransaction.userStateInheritance = trans.userStateInheritance;
                        if(_.contains([ABMShapesFactory.TEN, ABMShapesFactory.TEF], trans.graphNodeType)) {
                            modelTransaction.assignationType = ABMModelFactory.AT_GUIDED;
                        } else if(_.contains([ABMShapesFactory.TFN, ABMShapesFactory.TFF], trans.graphNodeType)) {
                            modelTransaction.assignationType = ABMModelFactory.AT_NONGUIDED;
                        } else if(_.contains([ABMShapesFactory.SFN], trans.graphNodeType)) {
                            modelTransaction.assignationType = ABMModelFactory.AT_NONGUIDED;
                            modelTransaction.subprocedureName1 = modelTransaction.language1;
                            modelTransaction.subprocedureName2 = modelTransaction.language2;
                            modelTransaction.subprocedureName3 = modelTransaction.language3;
                        }
                        
                        if(trans.expireType) {
                        	modelTransaction.expireType = trans.expireType;
                        } else if(trans.transactionType && trans.transactionType.expireType) {
                        	modelTransaction.expireType =  trans.transactionType.expireType;
                    	} else {
                        	modelTransaction.expireType = 'BUSINESS';
                        }

                        if(!_.contains([ABMShapesFactory.SFN], trans.graphNodeType)){
                            if(_.contains([ABMShapesFactory.SN], trans.graphNodeType)){
                            	modelTransaction.assignationTypeTram = trans.assignationTypeTram;
                            }else{
	                        	if(modelTransaction.assignationType == ABMModelFactory.AT_NONGUIDED){
	                                modelTransaction.assignationTypeTram = ABMModelFactory.AT_NONGUIDED;
	                            }else if(modelTransaction.processProfiles && modelTransaction.processProfiles.length == 0){
	                                modelTransaction.assignationTypeTram = ABMModelFactory.AT_INHERITED;
	                            }else{
	                                modelTransaction.assignationTypeTram = ABMModelFactory.AT_GUIDED;
	                            }
                        	}

                        }

                        if(trans.graphNodeType == ABMShapesFactory.TI) {
                            modelTransaction.tramitationType = 'INITIAL';
                        } else if(_.contains([ABMShapesFactory.TEN, ABMShapesFactory.TFN, ABMShapesFactory.SN, ABMShapesFactory.SFN], trans.graphNodeType)) {
                            modelTransaction.tramitationType = ABMModelFactory.TT_NORMAL;
                        } else if(_.contains([ABMShapesFactory.TEF, ABMShapesFactory.TFF], trans.graphNodeType)) {
                            modelTransaction.tramitationType = ABMModelFactory.TT_FINAL;
                        }

                        modelTransaction.graphNodeId = trans.graphNodeId;
                        modelTransaction.graphNodeType = trans.graphNodeType;
                        modelTransaction.customFieldsOriginal = trans.customFields;
                        _.forEach(trans.customFields, function (value) {
                            var customField = {
                                customField: value.customField,
                                id: value.id,
                                delete: value.deleted,
                                viewOrder:value.viewOrder,
                                required: value.required,
                                requiredForEndDossier: value.requiredForEndDossier,
                                hiddenField: value.hiddenField,
                                noEditable: value.noEditable,
                                createdDate: value.createdDate,
                                transaction: {id:trans.id},
                                value: value.value
                            };
                            customField.requiredString = 'OPTIONAL';
                            if(value.required){
                                customField.requiredString = 'REQUIRED';
                            }else if(value.requiredForEndDossier){
                                customField.requiredString = 'REQUIRED_TO_END';
                            }else if(value.hiddenField) {
                                customField.requiredString = 'HIDDEN_FIELD';
                            }else if(value.noEditable) {
                                customField.requiredString = 'NO_EDITABLE';
                            }
                            modelTransaction.customFields.push(customField);
                        });

                        if(trans.subprocedure) {
                            modelTransaction.subprocedure = trans.subprocedure.id;
                        }
                        if(trans.transactionType) {
                            modelTransaction.transactionType = trans.transactionType.id;
                        }
                        modelTransaction.deleteInitialNode = ((trans.deleteInitialNode)?'YES' : 'NO');
                        modelTransaction.deleteFinalNode = ((trans.deleteFinalNode)?'YES' : 'NO');
                        $scope.model.transactions[trans.graphNodeId] = modelTransaction;
                    });
                } else {
                    $scope.options.procedureViewProfiles = $scope.setProfileSelectableOptions($scope.options.procedureViewProfiles, null);
                    $scope.options.procedureResponsibleProfiles = $scope.setProfileSelectableOptions($scope.options.procedureResponsibleProfiles, null);
                    $scope.options.procedureStartProfiles = $scope.setProfileSelectableOptions($scope.options.procedureStartProfiles, null);
                    $scope.options.procedureTramitationProfiles = $scope.setProfileSelectableOptions($scope.options.procedureTramitationProfiles, null);
                    $scope.options.processProfiles = $scope.setProfileSelectableOptions($scope.options.processProfiles, null);
                }
                
                var setTransactionsTexts = function () {
                	var languageColumn = Language.getActiveColumn();
                	if(!languageColumn){
                		languageColumn = 'language1';
                	}
                    _.forOwn($scope.model.transactions, function(value, key) {
                        if(!_.contains([ABMShapesFactory.SN, ABMShapesFactory.SFN], value.graphNodeType)) {
                            ABMComponentFactory.setCellContent(key, ((value[languageColumn])?value[languageColumn]:value.language1));
                        }else if(_.contains([ABMShapesFactory.SFN], value.graphNodeType)){
                        	if(languageColumn == 'language3'){
                        		ABMComponentFactory.setCellContent(key, value.subprocedureName3);
                        	}else if(languageColumn == 'language2'){
                        		ABMComponentFactory.setCellContent(key, value.subprocedureName2);
                        	}else{
                        		ABMComponentFactory.setCellContent(key, value.subprocedureName1);	
                        	}
                        }else if(value.subprocedure){
                           var selected = $linq(ABMInspectorFactory.scope.options.subprocedure).singleOrDefault(undefined, "x => x.id == " + value.subprocedure);
                           if(selected){
                               ABMComponentFactory.setCellContent(key, ((selected[languageColumn])?selected[languageColumn]:selected.language1));
                           }
                        }else if(value.language1){
                            ABMComponentFactory.setCellContent(key, ((value[languageColumn])?value[languageColumn]:value.language1));
                        }
                    });
                };

                $scope.$watch(function($scope) {
                    setTransactionsTexts();
                }, function (newVal) {
                }, true);

                $scope.$on("toggleAdminMenu", function (event, args) {
                    if(ABMComponentFactory.paperScroller) {
                        ABMComponentFactory.paperScroller.center();
                    }
                });

                $scope.$on('graphJSON', function (event, args) {
                    var json = ABMComponentFactory.graph.toJSON();

                    var wnd = window.open("about:blank", "", "_blank");
                    wnd.document.write('<code>' + JSON.stringify(json) + '</code>');
                });

                $scope.$on('saveBPM', function(event, args) {
                    $scope.inspector.$setSubmitted();
                    ABMValidationFactory.validateModel();

                    if(inspectorValidation.checkFormValidity($scope)) {
                        if (ABMValidationFactory.validations.length == 0) {
                            args.saveFunc(ABMComponentFactory.graph.toJSON(), _.clone($scope.model));
                        } else {
                            $timeout(function () {
                                $scope.statusMessage = $filter('translate')('global.bpm.validation.noValid');
                                angular.element('.stencil-container').css('top', '40px');
                                angular.element('.paper-container').css('top', '40px');
                                angular.element('.inspector-container').css('top', '40px');
                                angular.element('.toolbar-container').css('top', '170px');
                                angular.element('.statusbar-container').show();
                            });
                        }
                    } else {
                        if (ABMValidationFactory.validations.length != 0) {
                            $timeout(function () {
                                $scope.statusMessage = $filter('translate')('global.bpm.validation.noValid');
                                angular.element('.stencil-container').css('top', '40px');
                                angular.element('.paper-container').css('top', '40px');
                                angular.element('.inspector-container').css('top', '40px');
                                angular.element('.toolbar-container').css('top', '170px');
                                angular.element('.statusbar-container').show();
                            });
                        } else {
                            args.saveFunc(ABMComponentFactory.graph.toJSON(), _.clone($scope.model));
                        }
                    }
                });
                
                $scope.$on('validateBPMErrorToSave', function(event, args) {
                    $timeout(function () {
                        $scope.statusMessage = $filter('translate')('global.bpm.validation.noValid');
                        angular.element('.stencil-container').css('top', '40px');
                        angular.element('.paper-container').css('top', '40px');
                        angular.element('.inspector-container').css('top', '40px');
                        angular.element('.toolbar-container').css('top', '170px');
                        angular.element('.statusbar-container').show();
                    });
                });
                
                $scope.angularInputLanguageValidation = function(field, origin) {
                    var cont = 1;

                    var all = true;

                    angular.forEach($rootScope.app.languagedef, function(value) {
                        var value = undefined;

                        if(!origin) {
                            value = $scope.model[field + cont];
                        } else {
                            value = $scope.model.transactions[origin][field + cont];
                        }

                        if(value == undefined || value == '') {
                            all = false;
                        }

                        cont++;
                    });

                    return { isValid: all, message: $filter('translate')('global.validation.required') };
                };

                var getDetailedErrors = function () {
                    var template = '';

                    template += '<ul>';

                    angular.forEach(ABMValidationFactory.validations, function (validation) {
                        switch (validation.type) {
                            case 'graph':
                                template += '<li>';
                                template +=     $filter('translate')('global.bpm.validation.graphValidation');
                                template += '   <ul>';
                                angular.forEach(validation.result, function(msg) {
                                    if(msg.resultParameters) {
                                        template += '<li>' + $filter('translate')(msg.result, msg.resultParameters) + '</li>';
                                    } else {
                                        template += '<li>' + $filter('translate')(msg.result) + '</li>';
                                    }
                                });
                                template += '   </ul>';
                                template += '</li>';
                                break;
                            case 'procedure':
                                template += '<li>';
                                template +=     $filter('translate')('global.bpm.modelvalidation.procedureValidation');
                                template += '   <ul>';
                                angular.forEach(validation.result, function(msg) {
                                    if(msg.resultParameters) {
                                        template += '<li>' + $filter('translate')(msg.result, msg.resultParameters) + '</li>';
                                    } else {
                                        template += '<li>' + $filter('translate')(msg.result) + '</li>';
                                    }
                                });
                                template += '   </ul>';
                                template += '</li>';
                                break;
                            case 'transaction':
                                template += '<li>';
                                template +=     $filter('translate')('global.bpm.modelvalidation.transactionValidations', { name: validation.name });
                                template += '   <ul>';
                                angular.forEach(validation.result, function (msg) {
                                    if(msg.resultParameters) {
                                        template += '<li>' + $filter('translate')(msg.result, msg.resultParameters) + '</li>';
                                    } else {
                                        template += '<li>' + $filter('translate')(msg.result) + '</li>';
                                    }
                                })
                                template += '   </ul>';
                                template += '</li>';
                                break;
                        }
                    });

                    template += '</ul>';

                    return template;
                }

                $scope.statusDetails = function() {
                    var dlg = dialogs.notify(
                        $filter('translate')('global.bpm.validation.detailsHeader'),
                        getDetailedErrors(),
                        {
                            animation: true,
                            backdrop: 'static',
                            keyboard: true,
                            size: '',
                            backdropClass: 'zMax',
                            windowClass: 'zMax3'
                        }
                    );

                    dlg.result.then(function(btn){}, function (btn) {});
                };

            }],
            link: function(scope, element, attrs) {
                _.invoke(ABMComponentFactory.Initialization, 'call', window, scope, element);
                angular.element('.statusbar-container').hide();
                angular.element('.stencil-container').css('top', '0px');
                angular.element('.paper-container').css('top', '0px');
                angular.element('.inspector-container').css('top', '0px');
                angular.element('.toolbar-container').css('top', '140px');
            }
        }
    }]);