/**
 * Created by osirvent on 24/05/2016.
 */
(function() {
    'use strict';
    angular
        .module('annexaApp')
        .filter('fromNow', fromNow);

    function fromNow() {
        return function(date) {
            return moment(date).fromNow();
        }
    }
})();
