/**
 * Created by osirvent on 27/01/2017.
 */
angular.module('annexaApp')
    .factory('ABMShapesFactory', ['ABMModelFactory', 'CommonService', function(ABMModelFactory, CommonService) {
        var factory = {};

        factory.TEN = 'TransactionEstablishedNormal';
        factory.TEF = 'TransactionEstablishedFinal';
        factory.TFN = 'TransactionFreeNormal';
        factory.TFF = 'TransactionFreeFinal';
        factory.TI  = 'TransactionInitial';
        factory.SN  = 'SubprocessNormal';
        factory.SFN  = 'SubprocessFreeNormal';
        factory.GSO = 'SelectOne';
        factory.GSM = 'SelectMultiple';
        factory.GSMO = 'SelectMultipleOpen';
        factory.GSMC = 'SelectMultipleClose';
        factory.GSA = 'SelectAll';
        factory.GSAO = 'SelectAllOpen';
        factory.GSAC = 'SelectAllClose';
        factory.ABMComponentFactory = undefined;

        //region General

        factory.setABMComponentFactory = function (ComponentFactory) {
            factory.ABMComponentFactory = ComponentFactory;
        };

        //endregion

        //region Shape Creation

        factory.getShapes = function() {
            return [
                new joint.shapes.bpmn.Activity({
                    activityType: 'task',
                    attrs: {
                        '.': { class: 'joint-theme-bpmn joint-cell joint-type-bpmn joint-type-bpmn-activity joint-element' }
                    }
                }),
                new joint.shapes.bpmn.Activity({
                    activityType: 'task',
                    icon: 'service',
                    attrs: {
                        '.': { class: 'joint-theme-bpmn joint-cell joint-type-bpmn joint-type-bpmn-activity joint-element' }
                    }
                }),
                new joint.shapes.bpmn.Gateway({
                    gatewayType: factory.GSO,
                    icon: 'cross',
                    attrs: {
                        '.': { class: 'joint-theme-bpmn joint-cell joint-type-bpmn joint-type-bpmn-gateway joint-element' }
                    }

                }),
                new joint.shapes.bpmn.Gateway({
                    gatewayType: factory.GSA,
                    icon: 'plus',
                    attrs: {
                        '.': { class: 'joint-theme-bpmn joint-cell joint-type-bpmn joint-type-bpmn-gateway joint-element' }
                    }

                }),
                new joint.shapes.bpmn.Gateway({
                    gatewayType: factory.GSM,
                    icon: 'circle',
                    attrs: {
                        '.': { class: 'joint-theme-bpmn joint-cell joint-type-bpmn joint-type-bpmn-gateway joint-element' }
                    }

                })
            ];
        };

        factory.getShapeTooltip = function (cell) {
            var tooltip = 'global.bpm.shapetypes.';

            switch (factory.getShapeType(cell)) {
                case factory.TEN:
                    tooltip += 'TEN';
                    break;
                case factory.TEF:
                    tooltip += 'TEF';
                    break;
                case factory.TFN:
                    tooltip += 'TFN';
                    break;
                case factory.TFF:
                    tooltip += 'TFF';
                    break;
                case factory.TI:
                    tooltip += 'TI';
                    break;
                case factory.SN:
                    tooltip += 'SN';
                    break;
                case factory.SFN:
                    tooltip += 'SFN';
                    break;
                case factory.GSO:
                    tooltip += 'GSO';
                    break;
                case factory.GSM:
                    tooltip += 'GSM';
                    break;
                case factory.GSA:
                    tooltip += 'GSA';
                    break;
                default:
                    tooltip = 'Unknown'
                    break;
            }

            return tooltip;
        };

        factory.getShape = function(type, position, content, highlight) {
            var hl = highlight != undefined ? highlight : '#ffffff';

            switch (type) {
                case factory.TI:
                    return new joint.shapes.bpmn.Activity({
                        position: position,
                        size: { width: 160, height: 40 },
                        content: content,
                        activityType: 'transaction',
                        attrs: {
                            '.': { class: 'joint-theme-bpmn joint-cell joint-type-bpmn joint-type-bpmn-activity joint-element' }
                        }
                    });
                case factory.GSAO:
                    return new joint.shapes.bpmn.Gateway({
                        markup: '<g class="rotatable"><g class="scalable"><polygon class="body"/><path class="gateway"/><image/></g></g><text class="label"/>',
                        position: position,
                        size: {
                            width: 40,
                            height: 40
                        },
                        gatewayType: factory.GSAO,
                        gatewayId: '',
                        icon: 'plus',
                        attrs: {
                            '.': { class: 'joint-theme-bpmn joint-cell joint-type-bpmn joint-type-bpmn-gateway joint-element' },
                            '.gateway': {
                                d: 'M40 80 L0 40 L40 0',
                                fill: 'none',
                                stroke: '#000000',
                                'stroke-width': 5,
                                'stroke-dasharray': 'none'
                            },
                            '.body': {
                                points: '40,0 80,40 40,80 0,40',
                                fill:  hl,
                                stroke: '#000000'
                            },
                            '.label': {
                                text: '',
                                ref: '.body',
                                'ref-x': .5,
                                'ref-dy': 20,
                                'y-alignment': 'middle',
                                'x-alignment': 'middle',
                                'font-size': 14,
                                'font-family': 'Arial, helvetica, sans-serif',
                                fill: '#000000'
                            },
                            image: {
                                width: 40,
                                height: 40,
                                'xlink:href': '',
                                transform: 'translate(20,20)'
                            }
                        }

                    });
                case factory.GSAC:
                    return new joint.shapes.bpmn.Gateway({
                        markup: '<g class="rotatable"><g class="scalable"><polygon class="body"/><path class="gateway"/><image/></g></g><text class="label"/>',
                        position: position,
                        size: {
                            width: 40,
                            height: 40
                        },
                        gatewayType: factory.GSAC,
                        gatewayId: '',
                        icon: 'plus',
                        attrs: {
                            '.': { class: 'joint-theme-bpmn joint-cell joint-type-bpmn joint-type-bpmn-gateway joint-element' },
                            '.gateway': {
                                d: 'M40 80 L80 40 L40 0',
                                fill: 'none',
                                stroke: '#000000',
                                'stroke-width': 5,
                                'stroke-dasharray': 'none'
                            },
                            '.body': {
                                points: '40,0 80,40 40,80 0,40',
                                fill: hl,
                                stroke: '#000000'
                            },
                            '.label': {
                                text: '',
                                ref: '.body',
                                'ref-x': .5,
                                'ref-dy': 20,
                                'y-alignment': 'middle',
                                'x-alignment': 'middle',
                                'font-size': 14,
                                'font-family': 'Arial, helvetica, sans-serif',
                                fill: '#000000'
                            },
                            image: {
                                width: 40,
                                height: 40,
                                'xlink:href': '',
                                transform: 'translate(20,20)'
                            }
                        }

                    });
                case factory.GSMO:
                    return new joint.shapes.bpmn.Gateway({
                        markup: '<g class="rotatable"><g class="scalable"><polygon class="body"/><path class="gateway"/><image/></g></g><text class="label"/>',
                        position: position,
                        size: {
                            width: 40,
                            height: 40
                        },
                        gatewayType: factory.GSMO,
                        gatewayId: '',
                        icon: 'circle',
                        attrs: {
                            '.': { class: 'joint-theme-bpmn joint-cell joint-type-bpmn joint-type-bpmn-gateway joint-element' },
                            '.gateway': {
                                d: 'M40 80 L0 40 L40 0',
                                fill: 'none',
                                stroke: '#000000',
                                'stroke-width': 5,
                                'stroke-dasharray': 'none'
                            },
                            '.body': {
                                points: '40,0 80,40 40,80 0,40',
                                fill: hl,
                                stroke: '#000000'
                            },
                            '.label': {
                                text: '',
                                ref: '.body',
                                'ref-x': .5,
                                'ref-dy': 20,
                                'y-alignment': 'middle',
                                'x-alignment': 'middle',
                                'font-size': 14,
                                'font-family': 'Arial, helvetica, sans-serif',
                                fill: '#000000'
                            },
                            image: {
                                width: 40,
                                height: 40,
                                'xlink:href': '',
                                transform: 'translate(20,20)'
                            }
                        }

                    });
                case factory.GSMC:
                    return new joint.shapes.bpmn.Gateway({
                        markup: '<g class="rotatable"><g class="scalable"><polygon class="body"/><path class="gateway"/><image/></g></g><text class="label"/>',
                        position: position,
                        size: {
                            width: 40,
                            height: 40
                        },
                        gatewayType: factory.GSMC,
                        gatewayId: '',
                        icon: 'circle',
                        attrs: {
                            '.': { class: 'joint-theme-bpmn joint-cell joint-type-bpmn joint-type-bpmn-gateway joint-element' },
                            '.gateway': {
                                d: 'M40 80 L80 40 L40 0',
                                fill: 'none',
                                stroke: '#000000',
                                'stroke-width': 5,
                                'stroke-dasharray': 'none'
                            },
                            '.body': {
                                points: '40,0 80,40 40,80 0,40',
                                fill: hl,
                                stroke: '#000000'
                            },
                            '.label': {
                                text: '',
                                ref: '.body',
                                'ref-x': .5,
                                'ref-dy': 20,
                                'y-alignment': 'middle',
                                'x-alignment': 'middle',
                                'font-size': 14,
                                'font-family': 'Arial, helvetica, sans-serif',
                                fill: '#000000'
                            },
                            image: {
                                width: 40,
                                height: 40,
                                'xlink:href': '',
                                transform: 'translate(20,20)'
                            }
                        }

                    });
            }

            return undefined;
        };

        factory.processGateways = function(cell, type) {
            var gatewayOpen = factory.GSAO;
            var gatewayClose = factory.GSAC;

            if(type == factory.GSM) {
                gatewayOpen = factory.GSMO;
                gatewayClose = factory.GSMC;
            }

            var gatewayId = CommonService.guid();

            var selectOpen = factory.getShape(gatewayOpen, { x: cell.get('position').x, y: cell.get('position').y},undefined, '#B1F0A3');
            selectOpen.set('gatewayId', gatewayId);
            selectOpen.set('gatewayPaths', []);
            factory.ABMComponentFactory.graph.addCell(selectOpen);

            var selectClose = factory.getShape(gatewayClose, { x: cell.get('position').x + 200, y: cell.get('position').y}, undefined, '#B1F0A3');
            selectClose.set('gatewayId', gatewayId);
            factory.ABMComponentFactory.graph.addCell(selectClose);
        }

        factory.processAdd = function (cell) {
            switch (factory.getShapeType(cell)) {
                case factory.GSA:
                case factory.GSM:
                    factory.processGateways(cell, factory.getShapeType(cell));
                    break;
            }
        }

        factory.isOpenGateway = function(element) {
            return _.contains([factory.GSAO, factory.GSMO], factory.getShapeType(element));
        };

        factory.isOpenGatewayPath = function (element, path) {
            if(!path) {
                return false;
            }
            return factory.isOpenGateway(element) && element.get('gatewayId') == path.gatewayId;
        };

        factory.isCloseGateway = function(element) {
            return _.contains([factory.GSAC, factory.GSMC], factory.getShapeType(element));
        };

        factory.isCloseGatewayPath = function (element, path) {
            if(!path) {
                return false;
            }
            return factory.isCloseGateway(element) && element.get('gatewayId') == path.gatewayId;
        };

        factory.hasPath = function(element) {
            if(!element) {
                return false;
            }
            return element.get('gatewayPath') != undefined;
        };

        factory.inPath = function(element, gateway) {
            if(!factory.hasPath(element)) {
                return false;
            }

            if(!gateway.get('gatewayId')) {
                return false;
            }

            return factory.getPath(element).gatewayId == gateway.get('gatewayId');
        };

        factory.getPath = function(element) {
            return element.get('gatewayPath');
        };

        var setPath = function (element, path) {
            element.set('gatewayPath', path);
            if(!_.contains([factory.GSAO, factory.GSAC, factory.GSMO, factory.GSMC], factory.getShapeType(element))) {
                factory.ABMComponentFactory.setCellContent(element.id, element.get('content'));
            } else {
            }
        };

        var unsetPath = function(element) {
            element.set('gatewayPath', undefined);
            factory.ABMComponentFactory.setCellContent(element.id, element.get('content'));
        };

        factory.isEqualPath = function(pathSource, pathTarget) {
            if(!pathSource) {
                return false;
            }

            if(!pathTarget) {
                return false;
            }

            return pathSource.gatewayId == pathTarget.gatewayId && pathSource.path == pathTarget.path;
        };

        factory.getCloseGateway = function(openGateway) {
            var gatewayId = openGateway.get('gatewayId');

            if(gatewayId) {
                var typeClose = factory.getShapeType(openGateway) == factory.GSAO ? factory.GSAC : factory.GSMC;

                var closeGateway = $linq(factory.ABMComponentFactory.graph.getCells()).singleOrDefault(undefined, function(x) {
                    return factory.getShapeType(x) == typeClose && x.get('gatewayId') == gatewayId;
                })

                return closeGateway;
            }

            return undefined;
        }

        factory.getOpenGatewayById = function(id) {
            var openGateway = $linq(factory.ABMComponentFactory.graph.getCells()).singleOrDefault(undefined, function(x) {
                return (factory.getShapeType(x) == factory.GSAO || factory.getShapeType(x) == factory.GSMO || factory.getShapeType(x) == factory.TI) && x.get('gatewayId') == id;
            })

            return openGateway;
        }

        factory.getOpenGateway = function(closeGateway) {
            var gatewayId = closeGateway.get('gatewayId');

            if(gatewayId) {
                var typeOpen = factory.getShapeType(closeGateway) == factory.GSAC ? factory.GSAO : factory.GSMO;

                var openGateway = $linq(factory.ABMComponentFactory.graph.getCells()).singleOrDefault(undefined, function(x) {
                    return factory.getShapeType(x) == typeOpen && x.get('gatewayId') == gatewayId;
                })

                return openGateway;
            }

            return undefined;
        }

        var completePath = function (path, element) {
            if(factory.isEqualPath(factory.getPath(element), path) || factory.getShapeType(element) == factory.TI || factory.isOpenGatewayPath(element, path) || factory.isCloseGatewayPath(element, path)) {
                return;
            }

            setPath(element,path);

            if(factory.isCloseGateway(element)) {
                completePath(path, factory.getOpenGateway(element));
            } else {
                _.forEach(factory.getInLinks(element), function (link) {
                    completePath(path, link.getSourceElement());
                });
            }

            if(factory.isOpenGateway(element)) {
                completePath(path, factory.getCloseGateway(element));
            } else {
                _.forEach(factory.getOutLinks(element), function(link) {
                    completePath(path, link.getTargetElement());
                });
            }
        };

        factory.processLink = function(link) {
            var source = link.getSourceElement();
            var target = link.getTargetElement();

            var path = undefined;
            var completeElement = undefined;

            if(factory.isOpenGateway(source) && !factory.hasPath(target)) {
                path = { gatewayId: source.get('gatewayId'), path: factory.getNewGatewayPath(source.get('gatewayId')) };
                completeElement = target;
            } else if (factory.hasPath(source) && !factory.hasPath(target)) {
                path = factory.getPath(source);
                completeElement = target;
            } else if (!factory.hasPath(source) && factory.hasPath(target)) {
                path = factory.getPath(target);
                completeElement = source;
            }

            if(path && completeElement) {
                completePath(path, completeElement);
            }
        }

        var existLinkWithStart = function(path, element, elements) {
        	if (!element) {
        		return false;
        	} else if(_.contains(elements, element.id)) {
                return false;
            } else if (factory.isOpenGatewayPath(element, path) || factory.getShapeType(element) == factory.TI) {
                return true;
            } else if (factory.isCloseGatewayPath(element, path)) {
                return false;
            }

            elements.push(element.id);

            if(factory.isCloseGateway(element)) {
                if(existLinkWithStart(path, factory.getOpenGateway(element), elements)) {
                    return true;
                }
            } else {
                var ret = false;
                _.forEach(factory.getInLinks(element), function (link) {
                    if(!ret) {
                        if (existLinkWithStart(path, link.getSourceElement(), elements)) {
                            ret = true;
                        }
                    }
                });
                if(ret) {
                    return true;
                }
            }

            if(factory.isOpenGateway(element)) {
                if(existLinkWithStart(path, factory.getCloseGateway(element), elements)) {
                    return true;
                }
            } else {
                var ret = false;
                _.forEach(factory.getOutLinks(element), function (link) {
                    if(!ret) {
                        if (existLinkWithStart(path, link.getTargetElement(), elements)) {
                            ret = true
                        }
                    }
                });

                if(ret) {
                    return true;
                }

            }

            return false;
        }

        var removePath = function(path, element) {
            if(!factory.hasPath(element) || factory.getShapeType(element) == factory.TI || factory.isOpenGatewayPath(element, path) || factory.isCloseGatewayPath(element, path)) {
                return;
            }

            unsetPath(element);

            if(factory.isCloseGateway(element)) {
                removePath(path, factory.getOpenGateway(element));
            } else {
                _.forEach(factory.getInLinks(element), function (link) {
                    removePath(path, link.getSourceElement());
                });
            }

            if(factory.isOpenGateway(element)) {
                removePath(path, factory.getCloseGateway(element));
            } else {
                _.forEach(factory.getOutLinks(element), function (link) {
                    var target = link.getTargetElement();
                    if(factory.isCloseGatewayPath(target, path)) {
                        link.remove();
                    } else {
                        removePath(path, target);
                    }
                });
            }
        }

        factory.processUnlink = function(link) {
            var source = factory.ABMComponentFactory.graph.getCell(link.get('source').id);
            var target = factory.ABMComponentFactory.graph.getCell(link.get('target').id);

            var path  = undefined;

            if(factory.hasPath(source)) {
                if (!existLinkWithStart(factory.getPath(source), source, [])) {
                    removePath(factory.getPath(source), source);
                }
            }

            if(factory.hasPath(target)) {
                if(!existLinkWithStart(factory.getPath(target), target, [])) {
                    removePath(factory.getPath(target), target);
                }
            }
        }

        //endregion

        //region Shape Querys

        factory.getNewGatewayPath = function (gatewayId) {
            var gateway = $linq(factory.ABMComponentFactory.graph.getCells()).singleOrDefault(undefined, function(x) {
                return x.get('gatewayId') == gatewayId && (factory.getShapeType(x) == factory.GSAO || factory.getShapeType(x) == factory.GSMO);
            });

            if(gateway) {
                var path = 1;
                if(gateway.get('gatewayPaths').length != 0) {
                    var maxPath = $linq(gateway.get('gatewayPaths')).max();
                    path = maxPath + 1;
                }

                gateway.get('gatewayPaths').push(path);

                return path;
            } else {
                return -1;
            }
        }

        factory.getShapeType = function(cell) {
            if(!cell) {
                return 'unknown';
            }

            switch (cell.get('type')) {
                case 'bpmn.Activity':
                    switch (cell.get('activityType')) {
                        case 'transaction':
                            return factory.TI;
                        case 'call-activity':
                            if(cell.get('icon') == 'service') {
                                if(ABMModelFactory.getAssignationType(cell) ==  ABMModelFactory.AT_NONGUIDED){
                                    return factory.SFN;
                                }else{
                                    return factory.SN;
                                }

                            } else {
                                return factory.TEF;
                            }
                            break;
                        case 'event-sub-process':
                            if(ABMModelFactory.getTramitationType(cell, true) == ABMModelFactory.TT_NORMAL) {
                                return factory.TFN;
                            } else {
                                return factory.TFF;
                            }
                            break;
                        case 'task':
                            if(cell.get('icon') == 'service') {
                                if(ABMModelFactory.getAssignationType(cell) ==  ABMModelFactory.AT_NONGUIDED) {
                                    return factory.SFN;
                                }else {
                                    return factory.SN;
                                }
                            } else {
                                return factory.TEN;
                            }
                            break;
                    }
                    break;
                case 'bpmn.Gateway':
                    return cell.get('gatewayType');
                    break;
            }

            return 'unknown';
        };

        factory.getShapesByType = function (type, cells) {
            return $linq(cells).where(function(x) {
                return factory.getShapeType(x) == type;
            }).toArray();
        };

        factory.getShapesByNotType = function (type, cells) {
            return $linq(cells).where(function(x) {
                return factory.getShapeType(x) != type;
            }).toArray();
        };

        factory.countOutLinks = function (cell) {
            return factory.ABMComponentFactory.graph.getConnectedLinks(cell, { outbound: true }).length;
        };

        factory.countInLinks = function (cell) {
            return factory.ABMComponentFactory.graph.getConnectedLinks(cell, { inbound: true }).length;
        }

        factory.getOutLinks = function (cell) {
            return factory.ABMComponentFactory.graph.getConnectedLinks(cell, { outbound: true });
        };

        factory.getInLinks = function (cell) {
            return factory.ABMComponentFactory.graph.getConnectedLinks(cell, { inbound: true });
        };

        //endregion

        //region Shape Modification

        factory.setCellType = function(cell, AT, TT) {
            if(AT == ABMModelFactory.AT_NONGUIDED && TT == ABMModelFactory.TT_NORMAL) {
                if(factory.getShapeType(cell) == factory.SFN){
                    cell.set('activityType', 'task');
                    cell.attr({'.outer': {'stroke-width': 1}});
                    cell.attr({'.outer': {'stroke-dasharray': 'none'}});
                }else{
                    cell.set('activityType', 'event-sub-process');
                    cell.attr({'.outer': {'stroke-width': 1}});
                    cell.attr({'.outer': {'stroke-dasharray': 'none'}});
                }
            } else if(AT == ABMModelFactory.AT_NONGUIDED && TT == ABMModelFactory.TT_FINAL) {
                cell.set('activityType', 'event-sub-process');
                cell.attr({ '.outer': { 'stroke-width': 5 } });
                cell.attr({'.outer': {'stroke-dasharray': 'none'}});
            } else if(AT == ABMModelFactory.AT_GUIDED && TT == ABMModelFactory.TT_NORMAL) {
                cell.set('activityType', 'task');
                cell.attr({'.outer': {'stroke-dasharray': 'none'}});
            } else {
                cell.set('activityType', 'call-activity');
            }
        }

        factory.setGatewayError = function (cell) {
            if(cell.get('type') == 'bpmn.Gateway') {
                cell.attr({ '.body': { stroke: '#a94442' } });
                cell.attr({ '.gateway': { stroke: '#a94442' } });
            }
        };

        factory.setTransactionError = function(cell) {
            if(cell.get('type') == 'bpmn.Activity') {
                cell.attr({ '.body': { stroke: '#a94442' } });
            }
        }

        factory.unsetGatewayError = function (cell) {
            if(cell.get('type') == 'bpmn.Gateway') {
                cell.attr({ '.body': { stroke: '#000000' } });
                cell.attr({ '.gateway': { stroke: '#000000' } });
            }
        };

        factory.unsetTransactionError = function(cell) {
            if(cell.get('type') == 'bpmn.Activity') {
                cell.attr({ '.body': { stroke: '#000000' } });
            }
        }

        factory.highlightGateway = function(cell) {
            cell.attr({ '.body': { fill: '#B1F0A3'}});
        }

        factory.unhighlightGateway = function(cell) {
            cell.attr({ '.body': { fill: '#ffffff' } });
        }

        factory.highlightSameIdGateways = function(cell, cells) {
            var gateways = $linq(cells).where(function(x) {
                return _.contains([factory.GSMO, factory.GSMC, factory.GSAO, factory.GSAC], factory.getShapeType(x));
            }).toArray();

            var sameIdGateways = $linq(gateways).where(function(x) {
                return x.get('gatewayId') == cell.get('gatewayId');
            }).toArray();

            _.forEach(sameIdGateways, function(same) {
                factory.highlightGateway(same);
            })
        }

        factory.unhighlightAllGateways = function(cells) {
            var gateways = $linq(cells).where(function(x) {
                return _.contains([factory.GSMO, factory.GSMC, factory.GSAO, factory.GSAC], factory.getShapeType(x));
            }).toArray();

            _.forEach(gateways, function(cell) {
                factory.unhighlightGateway(cell);
            })
        }

        //endregion

        //region Shape From Model

        factory.isTransactionFinal = function(transaction) {
            return _.contains([factory.TEF, factory.TFF], transaction.graphNodeType);
        };

        factory.isTransactionFree = function(transaction) {
            return _.contains([factory.TFN, factory.TFF], transaction.graphNodeType);
        };

        factory.getTransactionShapeType = function(transaction) {
            return transaction.graphNodeType;
        }

        factory.setCellContent = function (cell, content) {
            if(cell) {
                cell.set('content', content);
            }
        };

        //endregion

        return factory;
    }]);