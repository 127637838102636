/**
 * Created by osirvent on 26/01/2016.
 */
angular
    .module('annexaApp')
    .factory('EmailFactory',['$http', 'api', '$q', function ($http, api, $q) {
        return {
            sendEmail: function(to,subject,text,docid,docname,iduser,iddoc,attachments,multipleTo,multiple) {
                var deferred = $q.defer();
                var send = {
                    to:to,
                    subject:subject,
                    text:text,
                    idUser:iduser,
                    docs:[],
                    attachments: attachments,
                    multipleTo:multipleTo,
                    multiple:multiple
                };
                if(Array.isArray(iddoc)){
                    send.docs = iddoc;
                }else{
                    send.docs.push(iddoc);
                }

                $http({
                    url:  './api/email',
                    method: 'POST',
                    data: send
                }).then(function (data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                })
                return deferred.promise;
            }
        };
    }]);